import React, { Component } from 'react'
import history from '../history'

export default class NoMatch extends Component {
  componentDidMount() {
    history.replace('/')
  }

  render() {
    return(
      <></>
    )
  }
}