import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'

import { getStyles, getOfferType } from '../../Constants/utilityFunctions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { updateOfferHash, newOfferHash } from '../../Constants/apiHashes'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import AcquisitionOfferPanel from '../../Components/AcquisitionOfferPanel'
import RegularOfferPanel from '../../Components/RegularOfferPanel'

class OffersEditPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    let merchantId = this.props.merchantId
    this.props.getMerchantOffers(merchantId)
  }

  handleUpdateButtonPress = () => {
    const offers = this.props.onboarding.offers
    const existingOffers = this.props.onboarding.merchantOffers
    let merchantId = this.props.merchantId
    // let createAcquisitionOffer = true
    let createRegularOffer = true

    let merchantName = this.props.newMerchant.activeMerchant.business_name

    for (let existingOffer of existingOffers) {
      let existingOfferAmount = parseInt(existingOffer.amount)

      // Check regular offer to see if a create or update is required
      if (getOfferType(existingOffer) === 'regular') {
        createRegularOffer = false

        if (existingOfferAmount !== offers.regular.amount) {
          this.props.updateMerchantOffer(
            updateOfferHash(existingOffer, offers.regular, merchantId, merchantName),
          )
        }
      }

      // Check acquisition offer to see if a create, delete or update is required
      if (getOfferType(existingOffer) === 'acquisition') {
        // createAcquisitionOffer = false

        if (offers.acquisition.amount === 0) {
          this.props.deleteOffer({ id: existingOffer.id, merchantId })
        }

        if (existingOfferAmount !== offers.acquisition.amount && offers.acquisition.amount !== 0) {
          this.props.updateMerchantOffer(
            updateOfferHash(existingOffer, offers.acquisition, merchantId, merchantName),
          )
        }
      }
    }

    // if (createAcquisitionOffer && offers.acquisition.amount !== 0) {
    //   this.props.createOffer(newOfferHash(offers.acquisition, merchantId, merchantName))
    // }

    if (createRegularOffer) {
      this.props.createOffer(newOfferHash(offers.regular, merchantId, merchantName))
    }
  }

  render() {
    const { width, index, value } = this.props
    let styles = getStyles(width)

    return (
      <>
        {value === index && (
          <div style={styles.overviewPageContainer}>
            <div style={{ ...styles.offersSubtitle, marginTop: '4rem' }}>
              Select offers to reward and attract customers. These offers will be automatically
              applied when a customer uses their Buoy Local card and will not require any additional
              steps from the merchant or front line staff. You will be billed monthly for these
              offers (where applicable) and will be able to change these offers through your
              merchant profile.
            </div>

            <div style={styles.editOffersPanelContainer}>
              <AcquisitionOfferPanel styles={styles} />
              <RegularOfferPanel styles={styles} />
            </div>

            <div
              style={{
                margin: '1rem 0',
                alignSelf: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <DarkBlueButton
                variant="contained"
                size="large"
                onClick={this.handleUpdateButtonPress}>
                Update
              </DarkBlueButton>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMerchantOffers: merchantId => dispatch(MerchantApiActions.getMerchantOffers(merchantId)),
    updateMerchantOffer: data => dispatch(MerchantApiActions.updateMerchantOffer(data)),
    createOffer: data => dispatch(MerchantApiActions.createOffer(data)),
    deleteOffer: id => dispatch(MerchantApiActions.deleteOffer(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(OffersEditPage))
