import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE, HOVER_BLUE } from '../../Constants/cssColors'

export default withStyles({
  root: {
    backgroundColor: `${BLUE}`,
    color: 'white',
    '&:hover': {
      backgroundColor: `${HOVER_BLUE}`,
    },
  },
  outlinedPrimary: {
    borderColor: `${BLUE}`,
    color: `${BLUE}`,
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'black',
      backgroundColor: `${HOVER_BLUE}`,
      borderColor: 'black',
    },
  },
})(Button)
