import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, ListItem, CardMedia, ListItemText, Divider } from '@material-ui/core'
import { CountryRegionData } from 'react-country-region-selector'

import DarkBlueButton from './UI/DarkBlueButton'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import { getPlaceDetails } from '../Sagas/sagaMerchantActions'

class GooglePlacesList extends Component {
  fillInLocationForm = async place => {
    let address_components
    let street_address = ''
    let phone_number
    let latitude
    let longitude
    let businessName

    this.props.clearLocationAddressForm()

    if (place.address_components) {
      address_components = place.address_components
      place.international_phone_number
        ? (phone_number = place.international_phone_number)
        : (phone_number = '')
      place.geometry.location ? (latitude = place.geometry.location.lat()) : (latitude = '')
      place.geometry.location ? (longitude = place.geometry.location.lng()) : (longitude = '')
      place.name ? (businessName = place.name) : (businessName = '')
    } else {
      const service = new window.google.maps.places.PlacesService(document.createElement('div'))
      const request = {
        placeId: place.place_id,
        fields: ['address_component', 'international_phone_number', 'geometry', 'name'],
      }

      const placeDetails = await getPlaceDetails(request, service)

      placeDetails.address_components
        ? (address_components = placeDetails.address_components)
        : (address_components = '')
      placeDetails.international_phone_number
        ? (phone_number = placeDetails.international_phone_number)
        : (phone_number = '')
      placeDetails.geometry.location
        ? (latitude = placeDetails.geometry.location.lat())
        : (latitude = '')
      placeDetails.geometry.location
        ? (longitude = placeDetails.geometry.location.lng())
        : (longitude = '')
      placeDetails.name ? (businessName = placeDetails.name) : (businessName = '')
    }

    this.props.addLocationGooglePlaceId(place.place_id)
    this.props.addLocationPhoneNumber(phone_number)
    this.props.addLocationLatitude(latitude)
    this.props.addLocationLongitude(longitude)
    this.props.addLocationName(businessName)

    address_components.forEach(address_component => {
      switch (address_component.types[0]) {
        case 'street_number':
          street_address = address_component['long_name']
          break
        case 'route':
          street_address += ' ' + address_component['long_name']
          this.props.addLocationAddress1(street_address)
          break
        case 'locality':
          this.props.addLocationCity(address_component['long_name'])
          break
        case 'postal_code':
          this.props.addLocationZipCode(address_component['long_name'])
          break
        case 'country':
          CountryRegionData.forEach(country => {
            if (country[0] === address_component['long_name']) {
              this.props.addLocationCountry(country)
            }
          })
          break
        case 'administrative_area_level_1':
          this.props.addLocationState(address_component['short_name'])
          break
        default:
          break
      }
    })
  }

  render() {
    const { onboarding, styles } = this.props
    var currentPage = localStorage.getItem('onboardingPage')

    return onboarding.placeResults.length > 0 ? (
      <List style={styles.googlePlacesList}>
        {onboarding.placeResults.map(place => (
          <div key={place.place_id}>
            {onboarding.placeResults.findIndex(i => i.place_id === place.place_id) !== 0 ? (
              <Divider />
            ) : (
              ''
            )}
            <ListItem>
              <CardMedia image={place.icon} style={styles.googlePlaceIcon} />

              <ListItemText
                style={styles.googlePlaceTitleAndAddress}
                primary={place.name}
                secondary={<React.Fragment>{place.formatted_address}</React.Fragment>}
              />

              <DarkBlueButton
                variant="outlined"
                margin="dense"
                color="primary"
                disabled={onboarding.locationDetails.locationCreated && currentPage === 'addLocationsPage'}
                style={styles.selectButton}
                onClick={() => {
                  this.fillInLocationForm(place)
                }}>
                + Select
              </DarkBlueButton>
            </ListItem>
          </div>
        ))}
      </List>
    ) : null
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLocationName: name => dispatch(MerchantOnboardingActions.addLocationName(name)),
    addLocationAddress1: street => dispatch(MerchantOnboardingActions.addLocationAddress1(street)),
    addLocationState: state => dispatch(MerchantOnboardingActions.addLocationState(state)),
    addLocationCountry: country => dispatch(MerchantOnboardingActions.addLocationCountry(country)),
    addLocationCity: city => dispatch(MerchantOnboardingActions.addLocationCity(city)),
    addLocationZipCode: zip => dispatch(MerchantOnboardingActions.addLocationZipCode(zip)),
    addLocationGooglePlaceId: placeId =>
      dispatch(MerchantOnboardingActions.addLocationGooglePlaceId(placeId)),
    addLocationPhoneNumber: phone =>
      dispatch(MerchantOnboardingActions.addLocationPhoneNumber(phone)),
    addLocationLatitude: lat => dispatch(MerchantOnboardingActions.addLocationLatitude(lat)),
    addLocationLongitude: lng => dispatch(MerchantOnboardingActions.addLocationLongitude(lng)),
    clearLocationAddressForm: () => dispatch(MerchantOnboardingActions.clearLocationAddressForm()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GooglePlacesList)
