import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardContent, ButtonBase, Tooltip } from '@material-ui/core'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import { BLUE } from '../Constants/cssColors'

class RegularOfferButtons extends Component {
  state = {
    offer: this.props.offer,
    pointsButtonSelected: 2,
    values: [10, 5, 3, 2, 1],
  }

  componentDidMount = () => {
    // Initialize the component with "1X Points" pre-selected
    this.handlePointsButtonClick(1)
  }

  static getDerivedStateFromProps(props, state) {
    // The existingOffers props is used on the Offers Edit page
    if (props.offer !== state.offer) {
      return {
        pointsButtonSelected: props.offer.amount,
        existingOffers: props.offer,
      }
    }

    return null
  }

  handlePointsButtonClick = amount => {
    this.props.setRegOfferAmount(amount)

    this.setState({
      pointsButtonSelected: amount,
    })
  }

  render() {
    const { values } = this.state
    const { styles } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Card
          raised={this.state.pointsButtonSelected === values[0]}
          onClick={() => this.handlePointsButtonClick(values[0])}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid ${
              this.state.pointsButtonSelected === values[0] ? `${BLUE}` : 'lightgrey'
            }`,
          }}>
          <Tooltip
            title="Bangor Savings Bank will fund 1X Points. Merchant is responsible for funding additional points"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.regOfferButton,
                  color: `${
                    this.state.pointsButtonSelected === values[0] ? `${BLUE}` : 'lightgrey'
                  }`,
                }}>
                <div style={styles.noSubtextButton}>{values[0]}X Points</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[1]}
          onClick={() => this.handlePointsButtonClick(values[1])}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid ${
              this.state.pointsButtonSelected === values[1] ? `${BLUE}` : 'lightgrey'
            }`,
          }}>
          <Tooltip
            title="Bangor Savings Bank will fund 1X Points. Merchant is responsible for funding additional points"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.regOfferButton,
                  color: `${
                    this.state.pointsButtonSelected === values[1] ? `${BLUE}` : 'lightgrey'
                  }`,
                }}>
                <div style={styles.noSubtextButton}>{values[1]}X Points</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[2]}
          onClick={() => this.handlePointsButtonClick(values[2])}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid ${
              this.state.pointsButtonSelected === values[2] ? `${BLUE}` : 'lightgrey'
            }`,
          }}>
          <Tooltip
            title="Bangor Savings Bank will fund 1X Points. Merchant is responsible for funding additional points"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.regOfferButton,
                  color: `${
                    this.state.pointsButtonSelected === values[2] ? `${BLUE}` : 'lightgrey'
                  }`,
                }}>
                <div style={styles.noSubtextButton}>{values[2]}X Points</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[3]}
          onClick={() => this.handlePointsButtonClick(values[3])}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid ${
              this.state.pointsButtonSelected === values[3] ? `${BLUE}` : 'lightgrey'
            }`,
          }}>
          <Tooltip
            title="Bangor Savings Bank will fund 1X Points. Merchant is responsible for funding additional points"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.regOfferButton,
                  color: `${
                    this.state.pointsButtonSelected === values[3] ? `${BLUE}` : 'lightgrey'
                  }`,
                }}>
                <div style={styles.noSubtextButton}>{values[3]}X Points</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[4]}
          onClick={() => this.handlePointsButtonClick(values[4])}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid ${
              this.state.pointsButtonSelected === values[4] ? `${BLUE}` : 'lightgrey'
            }`,
          }}>
          <Tooltip
            title="Bangor Savings Bank will fund this offer. Consider selecting additional points for a stronger loyalty offer"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.regOfferButton,
                  color: `${
                    this.state.pointsButtonSelected === values[4] ? `${BLUE}` : 'lightgrey'
                  }`,
                }}>
                <div>{values[4]}X Points</div>
                <div style={styles.buttonTextSubtext}>BSB funded</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setRegOfferId: id => dispatch(MerchantOnboardingActions.setRegOfferId(id)),
    setRegOfferAmount: amount => dispatch(MerchantOnboardingActions.setRegOfferAmount(amount)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(RegularOfferButtons)
