import React, { Component } from 'react'
import { connect } from 'react-redux'

import { MerchantApiActions } from '../Actions/merchantApiActions'

class HomePage extends Component {
  componentDidMount() {
    this.props.getMerchants()
  }

  render() {
    return(
      <></>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getMerchants: () => dispatch(MerchantApiActions.getMerchants()),
  }
}

export default connect(null, mapDispatchToProps)(HomePage)
