import { Auth } from '../Constants/action-types'

export const AuthActions = {
  addUserEmail: function addUserEmail(payload) {
    return {
      type: Auth.USER_EMAIL,
      payload,
    }
  },
  addUserName: function addUserName(payload) {
    return {
      type: Auth.USER_NAME,
      payload,
    }
  },
  addUserPhone: function addUserPhone(payload) {
    return {
      type: Auth.USER_PHONE,
      payload,
    }
  },
  addUserPassword: function addUserPassword(payload) {
    return {
      type: Auth.USER_PASSWORD,
      payload,
    }
  },
  confirmEmail: function confirmEmail(payload) {
    return {
      type: Auth.CONFIRM_EMAIL,
      payload,
    }
  },
  confirmUserPassword: function confirmUserPassword(payload) {
    return {
      type: Auth.CONFIRM_PASSWORD,
      payload,
    }
  },
  logInUser: function logInUser(payload) {
    return {
      type: Auth.LOG_IN_USER,
      payload,
    }
  },
  userLoggedIn: function userLoggedIn(payload) {
    return {
      type: Auth.USER_LOGGED_IN,
      payload,
    }
  },
  signUpUser: function signUpUser(payload) {
    return {
      type: Auth.SIGN_UP_USER,
      payload,
    }
  },
  userSignedUp: function userSignedUp(payload) {
    return {
      type: Auth.USER_SIGNED_UP,
      payload,
    }
  },
  userLogOut: function userLogOut() {
    return { type: Auth.LOG_OUT_USER }
  },
  getUserProfile: function getUserProfile() {
    return {
      type: Auth.GET_USER_PROFILE,
    }
  },
  updateUserProfile: function updateUserProfile(payload) {
    return {
      type: Auth.UPDATE_USER_PROFILE,
      payload,
    }
  },
  deactivateUserProfile: function deactivateUserProfile(payload) {
    return {
      type: Auth.DEACTIVATE_USER_PROFILE,
      payload,
    }
  },
  recoverPassword: function recoverPassword(payload) {
    return {
      type: Auth.RECOVER_PASSWORD,
      payload,
    }
  },
  resetPassword: function resetPassword(payload) {
    return {
      type: Auth.RESET_PASSWORD,
      payload,
    }
  },
  resetPasswordToken: function resetPasswordToken(payload) {
    return {
      type: Auth.RESET_PASSWORD_TOKEN,
      payload,
    }
  },
  getPendingLegalNotices: function getPendingLegalNotices() {
    return {
      type: Auth.GET_PENDING_LEGAL_NOTICES,
    }
  },
  getAllLegalNotices: function getAllLegalNotices() {
    return {
      type: Auth.GET_ALL_LEGAL_NOTICES,
    }
  },
  acceptLegalNotices: function acceptLegalNotices(payload) {
    return {
      type: Auth.ACCEPT_LEGAL_NOTICES,
      payload,
    }
  },
}
