///////// Merchant onboarding /////////
export const MerchantOnboarding = {
  MERCHANT_ID: 'MERCHANT_ID',
  MERCHANT_NAME: 'MERCHANT_NAME',
  MERCHANT_CATEGORY: 'MERCHANT_CATEGORY',
  MERCHANT_EMAIL: 'MERCHANT_EMAIL',
  MERCHANT_PHONE: 'MERCHANT_PHONE',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  ROUTING_NUMBER: 'ROUTING_NUMBER',
  DEPOSITORY_NAME: 'DEPOSITORY_NAME',
  TAX_IDENTIFICATION_NUMBER: 'TAX_IDENTIFICATION_NUMBER',
  BILLING_ADDRESS_1: 'BILLING_ADDRESS_1',
  BILLING_ADDRESS_2: 'BILLING_ADDRESS_2',
  BILLING_STATE: 'BILLING_STATE',
  BILLING_COUNTRY: 'BILLING_COUNTRY',
  BILLING_CITY: 'BILLING_CITY',
  BILLING_ZIP_CODE: 'BILLING_ZIP_CODE',
  SHIPPING_ADDRESS_1: 'SHIPPING_ADDRESS_1',
  SHIPPING_ADDRESS_2: 'SHIPPING_ADDRESS_2',
  SHIPPING_STATE: 'SHIPPING_STATE',
  SHIPPING_COUNTRY: 'SHIPPING_COUNTRY',
  SHIPPING_CITY: 'SHIPPING_CITY',
  SHIPPING_ZIP_CODE: 'SHIPPING_ZIP_CODE',
  LOCATION_NAME: 'LOCATION_NAME',
  LOCATION_ADDRESS_1: 'LOCATION_ADDRESS_1',
  LOCATION_ADDRESS_2: 'LOCATION_ADDRESS_2',
  LOCATION_STATE: 'LOCATION_STATE',
  LOCATION_COUNTRY: 'LOCATION_COUNTRY',
  LOCATION_CITY: 'LOCATION_CITY',
  LOCATION_ZIP_CODE: 'LOCATION_ZIP_CODE',
  LOCATION_GOOGLE_PLACE_ID: 'LOCATION_GOOGLE_PLACE_ID',
  LOCATION_PHONE_NUMBER: 'LOCATION_PHONE_NUMBER',
  LOCATION_LATITUDE: 'LOCATION_LATITUDE',
  LOCATION_LONGITUDE: 'LOCATION_LONGITUDE',
  CLEAR_LOCATION_CREATED: 'CLEAR_LOCATION_CREATED',
  CLEAR_LOCATION_ADDRESS: 'CLEAR_LOCATION_ADDRESS',
  CLEAR_LOCATION_DETAILS: 'CLEAR_LOCATION_DETAILS',
  CLEAR_SHIPPING_ADDRESS: 'CLEAR_SHIPPING_ADDRESS',
  CLEAR_BILLING_ADDRESS: 'CLEAR_BILLING_ADDRESS',
  BILLING_SAME_AS_SHIPPING: 'BILLING_SAME_AS_SHIPPING',
  LOGO_IMAGE: 'LOGO_IMAGE',
  BANNER_IMAGE: 'BANNER_IMAGE',
  GOOGLE_PLACES: 'GOOGLE_PLACES',
  REGULAR_REWARD_AMOUNT: 'REGULAR_REWARD_AMOUNT',
  ACQUISITION_REWARD_AMOUNT: 'ACQUISITION_REWARD_AMOUNT',
  ACQUISITION_MIN_SPEND_AMOUNT: 'ACQUISITION_MIN_SPEND_AMOUNT',
  REGULAR_OFFER_ID: 'REGULAR_OFFER_ID',
  ACQUISITION_OFFER_ID: 'ACQUISITION_OFFER_ID',
  GET_SHIPPING_ADDRESS: 'GET_SHIPPING_ADDRESS',
  POPULATE_LOCATION_DETAILS: 'POPULATE_LOCATION_DETAILS',
  POPULATE_SHIPPING_ADDRESS: 'POPULATE_SHIPPING_ADDRESS',
  POPULATE_BILLING_ADDRESS: 'POPULATE_BILLING_ADDRESS',
  LOCATION_LOGO_IMAGE: 'LOCATION_LOGO_IMAGE',
  LOCATION_BANNER_IMAGE: 'LOCATION_BANNER_IMAGE',
  ABOUT_LOCATION: 'ABOUT_LOCATION',
  LOCATION_WEBSITE: 'LOCATION_WEBSITE',
  LOCATION_INSTAGRAM: 'LOCATION_INSTAGRAM',
  LOCATION_FACEBOOK: 'LOCATION_FACEBOOK',
  LOCATION_TWITTER: 'LOCATION_TWITTER',
  LOCATION_ONLINE_ORDERING: 'LOCATION_ONLINE_ORDERING',
  SWITCH_APP_BAR_TAB: 'SWITCH_APP_BAR_TAB',
  BUILD_LOCATIONS_FILTER: 'BUILD_LOCATIONS_FILTER',
  SET_LOCATION_FILTER: 'SET_LOCATION_FILTER',
  SET_DATE_RANGE_FILTER: 'SET_DATE_RANGE_FILTER',
  OPEN_VIRTUAL_CARD_MODAL: 'OPEN_VIRTUAL_CARD_MODAL',
  CLOSE_VIRTUAL_CARD_MODAL: 'CLOSE_VIRTUAL_CARD_MODAL',
}

///////// Merchant onboarding API calls /////////
export const OnboardingApiCalls = {
  CREATE_MERCHANT: 'CREATE_MERCHANT',
  MERCHANT_CREATED: 'MERCHANT_CREATED',
  UPDATE_MERCHANT: 'UPDATE_MERCHANT',
  MERCHANT_UPDATED: 'MERCHANT_UPDATED',
  CREATE_OFFER: 'CREATE_OFFER',
  OFFER_CREATED: 'OFFER_CREATED',
  DELETE_OFFER: 'DELETE_OFFER',
  OFFER_DELETED: 'OFFER_DELETED',
  CREATE_MERCHANT_LOCATION: 'CREATE_MERCHANT_LOCATION',
  MERCHANT_LOCATION_CREATED: 'MERCHANT_LOCATION_CREATED',
  GET_MERCHANT_LOCATIONS: 'GET_MERCHANT_LOCATIONS',
  LOCATIONS_RETRIEVED: 'LOCATIONS_RETRIEVED',
  UPDATE_MERCHANT_LOCATION: 'UPDATE_MERCHANT_LOCATION',
  MERCHANT_LOCATION_UPDATED: 'MERCHANT_LOCATION_UPDATED',
  GET_MERCHANT_OFFERS: 'GET_MERCHANT_OFFERS',
  OFFERS_RETRIEVED: 'OFFERS_RETRIEVED',
  GET_TAG_TYPES: 'GET_TAG_TYPES',
  TAG_TYPES_RETRIEVED: 'TAG_TYPES_RETRIEVED',
  UPDATE_MERCHANT_OFFER: 'UPDATE_MERCHANT_OFFER',
  MERCHANT_OFFER_UPDATED: 'MERCHANT_OFFER_UPDATED',
  GET_MERCHANTS: 'GET_MERCHANTS',
  MERCHANTS_RETRIEVED: 'MERCHANTS_RETRIEVED',
  GET_MERCHANT_DETAILS: 'GET_MERCHANT_DETAILS',
  MERCHANT_DETAILS_RETRIEVED: 'MERCHANT_DETAILS_RETRIEVED',
  GET_MERCHANT_TRANSACTIONS: 'GET_MERCHANT_TRANSACTIONS',
  MERCHANT_TRANSACTIONS_RETRIEVED: 'MERCHANT_TRANSACTIONS_RETRIEVED',
  GET_LOCATION_TRANSACTIONS: 'GET_LOCATION_TRANSACTIONS',
  LOCATION_TRANSACTIONS_RETRIEVED: 'LOCATION_TRANSACTIONS_RETRIEVED',
  GET_AVG_TICKET_VALUE_REPORT: 'GET_AVG_TICKET_VALUE_REPORT',
  AVG_TICKET_VALUE_REPORT_RETRIEVED: 'AVG_TICKET_VALUE_REPORT_RETRIEVED',
  GET_ALL_TIME_TRANSACTIONS_REPORT: 'GET_ALL_TIME_TRANSACTIONS_REPORT',
  ALL_TIME_TRANSACTIONS_REPORT_RETRIEVED: 'ALL_TIME_TRANSACTIONS_REPORT_RETRIEVED',
  GET_MONTH_TO_DATE_TRANSACTIONS_REPORT: 'GET_MONTH_TO_DATE_TRANSACTIONS_REPORT',
  MONTH_TO_DATE_TRANSACTIONS_REPORT_RETRIEVED: 'MONTH_TO_DATE_TRANSACTIONS_REPORT_RETRIEVED',
  GET_TOTAL_POINTS_ISSUED_REPORT: 'GET_TOTAL_POINTS_ISSUED_REPORT',
  TOTAL_POINTS_ISSUED_REPORT_RETRIEVED: 'TOTAL_POINTS_ISSUED_REPORT_RETRIEVED',
  GET_TOTAL_SPENT_REPORT: 'GET_TOTAL_SPENT_REPORT',
  TOTAL_SPENT_REPORT_RETRIEVED: 'TOTAL_SPENT_REPORT_RETRIEVED',
  GET_MONTH_TO_DATE_SPENT_REPORT: 'GET_MONTH_TO_DATE_SPENT_REPORT',
  MONTH_TO_DATE_SPENT_REPORT_RETRIEVED: 'MONTH_TO_DATE_SPENT_REPORT_RETRIEVED',
  SHOW_ALL_TRANSACTIONS: 'SHOW_ALL_TRANSACTIONS',
  GET_FIRST_TIME_CUSTOMERS_REPORT: 'GET_FIRST_TIME_CUSTOMERS_REPORT',
  FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED: 'FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED',
  GET_MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT: 'GET_MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT',
  MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED: 'MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED',
  CREATE_VIRTUAL_CARD: 'CREATE_VIRTUAL_CARD',
  VIRTUAL_CARD_CREATED: 'VIRTUAL_CARD_CREATED',
  GET_VIRTUAL_CARD_INFO: 'GET_VIRTUAL_CARD_INFO',
  VIRTUAL_CARD_INFO: 'VIRTUAL_CARD_INFO',
  GET_VIRTUAL_CARDS_LIST: 'GET_VIRTUAL_CARDS_LIST',
  VIRTUAL_CARDS_LIST: 'VIRTUAL_CARDS_LIST',
  GET_VIRTUAL_CARD_URL: 'GET_VIRTUAL_CARD_URL',
  VIRTUAL_CARD_URL: 'VIRTUAL_CARD_URL',
  GET_LOCATION_AND_GENERATE_VIRTUAL_CARD: 'GET_LOCATION_AND_GENERATE_VIRTUAL_CARD',
  CREATE_CARDS_REQUEST: 'CREATE_CARDS_REQUEST',
  CARDS_REQUEST_CREATED: 'CARDS_REQUEST_CREATED',
  CARDS_REQUEST_FAILED: 'CARDS_REQUEST_FAILED',
}

///////// User sign-up/sign-in /////////
export const Auth = {
  USER_EMAIL: 'USER_EMAIL',
  USER_NAME: 'USER_NAME',
  USER_PHONE: 'USER_PHONE',
  USER_PASSWORD: 'USER_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  LOG_IN_USER: 'LOG_IN_USER',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  LOG_OUT_USER: 'LOG_OUT_USER',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  SIGN_UP_USER: 'SIGN_UP_USER',
  USER_SIGNED_UP: 'USER_SIGNED_UP',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  USER_PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  USER_PROFILE_RETRIEVED: 'USER_PROFILE_RETRIEVED',
  RECOVER_PASSWORD: 'RECOVER_PASSWORD',
  PASSWORD_RECOVERED: 'PASSWORD_RECOVERED',
  RESET_PASSWORD: 'RESET_PASSWORD',
  PASSWORD_RESET: 'PASSWORD_RESET',
  RESET_PASSWORD_TOKEN: 'RESET_PASSWORD_TOKEN',
  GET_PENDING_LEGAL_NOTICES: 'GET_PENDING_LEGAL_NOTICES',
  GET_ALL_LEGAL_NOTICES: 'GET_ALL_LEGAL_NOTICES',
  LEGAL_NOTICES: 'LEGAL_NOTICES',
  ACCEPT_LEGAL_NOTICES: 'ACCEPT_LEGAL_NOTICES',
  LEGAL_NOTICES_ACCEPTED: 'LEGAL_NOTICES_ACCEPTED',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  DEACTIVATE_USER_PROFILE: 'DEACTIVATE_USER_PROFILE',
}

///////// Notifications /////////
export const Notifications = {
  ERROR_SNACKBAR: 'ERROR_SNACKBAR',
  SUCCESS_SNACKBAR: 'SUCCESS_SNACKBAR',
  INFO_SNACKBAR: 'INFO_SNACKBAR',
  WARNING_SNACKBAR: 'WARNING_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
}
