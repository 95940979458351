import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withWidth } from '@material-ui/core'
import { connect } from 'react-redux'

import { getStyles } from '../Constants/utilityFunctions'
import ApplicationAppBar from '../Components/UI/ApplicationAppBar'
import { MerchantApiActions } from '../Actions/merchantApiActions'
import LocationsListPage from '../Pages/MerchantOnboarding/LocationsListPage'
import OffersEditPage from '../Pages/EditPages/OffersEditPage'
import OverviewPage from '../Pages/OverviewPage'
import ReportingPage from '../Pages/ReportingPage'

class MerchantDetailsPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const { width } = this.props
    const { appBarTab } = this.props.onboarding
    let styles = getStyles(width)

    return (
      <div style={styles.homePageContainer}>
        <ApplicationAppBar auth={this.props.auth} />

        <div style={styles.swipeableViewContainer}>
          <OverviewPage merchantId={this.props.match.params.id} value={appBarTab} index={0} />
        </div>

        <div style={styles.swipeableViewContainer}>
          <ReportingPage merchantId={this.props.match.params.id} value={appBarTab} index={1} />
        </div>

        <div style={styles.swipeableViewContainer}>
          <OffersEditPage merchantId={this.props.match.params.id} value={appBarTab} index={2} />
        </div>

        <div style={styles.swipeableViewContainer}>
          <LocationsListPage merchantId={this.props.match.params.id} value={appBarTab} index={3} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMerchantDetails: id => dispatch(MerchantApiActions.getMerchantDetails(id)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(MerchantDetailsPage)),
)
