import handleErrors from './handleErrors'
import { NotificationsActions } from '../Actions/notificationsActions'
import store from '../Store'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: window.localStorage.bearerToken,
}

export function createMerchant(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function updateMerchant(payload) {
  let merchantId = payload.id
  delete payload.id

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants/${merchantId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getPlaceDetails(request, service) {
  return new Promise(function(resolve, reject) {
    service.getDetails(request, function(place, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        resolve(place)
      } else {
        reject(status)
      }
    })
  })
}

export function getMerchantOffers(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants/${payload}/offers`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getTagTypes() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/tag_types`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getMerchantTransactions(payload) {
  let merchantId = payload.merchant_id
  delete payload.merchant_id

  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/merchants/${merchantId}/transactions`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getLocationTransactions(payload) {
  let locationId = payload.location_id
  delete payload.location_id

  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/locations/${locationId}/transactions`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function createOffer(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/offers`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    if (response.status === 201) {
      store.dispatch(NotificationsActions.displaySuccessSnackbar('Offer created'))
    }

    return response.json()
  })
}

export function deleteOffer(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/offers/${payload.id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }
  })
}

export function updateOffer(payload) {
  let offerId = payload.id
  delete payload.id

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/offers/${offerId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    if (response.status === 202) {
      store.dispatch(NotificationsActions.displaySuccessSnackbar('Offer updated'))
    }

    return response.json()
  })
}

export function getMerchants() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getMerchantDetails(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants/${payload}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function createLocation(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/locations`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    if (response.status === 201) {
      store.dispatch(NotificationsActions.displaySuccessSnackbar('Location created'))
    }

    return response.json()
  })
}

export function updateLocation(payload) {
  let locationId = payload.id
  delete payload.id

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/locations/${locationId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getMerchantLocations(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/merchants/${payload}/locations`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getAvgTicketValue(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/reports/avg_ticket_value`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getAllTimeTransactions(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/reports/all_time_transactions`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getTotalPointsIssued(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/reports/total_points_issued`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getTotalSpent(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/reports/total_spent`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getFirstTimeCustomers(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/reports/first_time_customers`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function generateVirtualCard(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terminal_verification/cards`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    if (response.status === 201) {
      store.dispatch(NotificationsActions.displaySuccessSnackbar('Virtual card created'))
    }

    return response.json()
  })
}

export function getVirtualCardsList() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terminal_verification/cards`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getVirtualCard(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terminal_verification/cards/${payload.id}`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getVirtualCardUrl(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terminal_verification/cards/${payload}/display_url`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function createCardsRequest(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/card_requests`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response);
    }

    return response.json();
  });
}
