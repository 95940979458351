import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE, LIGHT_BLUE_BUTTON, LIGHT_BLUE_BUTTON_HOVER } from '../../Constants/cssColors'

export default withStyles({
  root: {
    backgroundColor: `${LIGHT_BLUE_BUTTON}`,
    color: `${BLUE}`,
    '&:hover': {
      backgroundColor: `${LIGHT_BLUE_BUTTON_HOVER}`,
    },
  },
})(Button)
