import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function Map(props) {
  const styles = {
    width: '100%',
    height: '300px',
    ...(props.styles.locationsMap || {}),
    ...(props.styles.mapContainer || {}),
  }

  return (
    <GoogleMap
      mapContainerStyle={styles}
      center={props.center}
      zoom={14}
      options={{
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    >
      <>
        <Marker position={props.center} />
      </>
    </GoogleMap>
  )
}

export default React.memo(Map)
