import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth, MenuItem, Card, CardHeader, CardContent, Divider } from '@material-ui/core'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import TransactionsTable from '../Components/TransactionsTable'
import MyReportingRow from '../Components/MyReportingRow'
import CssTextField from '../Components/UI/CssTextField'
import { getLocationId, getStyles, getDateRange, loadReports, loadMerchantTransactions } from '../Constants/utilityFunctions'

import { AvgTrxValueBarGraph } from '../Components/Graphs/AvgTrxValueBarGraph'
import { NumOfTrxLineGraph } from '../Components/Graphs/NumOfTrxLineGraph'
import { TotalPtsIssuedBarGraph } from '../Components/Graphs/TotalPtsIssuedBarGraph'
import { TotalSpentLineGraph } from '../Components/Graphs/TotalSpentLineGraph'
import getAvgTicketValue from '../Components/Graphs/avgTicketValueData'
import getPointsIssued from '../Components/Graphs/pointsIssuedData'
import getAllTimeTransactions from '../Components/Graphs/allTimeTransactionsData'
import getTotalSpent from '../Components/Graphs/totalSpentData'

const dateRangesFilter = [
  {
    id: 'last_12_months',
    value: 'Last 12 months',
  },
  {
    id: 'last_3_months',
    value: 'Last 3 months',
  },
  {
    id: 'last_1_month',
    value: 'Last month',
  },
  {
    id: 'last_2_weeks',
    value: 'Last 2 weeks',
  },
  {
    id: 'last_1_week',
    value: 'Last week',
  },
]

class ReportingPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleLocationFilterChange = event => {
    this.props.setLocationFilter(event.target.value)

    let dateRange = getDateRange(this.props.onboarding.selectedDateRange)
    let merchantId = this.props.merchantId
    let locationId = getLocationId(event.target.value, this.props.onboarding.locationsFilter)

    loadReports(locationId, merchantId, dateRange)
  }

  handleDateRangeFilterChange = event => {
    this.props.setDateRangeFilter(event.target.value)
    let dateRange = getDateRange(event.target.value)
    let merchantId = this.props.merchantId
    let locationId = getLocationId(
      this.props.onboarding.selectedLocation,
      this.props.onboarding.locationsFilter,
    )

    loadReports(locationId, merchantId, dateRange)
  }

  render() {
    const { index, value, width } = this.props
    const { onboarding, newMerchant } = this.props
    const {
      filteredTransactions,
      locationsFilter,
      selectedLocation,
      selectedDateRange,
    } = onboarding
    const {
      avgTicketValueReport,
      allTimeTransactionsReport,
      totalPointsIssuedReport,
      totalSpentReport,
    } = newMerchant
    let styles = getStyles(width)
    let dateRange = getDateRange(onboarding.selectedDateRange)

    return (
      <>
        {value === index && (
          <div style={styles.overviewPageSection}>
            <h1 style={styles.title}>Analytics</h1>

            <div style={{ display: 'flex' }}>
              <CssTextField
                select
                label="Location"
                margin="normal"
                variant="outlined"
                style={styles.textFieldHalfWidth}
                value={selectedLocation}
                onChange={this.handleLocationFilterChange}>
                {locationsFilter.map(option => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CssTextField>

              <div style={{ margin: '0.5vw' }} />

              <CssTextField
                select
                label="Period"
                margin="normal"
                variant="outlined"
                style={styles.textFieldHalfWidth}
                value={selectedDateRange}
                onChange={this.handleDateRangeFilterChange}>
                {dateRangesFilter.map(option => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CssTextField>
            </div>

            <div style={styles.overviewPageSection}>
              <MyReportingRow styles={styles} merchantData={newMerchant} />
            </div>

            {filteredTransactions.length > 0 ? (
              <>
                <Card style={{ margin: '0.5vw 0' }}>
                  <CardHeader
                    title="Transaction Metrics"
                    style={{ textAlign: 'left' }}
                    subheader="All transactions by cardholders"
                  />
                  <Divider />
                  <CardContent style={{ padding: '0px 0px 5px 0px' }}>
                    <div style={styles.graphSection}>
                      {Object.keys(avgTicketValueReport).length > 0 ? (
                        <div style={styles.graphContainer}>
                          <AvgTrxValueBarGraph data={getAvgTicketValue(avgTicketValueReport)} />
                        </div>
                      ) : (
                        ''
                      )}

                      <div style={{ margin: '0.5vw' }} />

                      {Object.keys(allTimeTransactionsReport).length > 0 ? (
                        <div style={styles.graphContainer}>
                          <NumOfTrxLineGraph
                            data={getAllTimeTransactions(allTimeTransactionsReport)}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </CardContent>
                </Card>

                <Card style={{ margin: '0.5vw 0' }}>
                  <CardHeader
                    title="Buoy Bucks Metrics"
                    style={{ textAlign: 'left' }}
                    subheader="Points issued to cardholders"
                  />
                  <Divider />
                  <CardContent style={{ padding: '0px 0px 5px 0px' }}>
                    <div style={styles.graphSection}>
                      {Object.keys(totalPointsIssuedReport).length > 0 ? (
                        <div style={styles.graphContainer}>
                          <TotalPtsIssuedBarGraph data={getPointsIssued(totalPointsIssuedReport)} />
                        </div>
                      ) : (
                        ''
                      )}

                      <div style={{ margin: '0.5vw' }} />

                      {Object.keys(allTimeTransactionsReport).length > 0 ? (
                        <div style={styles.graphContainer}>
                          <TotalSpentLineGraph data={getTotalSpent(totalSpentReport)} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </CardContent>
                </Card>

                <TransactionsTable loadTransactions={loadMerchantTransactions(this.props.merchantId, dateRange)} styles={styles} />
              </>
            ) : (
              <div>No transactions found</div>
            )}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLocationFilter: filterValue =>
      dispatch(MerchantOnboardingActions.setLocationFilter(filterValue)),
    setDateRangeFilter: filterValue =>
      dispatch(MerchantOnboardingActions.setDateRangeFilter(filterValue)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(ReportingPage))
