import React from 'react'
import { Route, Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { CircularProgress } from '@material-ui/core'

import CustomizedSnackbars from './Components/UI/CustomizedSnackbars'
import AuthModule from './AuthModule'
import history from './history'
import store from './Store'
import persistor from './Store/persistStorage'
import App from './App'


import { LoadScript } from '@react-google-maps/api';

const auth = new AuthModule(store)
const libraries = ['places']

export const makeMainRoutes = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <Router history={history}>
          <>
            <Route
              path="/"
              render={() => {
                return (
                  <LoadScript
                    googleMapsApiKey={`${process.env.REACT_APP_PLACES_KEY}`}
                    libraries={libraries}>
                    <App auth={auth} />
                  </LoadScript>
                )
              }}
            />
          </>
        </Router>

        <CustomizedSnackbars />
      </PersistGate>
    </Provider>
  )
}
