import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withWidth } from '@material-ui/core'

import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { getLocationAddress, getStyles } from '../../Constants/utilityFunctions'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LocationCard from '../../Components/LocationCard'

class LocationsListPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    let merchantId = this.props.merchantId
    this.props.getMerchantLocations(merchantId)
  }

  handleAddLocationButtonClick = () => {
    this.props.history.push('/add-location')
  }

  render() {
    const { onboarding, width, value, index } = this.props
    const mobileWidths = ['xs', 'sm']
    let styles = getStyles(width)
    const isMobile = mobileWidths.includes(width)

    return (
      <>
        {value === index && (
          <div style={styles.overviewPageSection}>
            <div style={styles.locationListHeader}>
              <DarkBlueButton
                size="large"
                variant="contained"
                onClick={this.handleAddLocationButtonClick}>
                + Add Locations
              </DarkBlueButton>
            </div>

            <div style={styles.addressLocationContainer}>
              {onboarding.locations.length > 0 ? (
                <>
                  {onboarding.locations.map((location, index) => (
                    <LocationCard
                      isMobile={isMobile}
                      styles={styles}
                      key={index}
                      locationAddress={getLocationAddress(location)}
                      locationData={location}
                    />
                  ))}
                </>
              ) : (
                <div>There are no locations for this merchant</div>
              )}
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMerchantLocations: merchantId =>
      dispatch(MerchantApiActions.getMerchantLocations(merchantId)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(LocationsListPage)),
)
