import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CountryRegionData } from 'react-country-region-selector'
import { MenuItem } from '@material-ui/core'

import CustomColorPhoneInput from '../Components/UI/CustomColorPhoneInput'
import { validatePhone } from '../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import CssTextField from '../Components/UI/CssTextField'

class LocationAddressForm extends Component {
  state = {
    name: false,
    phone: false,
    address: false,
    state: false,
    city: false,
    zip: false,
  }

  handlePhoneInput = value => {
    this.props.addLocationPhoneNumber(value)
  }

  componentDidMount = () => {
    // Return validateForm() as a callback so it can be
    // accessed by its parent element
    this.props.callValidateForm(this.validateForm.bind(this))
  }

  validateForm = () => {
    const form = this.props.onboarding.locationAddress
    const phoneNumber = this.props.onboarding.googlePlaceDetails.phone

    this.setState({
      name: form.name === '',
      phone: validatePhone(phoneNumber),
      address: form.streetAddress1 === '',
      state: form.state === '',
      city: form.city === '',
      zip: form.zipCode === '',
    })

    let phoneError = validatePhone(phoneNumber)
    let addressFormErrors =
      form.streetAddress1 === '' || form.state === '' || form.city === '' || form.zipCode === ''

    return form.name === '' || phoneError || addressFormErrors
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  getRegions = country => {
    if (!country) {
      return []
    }
    return country[2].split('|').map(regionPair => {
      let regionName = regionPair.split('~')
      return regionName
    })
  }

  render() {
    const { onboarding, styles } = this.props

    var currentPage = localStorage.getItem('onboardingPage')
    var textHalfWidthStyle = styles.textFieldHalfWidth
    var textFullWidthStyle = styles.textFieldFullWidth

    var enableForm = (!onboarding.locationDetails.locationCreated  && !this.props.disableAfterAdding) || currentPage == null

    if (!enableForm) {
        textHalfWidthStyle = styles.textDisabledFieldHalfWidth
        textFullWidthStyle = styles.textDisabledFieldFullWidth
    }

    return (
      <div>
        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Location name"
            margin="normal"
            error={this.state.name}
            variant="outlined"
            disabled = {!enableForm}
            value={onboarding.locationAddress.name}
            style={textHalfWidthStyle}
            onChange={this.handleChange('addLocationName')}
          />

          <div style={{ padding: '0 8px' }} />

          <CustomColorPhoneInput
            label="Phone number"
            margin="normal"
            error={this.state.phone}
            variant="outlined"
            defaultCountry="us"
            disabled = {!enableForm}
            style={textHalfWidthStyle}
            value={onboarding.googlePlaceDetails.phone}
            onChange={value => this.handlePhoneInput(value)}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Address 1"
            margin="normal"
            error={this.state.address}
            variant="outlined"
            style={textFullWidthStyle}
            disabled = {!enableForm}
            value={onboarding.locationAddress.streetAddress1}
            onChange={this.handleChange('addLocationAddress1')}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Address 2"
            margin="normal"
            variant="outlined"
            style={textFullWidthStyle}
            disabled = {!enableForm}
            value={onboarding.locationAddress.streetAddress2}
            onChange={this.handleChange('addLocationAddress2')}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            select
            label="Province or State"
            margin="normal"
            error={this.state.state}
            variant="outlined"
            value={onboarding.locationAddress.state}
            disabled = {!enableForm}
            style={textHalfWidthStyle}
            onChange={this.handleChange('addLocationState')}>
            {this.getRegions(onboarding.locationAddress.country).map(option => (
              <MenuItem key={option[1]} value={option[1]}>
                {option[0]}
              </MenuItem>
            ))}
          </CssTextField>

          <div style={{ padding: '0 8px' }} />

          <CssTextField
            select
            label="Country"
            margin="normal"
            variant="outlined"
            value={onboarding.locationAddress.country}
            disabled = {!enableForm}
            style={textHalfWidthStyle}
            onChange={this.handleChange('addLocationCountry')}>
            {CountryRegionData.map(option => (
              <MenuItem key={option[0]} value={option}>
                {option[0]}
              </MenuItem>
            ))}
          </CssTextField>
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="City"
            margin="normal"
            error={this.state.city}
            variant="outlined"
            disabled = {!enableForm}
            style={textHalfWidthStyle}
            value={onboarding.locationAddress.city}
            onChange={this.handleChange('addLocationCity')}
          />

          <div style={{ padding: '0 8px' }} />

          <CssTextField
            label="Postal/Zip Code"
            margin="normal"
            error={this.state.zip}
            variant="outlined"
            disabled = {!enableForm}
            style={textHalfWidthStyle}
            value={onboarding.locationAddress.zipCode}
            onChange={this.handleChange('addLocationZipCode')}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addLocationName: name => dispatch(MerchantOnboardingActions.addLocationName(name)),
    addLocationPhoneNumber: phone =>
      dispatch(MerchantOnboardingActions.addLocationPhoneNumber(phone)),
    addLocationAddress1: street => dispatch(MerchantOnboardingActions.addLocationAddress1(street)),
    addLocationAddress2: unit => dispatch(MerchantOnboardingActions.addLocationAddress2(unit)),
    addLocationState: state => dispatch(MerchantOnboardingActions.addLocationState(state)),
    addLocationCountry: country => dispatch(MerchantOnboardingActions.addLocationCountry(country)),
    addLocationCity: city => dispatch(MerchantOnboardingActions.addLocationCity(city)),
    addLocationZipCode: zip => dispatch(MerchantOnboardingActions.addLocationZipCode(zip)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationAddressForm)
