import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from './Pages/HomePage'
import NewMerchant from './Pages/NewMerchant'
import NoMatch from './Pages/NoMatch'
import MerchantEditPage from './Pages/EditPages/MerchantEditPage'
import MerchantDetailsPage from './Pages/MerchantDetailsPage'
import PendingAgreements from './Pages/PendingAgreements'
import LegalAgreements from './Pages/LegalAgreements'
import LocationEditPage from './Pages/EditPages/LocationEditPage'
import ProfileEditPage from './Pages/EditPages/ProfileEditPage'
import RecoverPassword from './Pages/RecoverPassword'
import ConfirmEmail from './Pages/ConfirmEmail'
import ResetPassword from './Pages/ResetPassword'
import AddLocations from './Pages/MerchantOnboarding/AddLocations'
import LoginForm from './Pages/LoginForm'
import SignupForm from './Pages/SignupForm'
import styles from './Styles/stylesDesktop'
import { createBrowserHistory } from "history";
import packageJson from "../package.json";
import { Provider, ErrorBoundary } from '@rollbar/react';

export default class App extends Component {

  render() {
    const { isAuthenticated } = this.props.auth
    // TODO: Do we need to use the historyContext function that Rollbar provides?
    const history = createBrowserHistory()

    const rollbarConfig = {
      accessToken: `${process.env.ROLLBAR_ACCESS_TOKEN}`,
      environment: `${process.env.ROLLBAR_ENVIRONMENT}`,
    }

    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <><div style={{ textAlign: 'center' }}>
            <header style={styles.appHeader}>
              {!isAuthenticated(history.location.pathname) && (
                <>
                  <Switch>
                    <Route exact path="/" render={() => <LoginForm auth={this.props.auth} />} />
                    <Route exact path="/sign-up" render={() => <SignupForm auth={this.props.auth} />} />
                    <Route exact path="/confirm-email/:query" component={ConfirmEmail} />
                    <Route exact path="/password-recovery" render={() => <RecoverPassword />} />
                    <Route path="/reset-password/:query" component={ResetPassword} />
                    <Route component={NoMatch} />
                  </Switch>
                </>
              )}
              {isAuthenticated(history.location.pathname) && (
                <>
                  <Route exact path="/" render={() => <HomePage />} />
                  <Route
                    path="/pending-agreements"
                    render={() => <PendingAgreements auth={this.props.auth} />} />
                  <Route path="/legal-agreements" render={() => <LegalAgreements />} />
                  <Route path="/new-merchant" render={() => <NewMerchant auth={this.props.auth} />} />
                  <Route
                    path="/merchant/:id"
                    render={() => <MerchantDetailsPage auth={this.props.auth} />} />
                  <Route path="/edit-merchant/:id" render={() => <MerchantEditPage />} />
                  <Route path="/edit-location/:id" render={() => <LocationEditPage />} />
                  <Route path="/add-location" render={() => <AddLocations />} />
                  <Route path="/user-profile" render={() => <ProfileEditPage />} />
                  <Route component={NoMatch} />
                </>
              )}
            </header>
          </div><div style={styles.appFooter}>Version {packageJson.version}</div></>
        </ErrorBoundary>
      </Provider>
    )
  }
}
