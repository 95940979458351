import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, withWidth } from '@material-ui/core'

import { MerchantApiActions } from '../Actions/merchantApiActions'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import {
  getStyles,
  getLocationAddress,
  getOfferType,
} from '../Constants/utilityFunctions'
import OfferCard from '../Components/OfferCard'
import LocationCard from '../Components/LocationCard'
import HomePageKPIs from '../Components/HomePageKPIs'
import RemainingActions from '../Components/RemainingActions'
import OrderCards from '../Components/OrderCards'
import { BLUE } from '../Constants/cssColors'

class OverviewPage extends Component {
  handleTabChange = tabIndex => {
    window.scrollTo(0, 0)
    this.props.switchAppBarTab(tabIndex)
  }

  render() {
    const { onboarding, width, value, index } = this.props
    const mobileWidths = ['xs', 'sm']
    let styles = getStyles(width)
    const isMobile = mobileWidths.includes(width)

    return (
      <>
        {value === index && (
          <div style={styles.overviewPageContainer}>
            <div style={styles.overviewPageSection}>
              <OrderCards />
              <RemainingActions isMobile={isMobile} styles={styles} />
            </div>

            <div style={styles.overviewPageSection}>
              <div style={{ display: 'flex' }}>
                <Button style={{ color: `${BLUE}` }} onClick={() => this.handleTabChange(1)}>
                  Reporting &gt;
                </Button>
              </div>

              <HomePageKPIs styles={styles} />
            </div>

            {onboarding.merchantOffers.length > 0 ? (
              <div style={styles.overviewPageSection}>
                <div style={{ display: 'flex' }}>
                  <Button style={{ color: `${BLUE}` }} onClick={() => this.handleTabChange(2)}>
                    Offers &gt;
                  </Button>
                </div>

                <div style={styles.reportingSection}>
                  {onboarding.merchantOffers.map((offer, index) => (
                    <OfferCard
                      styles={styles}
                      key={index}
                      offerType={getOfferType(offer)}
                      offersProps={offer}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}

            {onboarding.locations.length > 0 ? (
              <div style={styles.overviewPageSection}>
                <div style={{ display: 'flex' }}>
                  <Button style={{ color: `${BLUE}` }} onClick={() => this.handleTabChange(3)}>
                    Locations &gt;
                  </Button>
                </div>

                <div style={styles.addressLocationContainer}>
                  {onboarding.locations.map((location, index) => (
                    <LocationCard
                      isMobile={isMobile}
                      styles={styles}
                      key={index}
                      locationAddress={getLocationAddress(location)}
                      locationData={location}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    switchAppBarTab: index => dispatch(MerchantOnboardingActions.switchAppBarTab(index)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(OverviewPage))
