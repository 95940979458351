import { OnboardingApiCalls, MerchantOnboarding, Auth } from '../Constants/action-types'
import { initialState } from '../Store/initialState'

export default function newMerchantReducers(state = initialState.newMerchant, action) {
  if (action.type === MerchantOnboarding.MERCHANT_ID) {
    return {
      ...state,
      merchantId: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_CREATED) {
    return {
      ...state,
      merchantId: action.payload.merchant_id,
    }
  }

  if (action.type === MerchantOnboarding.LOGO_IMAGE) {
    return {
      ...state,
      logoImage: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.BANNER_IMAGE) {
    return {
      ...state,
      bannerImage: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_DETAILS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    } else {
      return {
        ...state,
        merchantId: action.payload.merchant_id,
        activeMerchant: action.payload,
      }
    }
  }

  if (action.type === OnboardingApiCalls.AVG_TICKET_VALUE_REPORT_RETRIEVED) {
    return {
      ...state,
      avgTicketValueReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.ALL_TIME_TRANSACTIONS_REPORT_RETRIEVED) {
    return {
      ...state,
      allTimeTransactionsReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MONTH_TO_DATE_TRANSACTIONS_REPORT_RETRIEVED) {
    return {
      ...state,
      monthToDateTransactionsReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.TOTAL_POINTS_ISSUED_REPORT_RETRIEVED) {
    return {
      ...state,
      totalPointsIssuedReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.TOTAL_SPENT_REPORT_RETRIEVED) {
    return {
      ...state,
      totalSpentReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MONTH_TO_DATE_SPENT_REPORT_RETRIEVED) {
    return {
      ...state,
      monthToDateSpentReport: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED) {
    return {
      ...state,
      firstTimeCustomers: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED) {
    return {
      ...state,
      monthToDateFirstTimeCustomers: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.VIRTUAL_CARD_CREATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    } else {
      return {
        ...state,
        virtualCard: action.payload,
      }
    }
  }

  if (action.type === OnboardingApiCalls.VIRTUAL_CARDS_LIST) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    } else {
      return {
        ...state,
        virtualCardsList: action.payload.cards,
      }
    }
  }

  if (action.type === OnboardingApiCalls.VIRTUAL_CARD_INFO) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    } else {
      return {
        ...state,
        virtualCard: action.payload,
      }
    }
  }

  if (action.type === OnboardingApiCalls.VIRTUAL_CARD_URL) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    } else {
      return {
        ...state,
        virtualCardUrl: action.payload.url,
      }
    }
  }

  if (action.type === MerchantOnboarding.OPEN_VIRTUAL_CARD_MODAL) {
    return {
      ...state,
      virtualCardModal: true,
    }
  }

  if (action.type === MerchantOnboarding.CLOSE_VIRTUAL_CARD_MODAL) {
    return {
      ...state,
      virtualCardModal: false,
    }
  }

  if (action.type === Auth.USER_LOGGED_OUT) {
    return {
      ...initialState.newMerchant,
    }
  }
  
  return state
}
