import { HOVER_BLUE, LIGHT_BLUE } from '../../Constants/cssColors'
import { getMonthAndYear } from '../../Constants/utilityFunctions'

export default function getPointsIssued(data) {
  let formattedData = []

  if (data.points_issued_per_date_range != null) {
    Object.entries(data.points_issued_per_date_range).forEach((entry, index) => {
      formattedData.unshift({
        month: getMonthAndYear(entry[0]),
        value: entry[1],
        color: index % 2 === 0 ? `${LIGHT_BLUE}` : `${HOVER_BLUE}`,
      })
    })
  }

  return formattedData
}
