import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { moveToNextPage, getLocationLatLong, getStyles } from '../../Constants/utilityFunctions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { NotificationsActions } from '../../Actions/notificationsActions'
import LocationAddressForm from '../../Components/LocationAddressForm'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LightBlueButton from '../../Components/UI/LightBlueButton'
import { newLocationHash } from '../../Constants/apiHashes'
import GoogleMapAndSearchBox from '../../Components/GoogleMapAndSearchBox'
import GooglePlacesList from '../../Components/GooglePlacesList'

class AddLocations extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.clearLocationAddressForm()
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handleBackButtonPress = () => {
    this.props.history.goBack()
  }

  handleNextButtonPress = () => {
    moveToNextPage()
  }

  handleMerchantLocationAdd = () => {
    let form = this.props.onboarding
    let merchantId = this.props.newMerchant.merchantId

    if (!this.validateLocationAddressForm()) {
      if (form.googlePlaceDetails.latitude === '') {
        getLocationLatLong(form.locationAddress)
          .then(() => {
            form = this.props.onboarding
            this.props.createMerchantLocation(newLocationHash(form, merchantId))
          })
          .catch(err => {
            this.props.displayErrorSnackbar(err)
          })
      } else {
        this.props.createMerchantLocation(newLocationHash(form, merchantId))
      }
    }
  }

  render() {
    const { onboarding, width } = this.props
    let styles = getStyles(width)
    let currentPage = localStorage.getItem('onboardingPage')

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>Add locations</h1>
        <div style={styles.subtitle}>
          Add your location details that will be displayed to customers in the Buoy Local App. If
          you have multiple locations, add them one at a time.
        </div>

        <GoogleMapAndSearchBox styles={styles} />

        <GooglePlacesList styles={styles} />

        <div style={styles.detailsContainer}>
          <h4>Location Details</h4>

          <LocationAddressForm
            styles={styles}
            callValidateForm={addressValidation =>
              (this.validateLocationAddressForm = addressValidation)
            }
          />

          <br />
          {currentPage === 'addLocationsPage' ? (
            <DarkBlueButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={this.handleMerchantLocationAdd}
              disabled={onboarding.locationDetails.locationCreated}
              style={{ alignSelf: 'flex-start' }}>
              + Add this location
            </DarkBlueButton>
          ) : (
            <DarkBlueButton
              variant="outlined"
              color="primary"
              size="large"
              onClick={this.handleMerchantLocationAdd}
              style={{ alignSelf: 'flex-start' }}>
              + Add this location
            </DarkBlueButton>

          )}
        </div>

        <div style={{ margin: '1rem 0', display: 'flex', alignSelf: 'flex-start' }}>
          {currentPage === 'addLocationsPage' ? (
            <DarkBlueButton
              variant="contained"
              size="large"
              onClick={this.handleNextButtonPress}
              disabled={!onboarding.locationDetails.locationCreated}>
              Next
            </DarkBlueButton>
          ) : (
            <LightBlueButton variant="contained" size="large" onClick={this.handleBackButtonPress}>
              Back
            </LightBlueButton>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayErrorSnackbar: error => dispatch(NotificationsActions.displayErrorSnackbar(error)),
    clearLocationAddressForm: () => dispatch(MerchantOnboardingActions.clearLocationAddressForm()),
    createMerchantLocation: data => dispatch(MerchantApiActions.createMerchantLocation(data)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(AddLocations)),
)
