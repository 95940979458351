import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'

import { getStyles } from '../Constants/utilityFunctions'
import { AuthActions } from '../Actions/authActions'
import LegalNoticesAccordion from '../Components/UI/LegalNoticesAccordion'
import LogoutLink from '../Components/UI/LogoutLink'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import CustomColorCheckbox from '../Components/UI/CustomColorCheckbox'

class PendingAgreements extends Component {
  state = {
    OnboardingCheckboxEnabled: false,
    EsignCheckboxEnabled: false,
  }

  componentDidMount = () => {
    this.props.getPendingLegalNotices()
  }

  handleAcceptButtonPress = () => {
    const { notices } = this.props.authForm

    notices.forEach(notice => {
      this.props.acceptLegalNotices(notice['id'])
    })
  }

  handleEsignCheckboxChange = () => event => {
    this.setState({
      EsignCheckboxEnabled: event.target.checked,
    })
  }

  handleOnboardingCheckboxChange = () => event => {
    this.setState({
      OnboardingCheckboxEnabled: event.target.checked,
    })
  }

  render() {
    const { notices } = this.props.authForm
    const { OnboardingCheckboxEnabled, EsignCheckboxEnabled} = this.state
    const { width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <LogoutLink auth={this.props.auth} />

        <h1 style={styles.title}>Accept Agreements</h1>

        <LegalNoticesAccordion notices={notices} styles={styles} />

        <div style={styles.agreementsAcceptSection}>
          <CustomColorCheckbox onChange={this.handleEsignCheckboxChange()} />
          <div style={styles.acceptAgreementText}>I agree to the E-sign Agreement</div>
        </div>

        <div style={styles.agreementsAcceptSection}>
          <CustomColorCheckbox
            onChange={this.handleOnboardingCheckboxChange()}
          />
          <div style={styles.acceptAgreementText}>I agree to the Merchant Onboarding Agreement</div>
        </div>

        <div
          style={{
            margin: '1rem 0',
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-start',
          }}>
          <DarkBlueButton
            size="large"
            variant="contained"
            disabled={ !(EsignCheckboxEnabled && OnboardingCheckboxEnabled) }
            onClick={this.handleAcceptButtonPress}>
            Next
          </DarkBlueButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingLegalNotices: () => dispatch(AuthActions.getPendingLegalNotices()),
    acceptLegalNotices: id => dispatch(AuthActions.acceptLegalNotices(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(PendingAgreements))
