import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import LogoutLink from '../Components/UI/LogoutLink'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import CreateMerchant from '../Pages/MerchantOnboarding/CreateMerchant'
import CreateOffers from '../Pages/MerchantOnboarding/CreateOffers'
import AddLocations from '../Pages/MerchantOnboarding/AddLocations'
import VerifyTerminal from '../Pages/MerchantOnboarding/VerifyTerminal'
import BillingDetails from '../Pages/MerchantOnboarding/BillingDetails'
import AwaitingReview from '../Pages/MerchantOnboarding/AwaitingReview'

class NewMerchant extends Component {
  componentDidMount = () => {
    let createdMerchantId = localStorage.getItem('selectedMerchant')

    if(createdMerchantId) {
      this.props.addMerchantId(createdMerchantId)
    }
  }

  render() {
    const currentPage = localStorage.getItem('onboardingPage')

    return(
      <div>
        <LogoutLink auth={this.props.auth} />
        { currentPage === 'merchantAccountPage' && <CreateMerchant /> }
        { currentPage === 'setUpOffersPage' && <CreateOffers /> }
        { currentPage === 'addLocationsPage' && <AddLocations disableAfterAdding={true} /> }
        { currentPage === 'verifyTerminalPage' && <VerifyTerminal /> }
        { currentPage === 'billingDetailsPage' && <BillingDetails /> }
        { currentPage === 'awaitingReviewPage' && <AwaitingReview /> }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addMerchantId: id => dispatch(MerchantOnboardingActions.addMerchantId(id)),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(NewMerchant))
