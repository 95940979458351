import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'

import RegularOfferButtons from './RegularOfferButtons'
import { getOfferType } from '../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import regularOfferImage from '../images/loyalty-offer.png'

class RegularOfferPanel extends Component {
  componentDidMount = () => {
    let merchantOffers = this.props.onboarding.merchantOffers

    for (let offer of merchantOffers) {
      if (getOfferType(offer) === 'regular') {
        this.props.setRegOfferAmount(parseInt(offer.amount))
        this.props.setRegOfferId(offer.id)
      }
    }
  }

  render() {
    const { offers } = this.props.onboarding
    const { styles } = this.props

    return (
      <Paper style={styles.offerContainer}>
        <div>
          <img src={regularOfferImage} alt="" style={styles.offerLogo} />
          <div style={styles.offerPanelTitle}>Loyalty Offer</div>
          <div style={styles.offerPanelSubtitle}>
            Set an ongoing offer for repeat customers. 1X Points equates to $.01
          </div>
        </div>

        <RegularOfferButtons offer={offers.regular} styles={styles} />
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setRegOfferId: id => dispatch(MerchantOnboardingActions.setRegOfferId(id)),
    setRegOfferAmount: amount => dispatch(MerchantOnboardingActions.setRegOfferAmount(amount)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegularOfferPanel)
