import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import CssTextField from './UI/CssTextField'

function GoogleMapWithSearchBox(props) {
  const [map, setMap] = React.useState(null)
  const [searchBox, setSearchBox] = React.useState(null);
  const [bounds, setBounds] = React.useState(null);
  const [center, setCenter] = React.useState({ lat: 44.8016, lng: -68.7712 })
  const [markers, setMarkers] = React.useState([])

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces()

    props.addGooglePlaceResults(places)

    const bounds = new google.maps.LatLngBounds()

    places.forEach((place) => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport)
      } else {
        bounds.extend(place.geometry.location)
      }
    })

    const nextMarkers = places.map((place) => ({
      position: place.geometry.location,
    }))

    const nextCenter = _.get(nextMarkers, '0.position', center)

    setCenter(nextCenter)
    setMarkers(nextMarkers)

    map.fitBounds(bounds)
  }

  const onBoundsChanged = () => {
    setBounds(map.getBounds())
    setCenter(map.getCenter())
  }

  const styles = {
    width: '100%',
    height: '300px',
  }


  return (
    <div style={props.styles.locationSearchFieldContainer}>
      <StandaloneSearchBox
        onLoad={(searchBox) => setSearchBox(searchBox)}
        bounds={bounds}
        onPlacesChanged={onPlacesChanged}>
        <CssTextField
          variant="outlined"
          placeholder="Search your business by name or phone"
          style={props.styles.locationSearchField}
        />
      </StandaloneSearchBox>

      <GoogleMap
        onLoad={(map) => setMap(map)}
        mapContainerStyle={styles}
        zoom={7}
        center={center}
        onBoundsChanged={onBoundsChanged}
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
      >
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position} />
        ))}
      </GoogleMap>
    </div>
  )
}


function mapDispatchToProps(dispatch) {
  return {
    addGooglePlaceResults: (results) =>
      dispatch(MerchantOnboardingActions.addGooglePlaceResults(results)),
  }
}

class GoogleMapWithSearchBoxContainer extends React.Component {
  render() {
    return <GoogleMapWithSearchBox {...this.props} />
  }
}

export default connect(null, mapDispatchToProps)(GoogleMapWithSearchBoxContainer)
