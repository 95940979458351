import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'

import { getStyles } from '../Constants/utilityFunctions'
import { resetUserPasswordHash } from '../Constants/apiHashes'
import { parseQueryString } from '../Constants/utilityFunctions'
import { AuthActions } from '../Actions/authActions'
import CssTextField from '../Components/UI/CssTextField'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import bsb from '../images/buoy-local-logo-empowering.png'

class ResetPassword extends Component {
  state = {
    password: false,
    confirmPassword: false,
  }

  componentDidMount = () => {
    let query = this.props.match.params.query
    let params = parseQueryString(query)

    for (let [key, value] of Object.entries(params)) {
      if (key === 'email') {
        this.props.addUserEmail(value)
      }

      if (key === 'reset_password_token') {
        this.props.resetPasswordToken(value)
      }
    }
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  validateForm = () => {
    let form = this.props.authForm
    let passwordConfirmError = true

    this.setState({
      password: form.userPassword === '',
      confirmPassword: form.confirmPassword === '',
    })

    if (form.userPassword === form.confirmPassword) {
      passwordConfirmError = false
    } else {
      this.setState({
        password: true,
        confirmPassword: true,
      })
    }

    return form.userPassword === '' || form.confirmPassword === '' || passwordConfirmError
  }

  handleSubmitClick() {
    const form = this.props.authForm

    if (!this.validateForm()) {
      this.props.resetPassword(resetUserPasswordHash(form))
    }
  }

  render() {
    const { authForm, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.loginPageContainer}>
        <div style={styles.pageContainer}>
          <div style={styles.loginContainer}>
            <img src={bsb} alt="" style={styles.buoyLogo} />
            <div style={styles.loginSignupTitle}>Reset Password</div>

            <CssTextField
              error={this.state.password}
              margin="normal"
              variant="outlined"
              label="Password"
              type="password"
              style={styles.textFieldMediumWidth}
              value={authForm.userPassword}
              onChange={this.handleChange('addUserPassword')}
            />

            <CssTextField
              error={this.state.confirmPassword}
              margin="normal"
              variant="outlined"
              label="Confirm Password"
              type="password"
              style={styles.textFieldMediumWidth}
              value={authForm.confirmPassword}
              onChange={this.handleChange('confirmUserPassword')}
            />

            <br />

            <DarkBlueButton
              size="large"
              variant="contained"
              style={styles.loginButton}
              onClick={() => this.handleSubmitClick()}>
              Reset Password
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUserEmail: email => dispatch(AuthActions.addUserEmail(email)),
    addUserPassword: password => dispatch(AuthActions.addUserPassword(password)),
    confirmUserPassword: password => dispatch(AuthActions.confirmUserPassword(password)),
    resetPassword: email => dispatch(AuthActions.resetPassword(email)),
    resetPasswordToken: token => dispatch(AuthActions.resetPasswordToken(token)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(ResetPassword))
