import React, { Component } from 'react'
import { connect } from 'react-redux'

import CustomColorTabs from './CustomColorTabs'
import CustomColorTab from './CustomColorTab'
import { refreshKPIReporting } from '../../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'

class HomePageTabs extends Component {
  handleTabChange = tabIndex => {
    this.props.getMerchantDetails(this.props.match.params.id)

    window.scrollTo(0, 0)
    this.props.switchAppBarTab(tabIndex)

    if(tabIndex === 1) {
      const { newMerchant, onboarding } = this.props
      refreshKPIReporting(newMerchant, onboarding)
    }
  }

  handleEditMerchantClick = () => {
    let merchantId = this.props.match.params.id
    this.props.history.push(`/edit-merchant/${merchantId}`)
  }

  render() {
    const { appBarTab } = this.props.onboarding
    const { styles } = this.props

    return (
      <CustomColorTabs
        value={appBarTab}
        onChange={(e, idx) => this.handleTabChange(idx)}
        variant="fullWidth"
        centered>
        <CustomColorTab style={styles.homePageTabFonts} label="Overview" />
        <CustomColorTab style={styles.homePageTabFonts} label="Reporting" />
        <CustomColorTab style={styles.homePageTabFonts} label="Offers" />
        <CustomColorTab style={styles.homePageTabFonts} label="Locations" />
      </CustomColorTabs>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    switchAppBarTab: index => dispatch(MerchantOnboardingActions.switchAppBarTab(index)),
    getMerchantDetails: (id) => dispatch(MerchantApiActions.getMerchantDetails(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePageTabs)
