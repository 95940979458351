import { Auth } from '../Constants/action-types'
import { initialState } from '../Store/initialState'

export default function authReducers(state = initialState.authForm, action) {
  if (action.type === Auth.USER_EMAIL) {
    return {
      ...state,
      userEmail: action.payload
    }
  }

  if (action.type === Auth.USER_NAME) {
    return {
      ...state,
      userName: action.payload
    }
  }

  if (action.type === Auth.USER_PHONE) {
    return {
      ...state,
      userPhone: action.payload
    }
  }

  if (action.type === Auth.USER_PASSWORD) {
    return {
      ...state,
      userPassword: action.payload
    }
  }

  if (action.type === Auth.CONFIRM_PASSWORD) {
    return {
      ...state,
      confirmPassword: action.payload
    }
  }

  if (action.type === Auth.USER_LOGGED_IN) {
    if(action.payload.user) {
      return {
        ...state,
        userId: action.payload.user.id,
        userName: action.payload.user.name,
        userEmail: action.payload.user.email,
        userPhone: action.payload.user.phone,
        apiError: ''
      }
    }

    if(action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error
      }
    }
  }

  if (action.type === Auth.USER_LOGGED_OUT) {
    return {
      ...initialState.authForm,
    }
  }

  if (action.type === Auth.USER_SIGNED_UP) {
    if(!action.payload.error) {
      return {
        ...state,
        userId: action.payload.user.id,
        userName: action.payload.user.name,
        userEmail: action.payload.user.email,
        userPhone: action.payload.user.phone,
        apiError: '',
      }
    }

    if(action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error
      }
    }
  }

  if (action.type === Auth.USER_PROFILE_RETRIEVED) {
    return {
      ...state,
      userId: action.payload.id,
      userName: action.payload.name,
      userEmail: action.payload.email,
      userPhone: action.payload.phone,
      apiError: action.payload.error,
    }
  }

  if (action.type === Auth.USER_PROFILE_UPDATED) {
    if(action.payload.user) {
      return {
        ...state,
        userId: action.payload.id,
        userName: action.payload.name,
        userEmail: action.payload.email,
        userPhone: action.payload.phone,
        apiError: action.payload.error,
      }
    }
  }

  if (action.type === Auth.LEGAL_NOTICES) {
    return {
      ...state,
      notices: action.payload
    }
  }

  if (action.type === Auth.LEGAL_NOTICES_ACCEPTED) {
    return {
      ...state,
      legalNoticesId: '',
    }
  }

  if (action.type === Auth.RESET_PASSWORD_TOKEN) {
    return {
      ...state,
      resetPasswordToken: action.payload,
    }
  }

  return state
}
