import { Tab } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE, LIGHT_BLUE_BUTTON } from '../../Constants/cssColors'

export default withStyles({
  root: {
    '&:hover': {
      backgroundColor: `${LIGHT_BLUE_BUTTON}`,
    },
    '&$selected': {
      '& *': {
        color: `${BLUE}`,
      }
    },
  },
  wrapper: {
    color: 'grey',
  },
  selected: {
    color: `${BLUE}`,
  }
})(Tab)
