export const BLUE = '#007AFF'
export const HOVER_BLUE = '#026BDB'
export const LIGHT_BLUE = '#2B9FE2'
export const LIGHT_BLUE_BUTTON = '#E4F1FF'
export const LIGHT_BLUE_BUTTON_HOVER = '#DCE6F2'
export const GREY = '#6D6D6D'
export const OUTLINE_GREY = '#D6D6D6'
export const LIGHT_GREY = '#C4C4C4'
export const OVERVIEW_PAGE_GREY = '#FAFCFC'
export const YELLOW = '#FFD006'
export const LIGHT_YELLOW = '#FFF1B9'
export const GREEN = '#19C283'
export const LIGHT_GREEN = '#ECFEF7'
export const RED = '#EB1111'
export const HOVER_RED = '#DB1111'
