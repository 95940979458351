import { BLUE, OUTLINE_GREY, GREY, OVERVIEW_PAGE_GREY } from '../Constants/cssColors'

export default {
  ////////// General Page Styles //////////
  appHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  appFooter: {
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    padding: 6,
    backgroundColor: "#efefef",
    textAlign: "Center",
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  loginPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: `black`,
    alignItems: 'center',
    padding: '2rem',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    width: '42vw',
    textAlign: 'initial',
    alignSelf: 'flex-start',
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: '200',
    textAlign: 'initial',
    width: '42vw',
    alignSelf: 'flex-start',
  },
  infoText: {
    fontSize: '1rem',
    fontWeight: 200,
    textAlign: 'left',
  },
  detailsContainer: {
    margin: '1rem 0',
    width: '42vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  textFieldFullWidth: {
    width: '42vw',
  },
  textDisabledFieldFullWidth: {
    width: '42vw',
    backgroundColor:'#F1F1F1',
  },
  textFieldMediumWidth: {
    width: '30vw',
  },
  textFieldHalfWidth: {
    width: '20vw',
  },
  textDisabledFieldHalfWidth: {
    width: '20vw',
    backgroundColor:'#F1F1F1',
  },
  merchantInputFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '42vw',
  },
  ////////// Upload Image Buttons //////////
  buttonImageRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '42vw',
  },
  buttonContainer: {
    width: '15vw',
    height: '10vW',
    margin: '1vw 0',
    padding: '1vw',
    border: `3px solid ${OUTLINE_GREY}`,
    background: `${OVERVIEW_PAGE_GREY}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buoyLogo: {
    width: '8rem',
  },
  buttonIcon: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'contain',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'initial',
  },
  imageButtonTitle: {
    color: '#55575A',
    margin: '1rem 0',
  },
  imageUploadError: {
    color: 'red',
    fontSize: '0.9rem',
    width: '16vw',
  },
  imageButtonInfoText: {
    fontSize: '0.7rem',
    fontWeight: 200,
    width: '16vw',
    height: '4vw',
  },
  uploadImageIcon: {
    width: '6vw',
    height: '6vw',
  },
  /////////// Social Media Links //////////
  fontAwesomeIcon: {
    height: '2vw',
    width: '2vw',
  },
  handlePrefix: {
    fontSize: '1.5rem',
    marginLeft: '1vw',
    marginRight: '-0.5vw',
  },
  ////////// Login, Signup, Password Recovery //////////
  loginSignupTitle: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '2rem',
    fontWeight: 'bold',
    padding: '1.5rem 0.5rem 0.5rem',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30vw',
    margin: '1vw',
    padding: '5rem 1vw 1vw',
  },
  loginButton: {
    width: '30vw',
    padding: '1vw',
    margin: '1vw 0',
  },
  forgotPassword: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    margin: '1rem 0',
    cursor: 'pointer',
    color: `${BLUE}`,
  },
  signUpOrLogIn: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    cursor: 'pointer',
    color: `${BLUE}`,
  },
  ////////// Google Places //////////
  googlePlacesList: {
    width: '42vw',
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    border: '1px solid black',
  },
  googlePlaceIcon: {
    height: '2rem',
    width: '2rem',
    marginRight: '1rem',
  },
  googlePlaceTitleAndAddress: {
    display: 'flex',
    flexDirection: 'column',
    width: '24vw',
  },
  selectButton: {
    fontSize: '1vw',
  },
  ////////// App Bar //////////
  profileAppBar: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 200,
    backgroundColor: `white`,
  },
  profileToolBar: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  homePageTabs: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '1vw',
  },
  appBarBuoyLogo: {
    width: '5vw',
    position: 'absolute',
    left: '50%',
    marginTop: 5,
  },
  appBarMerchantLogo: {
    width: '3vw',
    height: '3vw',
    objectFit: 'contain',
  },
  homePageTabFonts: {
    margin: '0 2vw',
  },
  /////////// Edit User //////////
  editUserButtonRow: {
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    width: '30vw',
  },
  backSaveButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  /////////// Locations //////////
  locationCard: {
    width: '21vw',
    margin: '1vw 1.5vw 1vw 0',
  },
  locationsMap: {
    height: '100%',
    width: '42vw',
  },
  mapContainer: {
    height: '300px',
    margin: '1rem 0 6rem',
  },
  locationSearchField: {
    width: '42vw',
    paddingTop: '1vw',
    paddingBottom: '2vw',
  },
  locationSearchFieldContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    marginTop: "1vw",
  },
  locationCardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    fontSize: '1.2vw',
    textAlign: 'left',
    height: '6vw',
    justifyContent: 'space-around',
  },
  locationCardAddress: {
    color: '#6A6767',
    fontSize: '0.9vw',
    textAlign: 'left',
  },
  locationCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '15vw',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'space-around',
  },
  locationCardFooter: {
    width: '100%',
    margin: '0.7vw 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addressLocationContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  googleImageContainer: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  locationCardRow: {
    marginBottom: '0.8vw',
    fontSize: '0.9vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  locationCardButtonRow: {
    margin: '0.5rem 0',
    display: 'flex',
    flexFlow: 'column',
  },
  locationListHeader: {
    display: 'flex',
    margin: '3vw 0',
    alignSelf: 'flex-start',
  },
  terminalVerificationInfo: {
    color: `${GREY}`,
    margin: '1rem 0',
  },
  ////////// Awaiting Review //////////
  buoyLogoAwaitingPage: {
    width: '10vw',
    marginBottom: '1rem',
  },
  ////////// Legal Notices //////////
  agreementsContainer: {
    width: '65vw',
  },
  agreementBody: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
  legalNoticeTitle: {
    fontSize: '2rem',
  },
  acceptAgreementText: {
    fontSize: '1.3rem',
  },
  agreementsAcceptSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  ////////// Overview Page //////////
  homePageContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Source Sans Pro, sans-serif',
    alignItems: 'center',
    padding: '2rem 0',
    backgroundColor: `${OVERVIEW_PAGE_GREY}`,
    minHeight: '100vh',
    width: '100%',
  },
  overviewPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: `black`,
    alignItems: 'center',
    padding: '3rem',
  },
  swipeableViewContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  overviewPageSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '66vw',
    marginTop: '3vw',
  },
  reportingSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  reportingCard: {
    width: '21vw',
    height: '12vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  reportingCardTitle: {
    color: `${GREY}`,
    fontSize: '1.5vw',
  },
  reportingCardContent: {
    fontSize: '1.2rem',
    textAlign: 'left',
    padding: '2.5vw 3vw',
  },
  offerCard: {
    width: '21vw',
    height: '15vw',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.5vw',
    justifyContent: 'center',
  },
  offerCardTitle: {
    margin: '1vw 0',
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  offerCardContent: {
    fontSize: '1.2rem',
    padding: '3vw',
  },
  reportingCardValue: {
    fontSize: '2rem',
    fontWeight: 400,
    margin: '0.5vw 0',
  },
  reportingCardFooter: {
    color: '#B6B6B6',
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'pre',
    fontSize: '1rem',
  },
  remainingActionsContainer: {
    textAlign: 'left',
    width: '43vw',
  },
  remainingActionsTitle: {
    fontSize: '1.5rem',
  },
  remainingActionsSubtitle: {
    fontSize: '1.2rem',
    color: `${GREY}`,
    margin: '1rem 0',
  },
  remainingActionsBox: {
    padding: '1rem 0 1rem 3rem',
  },
  remainingActionsEntry: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1rem 0',
  },
  remainingActionsCheckIcon: {
    color: `${OUTLINE_GREY}`,
    width: '2rem',
    height: '2rem',
    marginRight: '2rem',
  },
  remainingAction: {
    fontSize: '1.2rem',
    color: `${BLUE}`,
    textAlign: 'left',
  },
  remainingActionInfoIcon: {
    height: '2rem',
    padding: '0 1vw',
  },
  ////////// Offers Page //////////
  offerContainer: {
    width: '30vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2vw 0',
    margin: '2vw 2vw 0 0',
    justifyContent: 'space-around',
  },
  offersSubtitle: {
    fontSize: '1rem',
    fontWeight: '200',
    textAlign: 'initial',
    width: '60vw',
    alignSelf: 'flex-start',
    marginBottom: '0.5rem',
  },
  offerCardLogo: {
    height: '5vw',
  },
  offerPanelContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  editOffersPanelContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    marginTop: '2vw',
  },
  offerButtonContainer: {
    display: 'flex',
    margin: '0.5vw 0',
  },
  acqOfferButton: {
    fontSize: '1.3rem',
    padding: '0.5vw 3vw',
  },
  buttonTextSubtext: {
    fontSize: '0.8rem',
  },
  noSubtextButton: {
    padding: '0.5rem 0',
  },
  regOfferButton: {
    fontSize: '1.3rem',
    padding: '0.5vw 4.5vw',
  },
  offerPanelTitle: {
    fontSize: '1.5rem',
    fontWeight: 400,
    margin: '1rem 0 0.5rem',
  },
  offerPanelSubtitle: {
    fontSize: '1rem',
    color: `${GREY}`,
    padding: '0 3vw 1vw',
    height: '6vw',
  },
  offerLogo: {
    height: '3vw',
  },
  logoutLink: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '30px',
    fontSize: '0.9rem',
  },
  acqOfferInfoIcon: {
    height: '1.5rem',
    padding: '0 0.5vw',
  },
  ////////// Edit Side Panel //////////
  sidepanelTitle: {
    fontFamily: 'Source Sans Pro, sans-serif',
    margin: '2vw',
    textAlign: 'left',
    fontSize: '1.2rem',
  },
  sidepanel: {
    fontFamily: 'Source Sans Pro, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    width: '25vw',
    margin: '0 2vw',
  },
  bannerImage: {
    width: '25vw',
    height: '12vw',
    backgroundColor: '#E0DDDD',
    marginBottom: '5vw',
    objectFit: 'cover',
  },
  logoImage: {
    width: '8vw',
    height: '8vw',
    backgroundColor: 'white',
    position: 'absolute',
    marginTop: '8vw',
    marginLeft: '2.5vw',
    boxShadow: '0 3px 5px #888888',
    objectFit: 'contain',
  },
  merchantName: {
    fontSize: '2rem',
    textAlign: 'left',
    fontWeight: 600,
    margin: '0 2.5vw',
    wordWrap: 'break-word',
  },
  aboutMerchantSection: {
    fontSize: '1.3rem',
    fontWeight: 200,
    color: '#7C7C7C',
    margin: '2vw 2.5vw',
    textAlign: 'left',
    minHeight: '5vw',
    wordWrap: 'break-word',
  },
  contactInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 2.5vw 2vw',
  },
  contactSection: {
    width: '20vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1vw 0',
  },
  contactInfo: {
    marginLeft: '1vw',
    fontWeight: 200,
  },
  ////////// Graphs Section //////////
  graphSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '0.5vw 0',
  },
  graphContainer: {
    width: '32.5vw',
    height: '25vw',
  },
  ////////// Verify Terminals - onboarding //////////
  verifyTerminalPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '40vw',
  },
  verifyTerminalHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    background: '#F8F8F8',
    padding: '2rem',
  },
  locationName: {
    fontSize: 'larger',
    fontWeight: 700,
    margin: '0rem 1rem 1rem 0rem',
  },
  verificationCardSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1rem',
  },
  verificationCardIframe: {
    height: '11rem',
    marginRight: '1rem',
    borderWidth: 0,
  },
  yourVerificationCard: {
    fontWeight: 600,
    paddingBottom: '1rem',
  },
  yourVerificationCardSubtitle: {
    fontSize: '0.8rem',
    paddingBottom: '1rem',
  },
  verifyTerminalInstructionsList: {
    paddingBottom: '2rem',
    fontSize: '0.9rem',
    lineHeight: '1.5em',
    height: 'auto',
    marginRight: '1rem',
  },
  generateNewCardLink: {
   color: `${BLUE}`,
   cursor: 'pointer',
   fontSize: '0.9rem',
  },
  ////////// Verify Terminals - edit page //////////
  instructionsSet: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    lineHeight: '1.6',
  },
  instructionsList: {
    listStylePosition: 'inside',
    paddingLeft: '1rem',
  },
  activeTerminalsCount: {
    display: 'flex',
    marginBottom: '1rem',
  },
  existingVirtualCards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '1rem',
  },
  virtualCardModal: {
    width: 310,
    height: 185,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  virtualCardIFrame: {
    width: '40vw',
    height: '45vh',
  },
}
