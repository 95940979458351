import { TextField } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import { GREY, LIGHT_GREY, OUTLINE_GREY } from '../../Constants/cssColors'

export default withStyles({
  root: {
    '& label, label.Mui-focused': {
      color: `${GREY}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${GREY}`,
    },
    '& .MuiFormHelperText-root': {
      color: `${GREY}`,
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      textAlign: 'left',
    },
    '& .MuiSelect-icon': {
      color: `${GREY}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${OUTLINE_GREY}`,
      },
      '&:hover fieldset': {
        borderColor: `${LIGHT_GREY}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${GREY}`,
      },
    },
  },
})(TextField)
