import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { generateVirtualCardHash } from '../Constants/apiHashes'
import { MerchantApiActions } from '../Actions/merchantApiActions'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import VirtualCardModal from '../Components/VirtualCardModal'

class VerifyTerminalsSection extends Component {
  componentDidMount = () => {
    this.props.getVirtualCardsList()
  }

  getCardUrl = (cardId) => {
    this.props.getVirtualCardUrl(cardId)
  }

  handleGenerateCardClick = (location_id) => {
    this.props.createVirtualCard(generateVirtualCardHash(location_id))
  }

  render () {
    let { styles, onboarding, newMerchant, isMobile } = this.props
    const locationData = onboarding.locations.find(loc => loc.id.toString() === this.props.match.params.id)
    const virtualCards = newMerchant.virtualCardsList.filter(card => card.location_id === this.props.match.params.id)

    return (
      <div style={styles.detailsContainer}>
        <div style={styles.instructionsSet}>
          <h4>Terminals</h4>
          <div>
            Follow these steps to ensure that your terminals are setup to accept Buoy Local transactions:
            <ol style={styles.instructionsList}>
              <li>Select “Generate Virtual Card” below to present a test card</li>
              <li>Use the test card to process a manual entry transaction for $1.11 and select “credit” or “Visa” as the method of payment</li>
                  <div style={{ marginLeft: '1rem' }}>a. If prompted to enter an address, enter your business address</div>
              <li>The transaction will decline, which is expected</li>
              <li>You’re done! Repeat these steps for all terminals at your location</li>
            </ol>
          </div>
        </div>
        
        <div style={styles.activeTerminalsCount}>
          <div style={{ marginRight: '5px' }}>Active terminals:</div>

          <div
            style={
              locationData.active_terminals_count <= 0 ? { color: 'red' } : { color: 'black' }
            }>
            {locationData.active_terminals_count}
          </div>
        </div>

        {virtualCards.length > 0 ? 
          <div style={styles.existingVirtualCards}>
            <h4>Generated virtual cards:</h4>
            
            {virtualCards.map(card => (
              <button style={{ cursor: 'pointer', marginBottom: '10px' }} key={card.id} onClick={() => this.getCardUrl(card.id)}>
                {card.id}
              </button>
            ))}
          </div>
        : ''}

        <DarkBlueButton
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
          color="primary"
          onClick={() => this.handleGenerateCardClick(locationData.id)}>
          Generate virtual card
        </DarkBlueButton>

        <VirtualCardModal styles={styles} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createVirtualCard: payload => dispatch(MerchantApiActions.createVirtualCard(payload)),
    getVirtualCardsList: () => dispatch(MerchantApiActions.getVirtualCardsList()),
    getVirtualCardUrl: id => dispatch(MerchantApiActions.getVirtualCardUrl(id)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(VerifyTerminalsSection)
)
