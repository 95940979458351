import { takeLatest, takeEvery, call, put } from 'redux-saga/effects'
import { selectMerchant, moveToNextPage } from '../Constants/utilityFunctions'
import history from '../history'
import {
  Auth,
  MerchantOnboarding,
  OnboardingApiCalls,
} from '../Constants/action-types'

import {
  logInUser,
  signUpUser,
  logOutUser,
  getUserProfile,
  confirmEmail,
  updateUserProfile,
  deactivateUserProfile,
  recoverPassword,
  resetPassword,
  getPendingLegalNotices,
  getAllLegalNotices,
  acceptLegalNotices,
} from './sagaAuthActions'
import {
  createMerchant,
  updateMerchant,
  createOffer,
  deleteOffer,
  getMerchants,
  getMerchantDetails,
  getMerchantLocations,
  getMerchantTransactions,
  getLocationTransactions,
  createLocation,
  updateLocation,
  getMerchantOffers,
  getTagTypes,
  updateOffer,
  getAvgTicketValue,
  getAllTimeTransactions,
  getTotalPointsIssued,
  getTotalSpent,
  getFirstTimeCustomers,
  generateVirtualCard,
  getVirtualCardsList,
  getVirtualCard,
  getVirtualCardUrl,
  createCardsRequest,
} from './sagaMerchantActions'

export default function* watcherSaga() {
  yield takeLatest(Auth.LOG_IN_USER, logInUserSaga)
  yield takeLatest(Auth.CONFIRM_EMAIL, confirmEmailSaga)
  yield takeLatest(Auth.SIGN_UP_USER, signUpUserSaga)
  yield takeLatest(Auth.LOG_OUT_USER, logOutUserSaga)
  yield takeLatest(Auth.GET_USER_PROFILE, getUserProfileSaga)
  yield takeLatest(Auth.UPDATE_USER_PROFILE, updateUserProfileSaga)
  yield takeLatest(Auth.DEACTIVATE_USER_PROFILE, deactivateUserProfileSaga)
  yield takeLatest(Auth.RECOVER_PASSWORD, recoverPasswordSaga)
  yield takeLatest(Auth.RESET_PASSWORD, resetPasswordSaga)
  yield takeLatest(Auth.GET_PENDING_LEGAL_NOTICES, getPendingLegalNoticesSaga)
  yield takeLatest(Auth.GET_ALL_LEGAL_NOTICES, getAllLegalNoticesSaga)
  yield takeLatest(Auth.ACCEPT_LEGAL_NOTICES, acceptLegalNoticesSaga)
  yield takeLatest(OnboardingApiCalls.CREATE_MERCHANT, createMerchantSaga)
  yield takeLatest(OnboardingApiCalls.UPDATE_MERCHANT, updateMerchantSaga)
  yield takeEvery(OnboardingApiCalls.CREATE_OFFER, createOfferSaga)
  yield takeEvery(OnboardingApiCalls.DELETE_OFFER, deleteOfferSaga)
  yield takeLatest(OnboardingApiCalls.GET_MERCHANTS, getMerchantsSaga)
  yield takeLatest(OnboardingApiCalls.GET_MERCHANT_LOCATIONS, getMerchantLocationsSaga)
  yield takeLatest(MerchantOnboarding.GET_SHIPPING_ADDRESS, getShippingAddressSaga)
  yield takeLatest(OnboardingApiCalls.CREATE_MERCHANT_LOCATION, createLocationSaga)
  yield takeLatest(OnboardingApiCalls.UPDATE_MERCHANT_LOCATION, updateLocationSaga)
  yield takeLatest(OnboardingApiCalls.GET_MERCHANT_OFFERS, getMerchantOffersSaga)
  yield takeLatest(OnboardingApiCalls.GET_TAG_TYPES, getTagTypesSaga)
  yield takeLatest(OnboardingApiCalls.UPDATE_MERCHANT_OFFER, updateOfferSaga)
  yield takeLatest(OnboardingApiCalls.GET_MERCHANT_DETAILS, getMerchantDetailsSaga)
  yield takeLatest(OnboardingApiCalls.GET_MERCHANT_TRANSACTIONS, getMerchantTransactionsSaga)
  yield takeLatest(OnboardingApiCalls.GET_LOCATION_TRANSACTIONS, getLocationTransactionsSaga)
  yield takeLatest(OnboardingApiCalls.GET_AVG_TICKET_VALUE_REPORT, getAvgTicketValueReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_TOTAL_POINTS_ISSUED_REPORT, getTotalPointsIssuedReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_ALL_TIME_TRANSACTIONS_REPORT, getAllTimeTransactionReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_MONTH_TO_DATE_TRANSACTIONS_REPORT, getMonthToDateTransactionReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_TOTAL_SPENT_REPORT, getTotalSpentReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_MONTH_TO_DATE_SPENT_REPORT, getMonthToDateSpentReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_FIRST_TIME_CUSTOMERS_REPORT, getFirstTimeCustomersReportSaga)
  yield takeLatest(OnboardingApiCalls.GET_MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT, getMonthToDateFirstTimeCustomersReportSaga)
  yield takeLatest(OnboardingApiCalls.CREATE_VIRTUAL_CARD, createVirtualCardSaga)
  yield takeLatest(OnboardingApiCalls.GET_VIRTUAL_CARDS_LIST, getVirtualCardsListSaga)
  yield takeLatest(OnboardingApiCalls.GET_VIRTUAL_CARD_INFO, getVirtualCardSaga)
  yield takeLatest(OnboardingApiCalls.GET_VIRTUAL_CARD_URL, getVirtualCardUrlSaga)
  yield takeLatest(OnboardingApiCalls.GET_LOCATION_AND_GENERATE_VIRTUAL_CARD, getLocationAndCreateVirtualCardSaga)
  yield takeLatest(OnboardingApiCalls.CREATE_CARDS_REQUEST, createCardsRequestSaga)
}

function* logInUserSaga(action) {
  try {
    const payload = yield call(logInUser, action.payload)
    yield put({ type: Auth.USER_LOGGED_IN, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* confirmEmailSaga(action) {
  try {
    yield call(confirmEmail, action.payload)
    history.push('/')
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* signUpUserSaga(action) {
  try {
    const payload = yield call(signUpUser, action.payload)
    yield put({ type: Auth.USER_SIGNED_UP, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* logOutUserSaga(action) {
  try {
    yield call(logOutUser, action.payload)
    yield put({ type: Auth.USER_LOGGED_OUT })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* createMerchantSaga(action) {
  try {
    const payload = yield call(createMerchant, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_CREATED, payload })
    selectMerchant(payload.merchant_id)
    moveToNextPage()
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* updateMerchantSaga(action) {
  try {
    const payload = yield call(updateMerchant, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_UPDATED, payload })
    yield put({ type: OnboardingApiCalls.MERCHANT_DETAILS_RETRIEVED, payload })
    moveToNextPage()
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMerchantsSaga() {
  try {
    const payload = yield call(getMerchants)
    let currentPage = localStorage.getItem('onboardingPage')
    yield put({ type: OnboardingApiCalls.MERCHANTS_RETRIEVED, payload })

    /*
      This functions as a home page redirect
      If there are no merchants created, redirect to onboarding
      If onboarding is not finished, stay on the page
      Else, save new merchant id and redirect to merchant details page
    */
    if (currentPage) {
      history.push('/new-merchant')
    } else {
      if (payload.merchants.length === 0) {
        localStorage.setItem('onboardingPage', 'merchantAccountPage')
        history.push('/new-merchant')
      }

      if (payload.merchants.length === 1) {
        selectMerchant(payload.merchants[0].merchant_id)
        history.push(`/merchant/${payload.merchants[0].merchant_id}`)
      }

      if (payload.merchants.length > 1) {
        if (!localStorage.selectedMerchant) {
          selectMerchant(payload.merchants[0].merchant_id)
          history.push(`/merchant/${payload.merchants[0].merchant_id}`)
        } else {
          history.push(`/merchant/${localStorage.selectedMerchant}`)
        }
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* createOfferSaga(action) {
  try {
    const payload = yield call(createOffer, action.payload)
    yield put({ type: OnboardingApiCalls.OFFER_CREATED, payload })

    // Refresh the offers view after updating offers
    let offer_tags = payload.offer_tags_attributes

    if (offer_tags) {
      for (let offerTag of offer_tags) {
        if (offerTag.tag === 'Merchant') {
          var merchantId = offerTag.entity_id
        }
      }

      yield put({ type: OnboardingApiCalls.GET_MERCHANT_OFFERS, payload: merchantId })
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* deleteOfferSaga(action) {
  let merchantId = action.payload.merchantId
  delete action.payload.merchantId

  try {
    yield call(deleteOffer, action.payload)
    yield put({ type: OnboardingApiCalls.OFFER_DELETED })

    // Refresh the offers view after updating offers
    yield put({ type: OnboardingApiCalls.GET_MERCHANT_OFFERS, payload: merchantId })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* createLocationSaga(action) {
  try {
    const payload = yield call(createLocation, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_LOCATION_CREATED, payload })

    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMerchantLocationsSaga(action) {
  try {
    const payload = yield call(getMerchantLocations, action.payload)
    yield put({ type: OnboardingApiCalls.LOCATIONS_RETRIEVED, payload })
    yield put({ type: MerchantOnboarding.BUILD_LOCATIONS_FILTER, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getShippingAddressSaga(action) {
  try {
    const payload = yield call(getMerchantDetails, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_DETAILS_RETRIEVED, payload })
    let address = payload.shipping_address_attributes
    yield put({ type: MerchantOnboarding.POPULATE_SHIPPING_ADDRESS, payload: address })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* updateLocationSaga(action) {
  let merchantId = action.payload.merchantId
  delete action.payload.merchantId

  try {
    const payload = yield call(updateLocation, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_LOCATION_UPDATED, payload })

    // Refresh locations list
    yield put({ type: OnboardingApiCalls.GET_MERCHANT_LOCATIONS, payload: merchantId })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMerchantOffersSaga(action) {
  try {
    const payload = yield call(getMerchantOffers, action.payload)
    yield put({ type: OnboardingApiCalls.OFFERS_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getTagTypesSaga(action) {
  try {
    const payload = yield call(getTagTypes, action.payload)
    yield put({ type: OnboardingApiCalls.TAG_TYPES_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* updateOfferSaga(action) {
  let merchantId = action.payload.merchantId
  delete action.payload.merchantId

  try {
    const payload = yield call(updateOffer, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_OFFER_UPDATED, payload })

    // Refresh the offers view after updating offers
    yield put({ type: OnboardingApiCalls.GET_MERCHANT_OFFERS, payload: merchantId })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMerchantTransactionsSaga(action) {
  try {
    const payload = yield call(getMerchantTransactions, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_TRANSACTIONS_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getLocationTransactionsSaga(action) {
  if (action.payload === 'all') {
    yield put({ type: OnboardingApiCalls.SHOW_ALL_TRANSACTIONS })
  } else {
    try {
      const payload = yield call(getLocationTransactions, action.payload)
      yield put({ type: OnboardingApiCalls.LOCATION_TRANSACTIONS_RETRIEVED, payload })
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

function* getUserProfileSaga(action) {
  try {
    const payload = yield call(getUserProfile, action.payload)
    yield put({ type: Auth.USER_PROFILE_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* updateUserProfileSaga(action) {
  try {
    const payload = yield call(updateUserProfile, action.payload)
    yield put({ type: Auth.USER_PROFILE_UPDATED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* deactivateUserProfileSaga(action) {
  try {
    yield call(deactivateUserProfile, action.payload)
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* recoverPasswordSaga(action) {
  try {
    const payload = yield call(recoverPassword, action.payload)
    yield put({ type: Auth.PASSWORD_RECOVERED, payload })
    history.push('/')
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* resetPasswordSaga(action) {
  try {
    yield call(resetPassword, action.payload)
    yield put({ type: Auth.PASSWORD_RESET })

    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getPendingLegalNoticesSaga() {
  try {
    const payload = yield call(getPendingLegalNotices)
    yield put({ type: Auth.LEGAL_NOTICES, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getAllLegalNoticesSaga() {
  try {
    const payload = yield call(getAllLegalNotices)
    yield put({ type: Auth.LEGAL_NOTICES, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* acceptLegalNoticesSaga(action) {
  try {
    const payload = yield call(acceptLegalNotices, action.payload)
    yield put({ type: Auth.LEGAL_NOTICES_ACCEPTED, payload })

    // Redirect to home page
    history.push('/')
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMerchantDetailsSaga(action) {
  try {
    const payload = yield call(getMerchantDetails, action.payload)
    yield put({ type: OnboardingApiCalls.MERCHANT_DETAILS_RETRIEVED, payload })

    // Redirect the user to the Edit page if their account_verification_date is missing
    if (!payload.account_verification_date) {
      history.push(`/edit-merchant/${payload.id}`)
    }

    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getAvgTicketValueReportSaga(action) {
  try {
    const payload = yield call(getAvgTicketValue, action.payload)
    yield put({ type: OnboardingApiCalls.AVG_TICKET_VALUE_REPORT_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getAllTimeTransactionReportSaga(action) {
  try {
    const payload = yield call(getAllTimeTransactions, action.payload)
    yield put({ type: OnboardingApiCalls.ALL_TIME_TRANSACTIONS_REPORT_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMonthToDateTransactionReportSaga(action) {
  try {
    const payload = yield call(getAllTimeTransactions, action.payload)
    yield put({ type: OnboardingApiCalls.MONTH_TO_DATE_TRANSACTIONS_REPORT_RETRIEVED, payload })
    // TODO: Add second call for previous month
  } catch (e) {
    // eslint-disable-next-line no-empty
  }
}

function* getTotalPointsIssuedReportSaga(action) {
  try {
    const payload = yield call(getTotalPointsIssued, action.payload)
    yield put({ type: OnboardingApiCalls.TOTAL_POINTS_ISSUED_REPORT_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getTotalSpentReportSaga(action) {
  try {
    const payload = yield call(getTotalSpent, action.payload)
    yield put({ type: OnboardingApiCalls.TOTAL_SPENT_REPORT_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMonthToDateSpentReportSaga(action) {
  try {
    const payload = yield call(getTotalSpent, action.payload)
    yield put({ type: OnboardingApiCalls.MONTH_TO_DATE_SPENT_REPORT_RETRIEVED, payload })
    // TODO: Add second call for previous month
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getFirstTimeCustomersReportSaga(action) {
  try {
    const payload = yield call(getFirstTimeCustomers, action.payload)
    yield put({ type: OnboardingApiCalls.FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getMonthToDateFirstTimeCustomersReportSaga(action) {
  try {
    const payload = yield call(getFirstTimeCustomers, action.payload)
    yield put({ type: OnboardingApiCalls.MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT_RETRIEVED, payload })
    // TODO: Add second call for previous month
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* createVirtualCardSaga(action) {
  try {
    const payload = yield call(generateVirtualCard, action.payload)
    yield put({ type: OnboardingApiCalls.VIRTUAL_CARD_CREATED, payload })
    yield put({ type: OnboardingApiCalls.GET_VIRTUAL_CARDS_LIST })
    yield put({ type: OnboardingApiCalls.GET_VIRTUAL_CARD_URL, payload: payload.id })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getVirtualCardsListSaga() {
  try {
    const payload = yield call(getVirtualCardsList)
    yield put({ type: OnboardingApiCalls.VIRTUAL_CARDS_LIST, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getVirtualCardSaga(action) {
  try {
    const payload = yield call(getVirtualCard, action.payload)
    yield put({ type: OnboardingApiCalls.VIRTUAL_CARD_INFO, payload })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getVirtualCardUrlSaga(action) {
  try {
    const payload = yield call(getVirtualCardUrl, action.payload)
    yield put({ type: OnboardingApiCalls.VIRTUAL_CARD_URL, payload })
    yield put({ type: MerchantOnboarding.OPEN_VIRTUAL_CARD_MODAL })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* getLocationAndCreateVirtualCardSaga(action) {
  try {
    const payload = yield call(getMerchantLocations, action.payload)
    yield put({ type: OnboardingApiCalls.LOCATIONS_RETRIEVED, payload })

    let locationId = payload.locations.at(0).id
    yield put({ type: OnboardingApiCalls.CREATE_VIRTUAL_CARD, payload: { location_id: locationId } })
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* createCardsRequestSaga(action) {
  try {
    const payload = yield call(createCardsRequest, action.payload)
    yield put({ type: OnboardingApiCalls.CREATE_CARDS_REQUEST, payload })
    yield put({ type: OnboardingApiCalls.CARDS_REQUEST_CREATED, payload })
  } catch (e) {
    yield put({ type: OnboardingApiCalls.CARDS_REQUEST_FAILED, payload: action.payload })
  }
}
