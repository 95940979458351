import { BLUE } from '../../Constants/cssColors'
import { getMonthAndYear } from '../../Constants/utilityFunctions'

export default function getAllTimeTransactions(data) {
  let formattedData = [
    {
      id: 'allTimeTransactions',
      color: `${BLUE}`,
      data: [],
    },
  ]

  if (data.transactions_per_date_range != null) {
    Object.entries(data.transactions_per_date_range).forEach(entry => {
      formattedData[0].data.unshift({
        x: getMonthAndYear(entry[0]),
        y: entry[1],
      })
    })
  }

  return formattedData
}
