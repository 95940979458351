import { Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE, HOVER_BLUE } from '../../Constants/cssColors'

export default withStyles({
  root: {
    color: `${BLUE}`,
    '&:hover': {
      color: `${HOVER_BLUE}`,
    },
  },
})(Link)
