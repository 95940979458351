// Repository for API calls hashes

export function newUserHash(form) {
  return {
    name: form.userName,
    email: form.userEmail,
    phone: form.userPhone,
    password: form.userPassword,
    password_confirmation: form.confirmPassword,
  }
}

export function loginUserHash(form) {
  return {
    email: form.userEmail,
    password: form.userPassword,
  }
}

export function recoverPasswordHash(email) {
  return {
    user: {
      email: email,
    },
  }
}

export function resetUserPasswordHash(form) {
  return {
    user: {
      email: form.userEmail,
      password: form.userPassword,
      password_confirmation: form.confirmPassword,
      reset_password_token: form.resetPasswordToken,
    },
  }
}

export function confirmEmailHash(token, email) {
  return {
    confirmation_token: token,
    email: email,
  }
}

export function newMerchantHash(form) {
  let response = {
    business_name: form.merchantName,
    email: form.merchantEmail,
    is_active: false,
    preferred_method_of_communication: '',
    tag_type: form.merchantCategory,
    phone: form.merchantPhone,
    shipping_address_attributes: {
      address_1: form.shippingAddress.streetAddress1,
      address_2: form.shippingAddress.streetAddress2,
      city: form.shippingAddress.city,
      state: form.shippingAddress.state,
      country: form.shippingAddress.country[0],
      zip: form.shippingAddress.zipCode,
    },
  }

  if (form.logoImage) {
    response.logo = form.logoImage
  }

  if (form.bannerImage) {
    response.banner = form.bannerImage
  }

  return response
}

export function updateMerchantHash(form, newMerchant) {
  // Dynamically construct response based on the values
  // the user has entered.
  let response = {}

  if (form.merchantName) {
    response.business_name = form.merchantName
  }

  if (form.merchantEmail) {
    response.email = form.merchantEmail
  }

  if (form.merchantCategory) {
    response.tag_type = form.merchantCategory
  }

  if (form.merchantPhone) {
    response.phone = form.merchantPhone
  }

  if (form.shippingAddress.streetAddress1) {
    response.shipping_address_attributes = {
      id: newMerchant.activeMerchant.shipping_address_attributes?.id,
      address_1: form.shippingAddress.streetAddress1,
      address_2: form.shippingAddress.streetAddress2,
      city: form.shippingAddress.city,
      state: form.shippingAddress.state,
      country: form.shippingAddress.country[0],
      zip: form.shippingAddress.zipCode,
    }
  }

  if (form.billingAddress.streetAddress1) {
    response.billing_address_attributes = {
      id: newMerchant.activeMerchant.billing_address_attributes?.id,
      address_1: form.billingAddress.streetAddress1,
      address_2: form.billingAddress.streetAddress2,
      city: form.billingAddress.city,
      state: form.billingAddress.state,
      country: form.billingAddress.country[0],
      zip: form.billingAddress.zipCode,
    }
  }

  if (form.website) {
    let regex = RegExp(/http(s)?/)

    if (regex.test(form.website)) {
      response.website_link = form.website
    } else {
      response.website_link = `http://${form.website}`
    }
  }

  if (newMerchant.merchantId) {
    response.id = newMerchant.merchantId
  }

  if (newMerchant.logoImage) {
    response.logo = newMerchant.logoImage
  }

  if (newMerchant.bannerImage) {
    response.banner = newMerchant.bannerImage
  }

  if (form.bankDetails.accountNumber) {
    if (!response.ach_bank_detail_attributes) {
      response.ach_bank_detail_attributes = {}
    }

    response.ach_bank_detail_attributes.account_number = form.bankDetails.accountNumber
  }

  if (form.bankDetails.routingNumber) {
    if (!response.ach_bank_detail_attributes) {
      response.ach_bank_detail_attributes = {}
    }

    response.ach_bank_detail_attributes.routing_number = form.bankDetails.routingNumber
  }

  if (form.bankDetails.depositoryName) {
    if (!response.ach_bank_detail_attributes) {
      response.ach_bank_detail_attributes = {}
    }

    response.ach_bank_detail_attributes.depository_name = form.bankDetails.depositoryName
  }

  if (form.bankDetails.taxIdNumber) {
    if (!response.ach_bank_detail_attributes) {
      response.ach_bank_detail_attributes = {}
    }

    response.ach_bank_detail_attributes.tax_identification_number = form.bankDetails.taxIdNumber
  }

  return response
}

export function newOfferHash(offer, merchantId, merchantName) {
  let amount = offer.amount

  let response = {
    summary: '',
    amount: amount,
    offer_type: '',
    offer_tags_attributes: [
      {
        tag: 'Merchant',
        entity_id: parseInt(merchantId),
      },
    ],
  }

  if (offer.type === 'variableReward') {
    response.offer_type = 'N times points'
    response.summary = `Receive ${offer.amount}x points for every dollar you spend at ${merchantName} when you pay with your Buoy Local card or your Bangor Savings Bank debit card`
  } else {
    response.offer_type = 'N number of points'
    response.summary = `Receive ${offer.amount} pts when you spend $${offer.minSpend /
      100} or more at ${merchantName} and pay with your Buoy Local card or your Bangor Savings Bank debit card`

    response.offer_tags_attributes.push({
      tag: 'minimum_spent',
      value: offer.minSpend,
    })

    response.offer_tags_attributes.push({
      tag: 'transaction_count',
      value: 1,
    })
  }

  return response
}

export function updateOfferHash(existingOffer, newOffer, merchantId, merchantName) {
  let response = {
    merchantId: merchantId,
    id: existingOffer.id,
    amount: newOffer.amount,
    offer_type: existingOffer.offer_type,
    offer_tags_attributes: [
      {
        tag: 'Merchant',
        entity_id: parseInt(merchantId),
      },
    ],
  }

  if (newOffer.type === 'variableReward') {
    response.summary = `Receive ${newOffer.amount}x points for every dollar you spend at ${merchantName} when you pay with your Buoy Local card or your Bangor Savings Bank debit card`
  } else {
    response.summary = `Receive ${newOffer.amount} pts when you spend $${newOffer.minSpend /
      100} or more at ${merchantName} and pay with your Buoy Local card or your Bangor Savings Bank debit card`

    response.offer_tags_attributes.push({
      tag: 'minimum_spent',
      value: newOffer.minSpend,
    })

    response.offer_tags_attributes.push({
      tag: 'transaction_count',
      value: 1,
    })
  }

  return response
}

export function newLocationHash(form, merchantId) {
  let response = {
    merchant_id: merchantId,
    google_place_id: form.googlePlaceDetails.googlePlaceId,
    latitude: form.googlePlaceDetails.latitude,
    longitude: form.googlePlaceDetails.longitude,
    phone: form.googlePlaceDetails.phone,
    is_active: false,
    address_attributes: {
      address_1: form.locationAddress.streetAddress1,
      address_2: form.locationAddress.streetAddress2,
      city: form.locationAddress.city,
      state: form.locationAddress.state,
      country: form.locationAddress.country[0],
      zip: form.locationAddress.zipCode,
    },
    name: form.locationAddress.name,
  }

  return response
}

export function updateLocationHash(form, merchantId) {
  let response = {
    merchantId: merchantId,
    id: form.locationDetails.id,
    latitude: form.googlePlaceDetails.latitude,
    longitude: form.googlePlaceDetails.longitude,
    name: form.locationAddress.name,
    phone: form.googlePlaceDetails.phone,
    address_attributes: {
      id: form.locationAddress.address_id,
      address_1: form.locationAddress.streetAddress1,
      address_2: form.locationAddress.streetAddress2,
      city: form.locationAddress.city,
      state: form.locationAddress.state,
      country: form.locationAddress.country[0],
      zip: form.locationAddress.zipCode,
    },
  }

  if (form.locationDetails.website_link) {
    let regex = RegExp(/http(s)?/)

    if (regex.test(form.locationDetails.website_link)) {
      response.website_link = form.locationDetails.website_link
    } else {
      response.website_link = `http://${form.locationDetails.website_link}`
    }
  }

  if (form.locationDetails.facebook_link) {
    let facebookRegExp = RegExp(/(http(s)?:\/\/?)?:?(www\.)?facebook\.com\//)

    let facebookHandle = form.locationDetails.facebook_link.replace(facebookRegExp, '')
    response.facebook_link = `https://www.facebook.com/${facebookHandle}`
  }

  if (form.locationDetails.instagram_link) {
    let instagramRegExp = RegExp(/(http(s)?:\/\/?)?:?(www\.)?instagram\.com\//)

    let instagramHandle = form.locationDetails.instagram_link.replace(instagramRegExp, '')
    response.instagram_link = `https://www.instagram.com/${instagramHandle}`
  }

  if (form.locationDetails.online_ordering_link) {
    let regex = RegExp(/http(s)?/)

    if (regex.test(form.locationDetails.online_ordering_link)) {
      response.online_ordering_link = form.locationDetails.online_ordering_link
    } else {
      response.online_ordering_link = `http://${form.locationDetails.online_ordering_link}`
    }
  }

  if (form.locationDetails.description) {
    response.description = form.locationDetails.description
  }

  if (form.locationDetails.logoImage) {
    response.logo = form.locationDetails.logoImage
  }

  if (form.locationDetails.bannerImage) {
    response.banner = form.locationDetails.bannerImage
  }

  return response
}

export function updateUserProfileHash(form) {
  let response = {
    id: form.userId,
    name: form.userName,
    email: form.userEmail,
  }

  if (form.userPhone) {
    response.phone = form.userPhone
  }

  if (form.userPassword && form.confirmPassword) {
    response.password = form.userPassword
    response.confirmation_password = form.confirmPassword
  }

  return response
}

export function merchantTransactionsHash(merchantId, dateRange, page, rowsPerPage) {
  return {
    merchant_id: merchantId,
    from: dateRange.from,
    to: dateRange.to,
    page_number: page+1,
    page_size: rowsPerPage,
  }
}

export function locationTransactionsHash(locationId, dateRange) {
  return {
    location_id: locationId,
    from: dateRange.from,
    to: dateRange.to,
  }
}

export function generateVirtualCardHash(locationId) {
  return {
    location_id: locationId
  }
}
