import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { AuthActions } from '../Actions/authActions'
import RedButton from '../Components/UI/RedButton'

class DeleteUserDialog extends Component {
  state = {
    open: false,
  }

  handleDialogOpen = () => {
    this.setState({ open: true })
  }

  handleDialogClose = () => {
    this.setState({ open: false })
  }

  handleDeleteButtonPress = () => {
    this.props.deactivateUserProfile(this.props.userForm.userId)
    this.setState({ open: false })
  }

  render() {
    return (
      <>
        <RedButton variant="contained" size="large" onClick={this.handleDialogOpen}>
          Delete
        </RedButton>

        <Dialog
          open={this.state.open}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {'Are you sure you want to delete current user?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting your user will prevent you from logging into your account.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleDeleteButtonPress} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deactivateUserProfile: id => dispatch(AuthActions.deactivateUserProfile(id)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUserDialog)
