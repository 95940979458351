import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

const theme = {
  axis: {
    legend: {
      text: {
        fontSize: '1rem',
      },
    },
  },
}

export const AvgTrxValueBarGraph = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['value']}
    indexBy="month"
    margin={{ top: 15, right: 15, bottom: 75, left: 45 }}
    padding={0.5}
    theme={theme}
    colors={data.map(c => c.color)}
    colorBy="index"
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    labelFormat="$"
    isInteractive={false}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: 'Average transaction value',
      legendPosition: 'middle',
      legendOffset: 65,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    animate={true}
    motionStiffness={250}
    motionDamping={15}
  />
)
