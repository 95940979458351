import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'

import { getStyles, maskLast4 } from '../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import CssTextField from '../Components/UI/CssTextField'

class BankingInfoForm extends Component {
  state = {
    accountNumber: false,
    routingNumber: false,
    depositoryName: false,
    taxIdNumber: false,
    accountNumberFocused: false,
    taxIdNumberFocused: false,
  }

  componentDidMount = () => {
    // Return validateForm() as a callback so it can be
    // accessed by its parent element
    this.props.callValidateForm(this.validateForm.bind(this))
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  validateForm = () => {
    var bankFormErrors = false
    bankFormErrors = this.validateBankingEditForm();

    return bankFormErrors
  }

  handleAccountNumberChange = (value) => {
    this.setState({ accountNumberFocused: value })
  }

  handleTaxIdNumberChange = (value) => {
    this.setState({ taxIdNumberFocused: value })
  }

  // Do not validate against empty fields on the Merchant Edit page
  validateBankingEditForm = () => {
    let form = this.props.onboarding.bankDetails
    let formError = false

    let regex = RegExp(/^\*+/i)
    let accountNumberMasked = regex.test(form.accountNumber)
    let taxIdNumberMasked = regex.test(form.taxIdNumber)

    //  Clear the field if it's showing the masked version of the account number
    if (accountNumberMasked) {
      form.accountNumber = ''
    } else {
      let accountNumberError = isNaN(parseInt(form.accountNumber))

      this.setState({ accountNumber: accountNumberError })

      formError = formError || accountNumberError
    }

    if (!form.depositoryName) {
      this.setState({ depositoryName: true })
     formError = formError || true
    }

    if (taxIdNumberMasked) {
      form.taxIdNumber = ''
    } else {
      let taxIdNumberError = isNaN(parseInt(form.taxIdNumber))

      this.setState({ taxIdNumber: taxIdNumberError })

      formError = formError || taxIdNumberError
    }

    let routingNumberError = form.routingNumber.length !== 9

    this.setState({ routingNumber: routingNumberError })

    formError = formError || routingNumberError

    return formError
  }

  validateBankingCreateForm = () => {
    let form = this.props.onboarding.bankDetails
    let routingNumberError = form.routingNumber === '' || form.routingNumber.length !== 9

    this.setState({
      accountNumber: form.accountNumber === '',
      routingNumber: routingNumberError,
      depositoryName: form.depositoryName === '',
      taxIdNumber: form.taxIdNumber === '',
    })

    let bankFormErrors =
      form.accountNumber === '' || routingNumberError || form.depositoryName === '' || form.taxIdNumber === ''

    return bankFormErrors
  }

  render() {
    const { onboarding, width } = this.props
    let styles = getStyles(width)

    return (
      <>
        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Account Number"
            margin="normal"
            error={this.state.accountNumber}
            variant="outlined"
            type="text"
            style={styles.textFieldHalfWidth}
            value={this.state.accountNumberFocused ? onboarding.bankDetails.accountNumber : maskLast4(onboarding.bankDetails.accountNumber)}
            onInput={(e)=>{ e.target.value = e.target.value.slice(0,20) }}
            onFocus={()=>{ this.handleAccountNumberChange(true) }}
            onBlur={()=>{ this.handleAccountNumberChange(false) }}
            onChange={this.handleChange('addAccountNumber')}
          />

          <div style={{ padding: '0 8px' }} />

          <CssTextField
            label="Routing Number"
            margin="normal"
            error={this.state.routingNumber}
            variant="outlined"
            type="number"
            style={styles.textFieldHalfWidth}
            value={onboarding.bankDetails.routingNumber}
            onInput={(e)=>{ e.target.value = e.target.value.slice(0,9) }}
            onChange={this.handleChange('addRoutingNumber')}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Depository Name"
            margin="normal"
            error={this.state.depositoryName}
            variant="outlined"
            style={styles.textFieldHalfWidth}
            value={onboarding.bankDetails.depositoryName}
            onChange={this.handleChange('addDepositoryName')}
          />

          <CssTextField
            label="Tax Identification Number"
            margin="normal"
            type="text"
            error={this.state.taxIdNumber}
            variant="outlined"
            style={styles.textFieldHalfWidth}
            value={this.state.taxIdNumberFocused ? onboarding.bankDetails.taxIdNumber : maskLast4(onboarding.bankDetails.taxIdNumber)}
            onFocus={()=>{ this.handleTaxIdNumberChange(true) }}
            onBlur={()=>{ this.handleTaxIdNumberChange(false) }}
            onChange={this.handleChange('addTaxIdNumber')}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addAccountNumber: number => dispatch(MerchantOnboardingActions.addAccountNumber(number)),
    addRoutingNumber: number => dispatch(MerchantOnboardingActions.addRoutingNumber(number)),
    addDepositoryName: name => dispatch(MerchantOnboardingActions.addDepositoryName(name)),
    addTaxIdNumber: name => dispatch(MerchantOnboardingActions.addTaxIdNumber(name)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(BankingInfoForm))
