import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import { getStyles } from '../../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { updateMerchantHash } from '../../Constants/apiHashes'
import BillingAddressForm from '../../Components/BillingAddressForm'
import CustomColorCheckbox from '../../Components/UI/CustomColorCheckbox'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import BankingInfoForm from '../../Components/BankingInfoForm'

class BillingDetails extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)

    let merchantId = localStorage.getItem('selectedMerchant')
    this.props.getShippingAddress(merchantId)

    // Clear forms in case the user is coming from the Edit Merchant page
    this.props.billingSameAsShipping(false)
    this.props.addAccountNumber('')
    this.props.addRoutingNumber('')
    this.props.addDepositoryName('')
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handleCheckboxChange = name => event => {
    this.props[name](event.target.checked)
  }

  validateForm = () => {
    // Call validateForm() on the BillingAddressForm amd BankingInfoForm components
    let billingAddressFormErrors = this.validateBillingAddressForm()
    let bankingInfoFormErrors = this.validateBankingInfoForm()

    return billingAddressFormErrors || bankingInfoFormErrors
  }

  handleNextButtonPress = () => {
    let form = this.props.onboarding
    let newMerchant = this.props.newMerchant

    if (!this.validateForm()) {
      this.props.updateMerchant(updateMerchantHash(form, newMerchant))
    }
  }

  render() {
    const { onboarding, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>Billing</h1>
        <div style={styles.subtitle}>
          Enter your banking information for direct payment. Refer to your Program Agreement for
          additional information on payment and program fees.
        </div>

        <div style={styles.detailsContainer}>
          <h4>Enter banking information</h4>

          <BankingInfoForm 
              callValidateForm={bankingInfoValidation =>
                (this.validateBankingInfoForm = bankingInfoValidation)
              }
              pageLocation='AddBillingDetailsPage'
            />

          <div style={{ ...styles.previewContainer, alignItems: 'center' }}>
            <FontAwesomeIcon
              style={{ ...styles.fontAwesomeIcon, padding: '0.6rem' }}
              icon={faLock}
            />
            <div style={styles.infoText}>Your information is encrypted and secure.</div>
          </div>
        </div>

        <div style={styles.detailsContainer}>
          <h4>Billing Address</h4>

          <div style={{ ...styles.previewContainer, alignItems: 'center' }}>
            <CustomColorCheckbox
              checked={onboarding.billingSameAsShipping}
              onChange={this.handleCheckboxChange('billingSameAsShipping')}
            />
            <div style={styles.infoText}>Same as shipping address</div>
          </div>

          <BillingAddressForm
            styles={styles}
            callValidateForm={billingAddressValidation =>
              (this.validateBillingAddressForm = billingAddressValidation)
            }
          />

          <br />

          <div style={{ margin: '1rem 0', display: 'flex' }}>
            <DarkBlueButton variant="contained" size="large" onClick={this.handleNextButtonPress}>
              Next
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearBillingAddressForm: () => dispatch(MerchantOnboardingActions.clearBillingAddressForm()),
    getShippingAddress: id => dispatch(MerchantOnboardingActions.getShippingAddress(id)),
    populateShippingAddress: address =>
      dispatch(MerchantOnboardingActions.populateShippingAddress(address)),
    addAccountNumber: number => dispatch(MerchantOnboardingActions.addAccountNumber(number)),
    addRoutingNumber: number => dispatch(MerchantOnboardingActions.addRoutingNumber(number)),
    addDepositoryName: name => dispatch(MerchantOnboardingActions.addDepositoryName(name)),
    billingSameAsShipping: check =>
      dispatch(MerchantOnboardingActions.billingSameAsShipping(check)),
    updateMerchant: data => dispatch(MerchantApiActions.updateMerchant(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(BillingDetails))
