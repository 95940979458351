import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ButtonBase, MenuItem, withWidth } from '@material-ui/core'
import Dropzone from 'react-dropzone'

import { getStyles, validateEmail, validatePhone } from '../../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { newMerchantHash } from '../../Constants/apiHashes'
import CssTextField from '../../Components/UI/CssTextField'
import CustomColorPhoneInput from '../../Components/UI/CustomColorPhoneInput'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LightBlueButton from '../../Components/UI/LightBlueButton'
import ShippingAddressForm from '../../Components/ShippingAddressForm'
import uploadImage from '../../images/upload-icon.png'
import imgToBase64 from '../../Components/base64'

class CreateMerchant extends Component {
  state = {
    name: false,
    category: false,
    email: false,
    phone: false,
    bannerImage: uploadImage,
    logoImage: uploadImage,
    logoImageError: false,
    bannerImageError: false,
    previewBanner: null,
    previewLogo: null,
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)

    // Clear forms in case the user is coming from the Edit Merchant page
    this.props.clearLocationCreated()
    this.props.clearShippingAddressForm()
    this.props.addMerchantName('')
    this.props.addMerchantCategory('')
    this.props.addMerchantEmail('')
    this.props.addMerchantPhone('')
    this.props.fetchMerchantCategories()
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handlePhoneInput = value => {
    this.props.addMerchantPhone(value)
  }

  handleLogoUploadClick = acceptedFiles => {
    let logoImageSize = acceptedFiles[0].size / 1000

    if (logoImageSize > 400) {
      this.setState({ logoImageError: true })
    } else {
      let logoImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewLogo: logoImage, logoImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addLogoImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  handleBannerUploadClick = acceptedFiles => {
    let bannerImageSize = acceptedFiles[0].size / 1000

    if (bannerImageSize > 800) {
      this.setState({ bannerImageError: true })
    } else {
      let bannerImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewBanner: bannerImage, bannerImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addBannerImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  handleBackButtonPress = () => {
    localStorage.removeItem('onboardingPage')
    this.props.history.push('/')
  }

  validateForm = () => {
    const form = this.props.onboarding

    this.setState({
      name: form.merchantName === '',
      category: form.merchantCategory === '',
      email: validateEmail(form.merchantEmail),
      phone: validatePhone(form.merchantPhone),
    })

    // Call validateForm() on the ShippingAddressForm component
    let shippingAddressFormErrors = this.validateShippingAddressForm()
    let phoneInputErrors = validatePhone(form.merchantPhone)
    let regexValidations = validateEmail(form.merchantEmail)
    let merchantFormErrors =
      form.merchantName === '' ||
      form.merchantCategory === '' ||
      regexValidations ||
      phoneInputErrors

    return shippingAddressFormErrors || merchantFormErrors
  }

  handleNextButtonPress = () => {
    const form = this.props.onboarding

    if (!this.validateForm()) {
      this.props.createMerchant(newMerchantHash(form))
    }
  }

  render() {
    const { onboarding, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>Create Merchant</h1>
        <div style={styles.subtitle}>
          Your merchant profile will be universally applicable to all your locations unless
          specifically updated per location.
        </div>

        <div style={{ ...styles.merchantInputFieldRow, marginTop: '2vw' }}>
          <CssTextField
            label="Business Name"
            margin="normal"
            error={this.state.name}
            variant="outlined"
            style={styles.textFieldFullWidth}
            value={onboarding.merchantName}
            onChange={this.handleChange('addMerchantName')}
          />
        </div>

        <div style={styles.detailsContainer}>
          <h4>Shipping Address</h4>

          <ShippingAddressForm
            styles={styles}
            callValidateForm={shippingAddressValidation =>
              (this.validateShippingAddressForm = shippingAddressValidation)
            }
          />

          <div style={styles.detailsContainer}>
            <div style={styles.merchantInputFieldRow}>
              <CssTextField
                label="Email"
                margin="normal"
                error={this.state.email}
                type="email"
                variant="outlined"
                style={styles.textFieldHalfWidth}
                value={onboarding.merchantEmail}
                onChange={this.handleChange('addMerchantEmail')}
              />

              <div style={{ padding: '0 8px' }} />

              <CustomColorPhoneInput
                label="Phone"
                margin="normal"
                error={this.state.phone}
                defaultCountry="us"
                variant="outlined"
                style={styles.textFieldHalfWidth}
                value={onboarding.merchantPhone}
                onChange={value => this.handlePhoneInput(value)}
              />
            </div>

            <div style={styles.merchantInputFieldRow}>
              <CssTextField
                select
                label="Category"
                margin="normal"
                error={this.state.category}
                variant="outlined"
                style={styles.textFieldHalfWidth}
                value={onboarding.merchantCategory}
                onChange={this.handleChange('addMerchantCategory')}>
                  {onboarding.tagTypes.map(option => (
                      <MenuItem key={option} value={option}>
                          {option}
                      </MenuItem>
                ))}
              </CssTextField>
            </div>
          </div>

          <div style={styles.detailsContainer}>
            <div style={styles.buttonImageRow}>
              <div style={styles.columnContainer}>
                <div style={styles.imageButtonTitle}>List Image</div>
                <div style={styles.imageButtonInfoText}>
                  This is a square image that will appear in offer list. Users often upload a logo.
                </div>

                <Dropzone
                  onDropAccepted={acceptedFiles => this.handleLogoUploadClick(acceptedFiles)}
                  accept={'image/jpeg, image/png'}>
                  {({ getRootProps, getInputProps }) => (
                    <ButtonBase>
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div style={styles.buttonContainer}>
                            {!this.state.previewLogo ? (
                              <img
                                src={this.state.logoImage}
                                alt=""
                                style={styles.uploadImageIcon}
                              />
                            ) : (
                              <img src={this.state.previewLogo} alt="" style={styles.buttonIcon} />
                            )}
                          </div>
                        </div>
                      </section>
                    </ButtonBase>
                  )}
                </Dropzone>

                {this.state.logoImageError ? (
                  <div style={styles.imageUploadError}>Error: the image cannot exceed 400Kb</div>
                ) : null}

                <div style={styles.imageButtonInfoText}>
                  Preferred dimensions 400 x 400. Max size 400 kb. Accepted formats PNG, JPG.
                </div>
              </div>

              <div style={{ margin: '0 0.5vw' }} />

              <div style={styles.columnContainer}>
                <div style={styles.imageButtonTitle}>Banner Image</div>
                <div style={styles.imageButtonInfoText}>
                  This is a large image that will appear above your offer and profile in your
                  location.
                </div>

                <Dropzone
                  onDropAccepted={acceptedFiles => this.handleBannerUploadClick(acceptedFiles)}
                  accept={'image/jpeg, image/png'}>
                  {({ getRootProps, getInputProps }) => (
                    <ButtonBase>
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div style={styles.buttonContainer}>
                            {!this.state.previewBanner ? (
                              <img
                                src={this.state.bannerImage}
                                alt=""
                                style={styles.uploadImageIcon}
                              />
                            ) : (
                              <img
                                src={this.state.previewBanner}
                                alt=""
                                style={styles.buttonIcon}
                              />
                            )}
                          </div>
                        </div>
                      </section>
                    </ButtonBase>
                  )}
                </Dropzone>

                {this.state.bannerImageError ? (
                  <div style={styles.imageUploadError}>Error: the image cannot exceed 800Kb</div>
                ) : null}

                <div style={styles.imageButtonInfoText}>
                  Preferred dimensions 1500 x 400. Max size 800 kb. Accepted formats PNG, JPG.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              margin: '1rem 0',
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-start',
            }}>
            {onboarding.merchantsCreated.length > 0 ? (
              <>
                <LightBlueButton
                  variant="contained"
                  size="large"
                  onClick={this.handleBackButtonPress}>
                  Back
                </LightBlueButton>

                <div style={{ margin: '0 1rem' }} />
              </>
            ) : null}
            <DarkBlueButton variant="contained" size="large" onClick={this.handleNextButtonPress}>
              Next
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearLocationCreated: () => dispatch(MerchantOnboardingActions.clearLocationCreated()),
    clearShippingAddressForm: () => dispatch(MerchantOnboardingActions.clearShippingAddressForm()),
    fetchMerchantCategories: () => dispatch(MerchantApiActions.getTagTypes()),
    addMerchantName: name => dispatch(MerchantOnboardingActions.addMerchantName(name)),
    addMerchantCategory: cat => dispatch(MerchantOnboardingActions.addMerchantCategory(cat)),
    addMerchantEmail: email => dispatch(MerchantOnboardingActions.addMerchantEmail(email)),
    addMerchantPhone: phone => dispatch(MerchantOnboardingActions.addMerchantPhone(phone)),
    addLogoImage: image => dispatch(MerchantOnboardingActions.addLogoImage(image)),
    addBannerImage: image => dispatch(MerchantOnboardingActions.addBannerImage(image)),
    createMerchant: data => dispatch(MerchantApiActions.createMerchant(data)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(CreateMerchant)),
)
