import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Paper, Tooltip, ButtonBase } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import { missingLocationFields } from '../Constants/utilityFunctions'
import infoIcon from '../images/info-icon.png'

class RemainingActions extends Component {
  handleTabChange = tabIndex => {
    window.scrollTo(0, 0)
    this.props.switchAppBarTab(tabIndex)
  }

  handleEditMerchantClick = () => {
    let merchantId = this.props.match.params.id
    this.props.history.push(`/edit-merchant/${merchantId}`)
  }

  render() {
    const { styles, isMobile } = this.props
    const { activeMerchant } = this.props.newMerchant
    const { merchantOffers, locations } = this.props.onboarding
    const remainingActions = actionsCounter(activeMerchant, merchantOffers, locations)

    return (
      <div>
        {remainingActions ? (
          <div style={styles.remainingActionsContainer}>
            <div style={styles.remainingActionsTitle}>Remaining Actions ({remainingActions})</div>

            <div style={styles.remainingActionsSubtitle}>
              Your locations cannot be set active until these actions have been completed.
            </div>

            <Paper style={styles.remainingActionsBox}>
              {shippingAddrMissing(activeMerchant) ? (
                <div style={styles.remainingActionsEntry}>
                  <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
                  <ButtonBase
                    style={styles.remainingAction}
                    onClick={() => this.handleEditMerchantClick()}>
                    Add a shipping address
                  </ButtonBase>
                </div>
              ) : (
                ''
              )}

              {billingAddrMissing(activeMerchant) ? (
                <div style={styles.remainingActionsEntry}>
                  <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
                  <ButtonBase
                    style={styles.remainingAction}
                    onClick={() => this.handleEditMerchantClick()}>
                    Add a billing address
                  </ButtonBase>
                </div>
              ) : (
                ''
              )}

              {locationsMissing(activeMerchant) ? (
                <div style={styles.remainingActionsEntry}>
                  <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
                  <ButtonBase
                    style={styles.remainingAction}
                    onClick={() => this.handleTabChange(3)}>
                    Add a location
                  </ButtonBase>
                </div>
              ) : (
                ''
              )}

              {offersMissing(activeMerchant, merchantOffers) ? (
                <div style={styles.remainingActionsEntry}>
                  <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
                  <ButtonBase
                    style={styles.remainingAction}
                    onClick={() => this.handleTabChange(2)}>
                    Set your offers
                  </ButtonBase>
                </div>
              ) : (
                ''
              )}

              {locationInfoMissing(locations) ? (
                <LocationInfoMissingSwitch isMobile={isMobile} styles={styles} />
              ) : (
                ''
              )}

              {terminalMissing(locations) ? (
                <TerminalMissingSwitch isMobile={isMobile} styles={styles} />
              ) : (
                ''
              )}
            </Paper>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    switchAppBarTab: index => dispatch(MerchantOnboardingActions.switchAppBarTab(index)),
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function shippingAddrMissing(merchant) {
  if (merchant) {
    return !merchant.shipping_address_attributes
  }
}

function billingAddrMissing(merchant) {
  if (merchant) {
    return !merchant.billing_address_attributes
  }
}

function locationsMissing(merchant) {
  if (merchant) {
    return merchant.locations.length === 0
  }
}

function locationInfoMissing(locations) {
  if (locations) {
    return missingLocationFields(locations)
  }
}

function terminalMissing(locations) {
  let terminalsMissing = false

  if (locations.length !== 0) {
    for (let location of locations) {
      if (location.active_terminals_count === 0) {
        terminalsMissing = true
      }
    }
  }

  return terminalsMissing
}

function offersMissing(merchant, offers) {
  if (merchant) {
    return offers.length === 0
  }
}

function actionsCounter(merchant, offers, locations) {
  let counter = 0

  if (shippingAddrMissing(merchant)) {
    counter += 1
  }

  if (billingAddrMissing(merchant)) {
    counter += 1
  }

  if (locationsMissing(merchant)) {
    counter += 1
  }

  if (terminalMissing(locations)) {
    counter += 1
  }

  if (locationInfoMissing(locations)) {
    counter += 1
  }

  if (offersMissing(merchant, offers)) {
    counter += 1
  }

  if (counter > 0) {
    return counter
  } else {
    return ''
  }
}

function TerminalMissingSwitch({ isMobile, styles }) {
  if (isMobile) {
    return <TerminalMissingMobile styles={styles} />
  } else {
    return <TerminalMissingDesktop styles={styles} />
  }
}

function TerminalMissingDesktop({ styles }) {
  return (
    <div style={styles.remainingActionsEntry}>
      <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
      <div style={{ ...styles.remainingAction, color: 'black' }}>Verify your terminals</div>
      <Tooltip
        title="You must verify at least 1 terminal at all of your locations to activate.
        Follow the instructions on the Location Edit page. Contact support or FAQs for more.">
        <img alt="" src={infoIcon} style={styles.remainingActionInfoIcon} />
      </Tooltip>
    </div>
  )
}

function TerminalMissingMobile({ styles }) {
  return (
    <div style={styles.columnContainer}>
      <div style={styles.remainingActionsEntry}>
        <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
        <div style={{ ...styles.remainingAction, color: 'black' }}>Verify your terminals</div>
      </div>

      <div style={styles.remainingActionsMobileTooltip}>
        You must verify at least 1 terminal at all of your locations to activate. A terminal
        verification card will be sent to each location. Contact support or FAQs for more.
      </div>
    </div>
  )
}

function LocationInfoMissingSwitch({ isMobile, styles }) {
  if (isMobile) {
    return <LocationInfoMissingMobile styles={styles} />
  } else {
    return <LocationInfoMissingDesktop styles={styles} />
  }
}

function LocationInfoMissingDesktop({ styles }) {
  return (
    <div style={styles.remainingActionsEntry}>
      <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
      <div style={{ ...styles.remainingAction, color: 'black' }}>Complete Your Profile</div>
      <Tooltip title="Use the locations tab to add images, social media links and a description of your business.">
        <img alt="" src={infoIcon} style={styles.remainingActionInfoIcon} />
      </Tooltip>
    </div>
  )
}

function LocationInfoMissingMobile({ styles }) {
  return (
    <div style={styles.columnContainer}>
      <div style={styles.remainingActionsEntry}>
        <FontAwesomeIcon style={styles.remainingActionsCheckIcon} icon={faCheckCircle} />
        <div style={{ ...styles.remainingAction, color: 'black' }}>Complete Your Profile</div>
      </div>

      <div style={styles.remainingActionsMobileTooltip}>
        Use the locations tab to add images, social media links and a description of your business.
      </div>
    </div>
  )
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RemainingActions),
)
