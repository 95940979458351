import { BLUE, LIGHT_BLUE } from '../../Constants/cssColors'
import { getMonthAndYear } from '../../Constants/utilityFunctions'

export default function getAvgTicketValue(data) {
  let formattedData = []

  if (data.average_per_date_range != null) {
    Object.entries(data.average_per_date_range).forEach((entry, index) => {
      formattedData.unshift({
        month: getMonthAndYear(entry[0]),
        value: parseFloat((entry[1] / 100).toFixed(2)),
        color: index % 2 === 0 ? `${BLUE}` : `${LIGHT_BLUE}`,
      })
    })
  }

  return formattedData
}
