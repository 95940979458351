import { Notifications } from '../Constants/action-types'
import { initialState } from '../Store/initialState'

export default function notificationsReducers(state = initialState.notifications, action) {
  if (action.type === Notifications.ERROR_SNACKBAR) {
    return {
      ...state,
      openSnackbar: true,
      message: action.payload,
      variant: 'error',
    }
  }

  if (action.type === Notifications.SUCCESS_SNACKBAR) {
    return {
      ...state,
      openSnackbar: true,
      message: action.payload,
      variant: 'success',
    }
  }

  if (action.type === Notifications.INFO_SNACKBAR) {
    return {
      ...state,
      openSnackbar: true,
      message: action.payload,
      variant: 'info',
    }
  }

  if (action.type === Notifications.WARNING_SNACKBAR) {
    return {
      ...state,
      openSnackbar: true,
      message: action.payload,
      variant: 'warning',
    }
  }

  if (action.type === Notifications.REMOVE_SNACKBAR) {
    return {
      ...state,
      openSnackbar: false,
      message: '',
      variant: '',
    }
  }

  return state
}