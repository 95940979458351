import React, { Component } from 'react'

import CustomColorLink from './CustomColorLink'
import styles from '../../Styles/stylesDesktop'

export default class LogoutLink extends Component {
  handleSignOutClick = () => {
    this.props.auth.logout()
  }

  render() {
    return (
      <div>
        <CustomColorLink 
          component='button'
          variant='body2'
          style={styles.logoutLink}
          onClick={() => this.handleSignOutClick()}
        >
          Logout
        </CustomColorLink>
      </div>
    )
  }
}
