import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withWidth,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
} from '@material-ui/core'

import CssTextField from '../Components/UI/CssTextField'

const transactionTypes = [
  {
    id: 'all',
    value: 'All transactions',
  },
  {
    id: 'refund',
    value: 'Refunds',
  },
  {
    id: 'void',
    value: 'Voids',
  },
  {
    id: 'capture',
    value: 'Sales',
  },
]

class TransactionsTable extends Component {
  state = {
    rowsPerPage: 10,
    page: 0,
    transactionTypeFilter: transactionTypes[0].value,
    transactionTypeFilterId: transactionTypes[0].id,
  }

  componentDidUpdate(_prevProps, prevState) {
    const { page, rowsPerPage } = this.state

    if (page != prevState.page) {
        this.props.loadTransactions(page, rowsPerPage)
    }
  }

  handleFilterChange = event => {
    for (let transactionType of transactionTypes) {
      if (transactionType.value === event.target.value) {
        this.setState({
          transactionTypeFilter: transactionType.value,
          transactionTypeFilterId: transactionType.id,
        })
      }
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  }

  // Request from Bangor: move the date in the transaction row to first place
  rearrangeTransactionKeys = transaction => {
    let newTransactionObject = {}

    newTransactionObject.created_at = transaction.created_at
    newTransactionObject.id = transaction.id
    newTransactionObject.amount_approved = transaction.amount_approved
    newTransactionObject.location_id = transaction.location_id
    newTransactionObject.trx_type = transaction.trx_type

    return newTransactionObject
  }

  removeTransactionAttributes = transactions => {
    const allowedTransactionData = [
      'id',
      'amount_approved',
      'created_at',
      'location_id',
      'trx_type',
    ]

    // Remove unwanted transaction data (terminal_id, acquirer_id, updated_at)
    let transactionsArray = transactions.map(transaction => {
      const filteredTransactions = Object.keys(transaction)
        .filter(key => allowedTransactionData.includes(key))
        .reduce((obj, key) => {
          obj[key] = transaction[key]
          return obj
        }, {})

      return filteredTransactions
    })

    return transactionsArray
  }

  // Filter transactions by transaction type
  applyTransactionTypeFilter = transactions => {
    let filteredTransactions = []

    if (this.state.transactionTypeFilterId === 'all') {
      filteredTransactions = transactions.filter(transaction => {
        return transaction.trx_type !== 'authorization'
      })
    } else {
      filteredTransactions = transactions.filter(transaction => {
        return transaction.trx_type === this.state.transactionTypeFilterId
      })
    }

    return this.removeTransactionAttributes(filteredTransactions)
  }

  render() {
    const { styles, onboarding } = this.props
    const { locationsFilter, filteredTransactions, totalCount } = onboarding
    const { rowsPerPage, page, transactionTypeFilter } = this.state

    // Apply transaction type filter and remove unwanted attributes
    var transactionData = this.applyTransactionTypeFilter(filteredTransactions)

    return (
      <div style={{ ...styles.columnContainer, margin: '1rem 0' }}>
        <h2>Transactions</h2>

        <CssTextField
          select
          label="Transaction Type"
          margin="normal"
          variant="outlined"
          style={styles.textFieldHalfWidth}
          value={transactionTypeFilter}
          onChange={this.handleFilterChange}>
          {transactionTypes.map(option => (
            <MenuItem key={option.id} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </CssTextField>

        <Paper style={{ width: '100%' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {transactionData.length > 0 ? (
                    Object.keys(this.rearrangeTransactionKeys(transactionData[0])).map(title => (
                      <TableCell key={title} align="right">
                        {TableHeader(title)}
                      </TableCell>
                    ))
                  ) : (
                    <TableCell key={0} align="center">
                      No transactions found
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionData.map(transaction => {
                    transaction = this.rearrangeTransactionKeys(transaction)

                    return (
                      <TransactionRow
                        transaction={transaction}
                        key={transaction.id}
                        locationsFilter={locationsFilter}
                      />
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

export default connect(
  mapStateToProps,
  null,
)(withWidth()(TransactionsTable))

function TableHeader(header) {
  switch (header) {
    case 'created_at':
      return 'Date'
    case 'amount_approved':
      return 'Amount'
    case 'location_id':
      return 'Location'
    case 'trx_type':
      return 'Type'
    default:
      break
  }
}

function TransactionRow({ transaction, locationsFilter }) {
  const getLocationName = id => {
    let locationName = ''

    for (let location of locationsFilter) {
      if (location.id === id) {
        locationName = location.value
      }
    }

    return locationName
  }

  return (
    <TableRow key={transaction.id}>
      {Object.entries(transaction).map((entry, index) => {
        // Format the date and do not display the id
        if (entry[0] === 'created_at') {
          entry[1] = new Date(entry[1]).toLocaleString()
        }

        if (entry[0] === 'location_id') {
          entry[1] = getLocationName(entry[1])
        }

        if (entry[0] === 'amount' || entry[0] === 'amount_approved') {
          entry[1] = `$${(entry[1] / 100).toLocaleString('en-US', {minimumFractionDigits: 2})}`
        }

        // Rename captures to sales
        if (entry[0] === 'trx_type' && entry[1] === 'capture') {
          entry[1] = 'sale'
        }

        return (
          <TableCell key={`${transaction.id}_${entry[1]}_${index}`} align="right">
            {entry && entry[0] === 'id' ? null : entry[1]}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
