import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Paper, withWidth } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons'
import { faUser, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

import { getStyles } from '../../Constants/utilityFunctions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import MerchantsList from './MerchantsList'
import HomePageTabs from './HomePageTabs'
import MerchantStatusChip from './MerchantStatusChip'
import defaultMerchantIcon from '../../images/default-merchant-icon.png'
import bsb from '../../images/buoy-local-logo-empowering.png'
import { BLUE } from '../../Constants/cssColors'

class ApplicationAppBar extends Component {
  state = {
    profileAnchorEl: null,
    merchantAnchorEl: null,
    infoAnchorEl: null,
    mobileAnchorEl: null,
  }
  
  profileMenuId='profile-menu'
  merchantMenuId='merchant-menu'
  infoMenuId='info-menu'
  mobileMenuId='mobile-menu'

  componentDidMount = () => {
    this.props.getMerchantDetails(this.props.match.params.id) 
  }

  handleProfileMenuIconClick = (event) => {
    this.setState({ profileAnchorEl: event.currentTarget })
  }

  handleMerchantMenuIconClick = (event) => {
    this.setState({ merchantAnchorEl: event.currentTarget })
  }

  handleInfoMenuClick = (event) => {
    this.setState({ infoAnchorEl: event.currentTarget })
  }

  handleMobileMenuClick = (event) => {
    this.setState({ mobileAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileAnchorEl: null })
  }

  handleProfileMenuClose = () => {
    this.setState({ profileAnchorEl: null })
  }

  handleInfoMenuClose = () => {
    this.setState({ infoAnchorEl: null })
  }

  handleMerchantMenuClose = () => {
    this.setState({ merchantAnchorEl: null })
  }

  handleUserProfileClick = () => {
    this.props.history.push('/user-profile')
  }

  handleSignOutClick = () => {
    this.props.auth.logout()
  }

  handleSupportClick = () => {
    window.open('https://buoylocal.com/contact', '_blank')
  }

  handleFAQClick = () => {
    window.open('https://buoylocal.com/how-it-works', '_blank')
  }

  handleAgreementsClick = () => {
    this.props.history.push('/legal-agreements')
  }

  handleEditMerchantClick = () => {
    let merchantId = this.props.match.params.id
    this.props.history.push(`/edit-merchant/${merchantId}`)
  }

  handleNewhMerchantClick = () => {
    localStorage.setItem('onboardingPage', 'merchantAccountPage')
    this.props.history.push('/new-merchant')
  }

  render() {
    const { profileAnchorEl, merchantAnchorEl, infoAnchorEl, mobileAnchorEl } = this.state
    const { activeMerchant } = this.props.newMerchant
    const { merchantsCreated } = this.props.onboarding
    
    const { width } = this.props
    const mobileWidths = ['xs', 'sm']
    let styles = getStyles(width)
    const isMobile = mobileWidths.includes(width)

    function getMerchantLogo() {
      if(activeMerchant) {
        let logo = activeMerchant.logo

        if(logo) {
          return logo
        } else {
          return defaultMerchantIcon
        }
      }
    }

    return (
      <div style={styles.pageContainer}>
        <AppBar style={styles.profileAppBar} >
          <Toolbar style={styles.profileToolBar}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton
                edge='start'
                aria-controls={this.merchantMenuId}
                aria-haspopup='true'
                onClick={this.handleMerchantMenuIconClick}
              > 
                <Paper style={{ padding: '0.5vw', display: 'flex' }}>
                  <img src={getMerchantLogo()} alt='new merchant' style={styles.appBarMerchantLogo}/>                
                </Paper>
                <FontAwesomeIcon style={{ color: 'black', width: '1vw', marginLeft: '0.5vw' }} icon={faCaretDown}/>
              </IconButton>
              { activeMerchant && !isMobile ? (
                  <div style={{ color: 'black' }}>{activeMerchant.business_name}</div>
                ) : ''
              }
            </div>

            <img src={bsb} alt='' style={styles.appBarBuoyLogo}/>

            <div>
              { isMobile ? null : <MerchantStatusChip />}

              { isMobile ? (
                <IconButton
                  edge='end'
                  aria-controls={this.mobileMenuId}
                  aria-haspopup='true'
                  onClick={this.handleMobileMenuClick}
                >
                  <FontAwesomeIcon style={styles.mobileMenuIcon} icon={faBars}/>
                </IconButton>
              ) : (
                <>
                  <IconButton
                    edge='end'
                    aria-controls={this.infoMenuId}
                    aria-haspopup='true'
                    onClick={this.handleInfoMenuClick}
                  >
                    <FontAwesomeIcon style={{ color: `${BLUE}` }} icon={faQuestionCircle}/>
                  </IconButton>

                  <IconButton
                    edge='end'
                    aria-controls={this.profileMenuId}
                    aria-haspopup='true'
                    onClick={this.handleProfileMenuIconClick}
                  >
                    <FontAwesomeIcon style={{ color: `${BLUE}` }} icon={faUser}/>
                    <FontAwesomeIcon style={{ color: `${BLUE}`, width: '1vw', marginLeft: '0.5vw' }} icon={faCaretDown}/>
                  </IconButton>
                </>
              )}
            </div>
          </Toolbar>

          <div style={styles.homePageTabs}>
            <HomePageTabs styles={styles} match={this.props.match} history={this.props.history} />
          </div>
        </AppBar>

        <Menu
          id={this.infoMenuId}
          anchorEl={infoAnchorEl}  
          keepMounted
          open={Boolean(infoAnchorEl)}
          onClose={this.handleInfoMenuClose}
        >
          <MenuItem onClick={this.handleSupportClick}>
            Support
          </MenuItem>
          <MenuItem onClick={this.handleFAQClick}>
            FAQs
          </MenuItem>
          <MenuItem onClick={this.handleAgreementsClick}>
            Agreements
          </MenuItem>
        </Menu>

        <Menu
          id={this.profileMenuId}
          anchorEl={profileAnchorEl}  
          keepMounted
          open={Boolean(profileAnchorEl)}
          onClose={this.handleProfileMenuClose}
        >
          <MenuItem onClick={this.handleUserProfileClick}>
            Edit User
          </MenuItem>
          <MenuItem onClick={this.handleSignOutClick}>
            Logout
          </MenuItem>
        </Menu>

        <Menu
          id={this.merchantMenuId}
          anchorEl={merchantAnchorEl}  
          keepMounted
          open={Boolean(merchantAnchorEl)}
          onClose={this.handleMerchantMenuClose}
        >
          <MenuItem onClick={this.handleEditMerchantClick}>
            Edit Merchant
          </MenuItem>

          <MenuItem onClick={this.handleNewhMerchantClick}>
            Create a new merchant
          </MenuItem>
          
          { merchantsCreated.length > 1 ? (
            <MerchantsList merchants={merchantsCreated} />
          ) : '' }
        </Menu>

        <Menu
          id={this.profileMenuId}
          anchorEl={mobileAnchorEl}  
          keepMounted
          open={Boolean(mobileAnchorEl)}
          onClose={this.handleMobileMenuClose}
        >
          <MenuItem onClick={this.handleSupportClick}>
            Support
          </MenuItem>
          <MenuItem onClick={this.handleFAQClick}>
            FAQs
          </MenuItem>
          <MenuItem onClick={this.handleAgreementsClick}>
            Agreements
          </MenuItem>
          <MenuItem onClick={this.handleUserProfileClick}>
            Edit User
          </MenuItem>
          <MenuItem onClick={this.handleSignOutClick}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMerchantDetails: (id) => dispatch(MerchantApiActions.getMerchantDetails(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWidth()(ApplicationAppBar)))
