import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../Reducers/index'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleWare from 'redux-saga'
import apiSaga from '../Sagas/api-saga'

const initializeSagaMiddleware = createSagaMiddleWare()

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(initializeSagaMiddleware)),
)

initializeSagaMiddleware.run(apiSaga)

export default store
