import React, { Component } from 'react'
import { Paper } from '@material-ui/core'

export default class MyRportingRow extends Component {
  render() {
    const { styles, merchantData } = this.props
    const { allTimeTransactionsReport, totalSpentReport, firstTimeCustomers } = merchantData

    return (
      <div style={{ ...styles.reportingSection, justifyContent: 'space-between' }}>
        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>Total Transactions</div>
            <div style={styles.reportingCardValue}>
              {allTimeTransactionsReport.all_time_transactions
                ? allTimeTransactionsReport.all_time_transactions.toLocaleString()
                : 0}
            </div>
          </div>
        </Paper>

        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>Total Sales</div>
            <div style={styles.reportingCardValue}>
              {totalSpentReport.total_spent_value
                ? `$${(totalSpentReport.total_spent_value / 100).toLocaleString('en-US', {minimumFractionDigits: 2})
              }`
                : 0}
            </div>
          </div>
        </Paper>

        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>New Customers</div>
            <div style={styles.reportingCardValue}>
              {firstTimeCustomers.first_time_customers 
                ? firstTimeCustomers.first_time_customers.toLocaleString()
                : 0}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}
