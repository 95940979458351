import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { getStyles, validateEmail } from '../Constants/utilityFunctions'
import CssTextField from '../Components/UI/CssTextField'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import { AuthActions } from '../Actions/authActions'
import { loginUserHash } from '../Constants/apiHashes'
import bsb from '../images/buoy-local-logo-empowering.png'

class LoginForm extends Component {
  state = {
    email: false,
    password: false,
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  validateForm = () => {
    const form = this.props.authForm

    this.setState({
      email: validateEmail(form.userEmail),
      password: form.userPassword === '',
    })

    return validateEmail(form.userEmail) || form.userPassword === ''
  }

  catchReturn(event) {
    if (event.key === 'Enter') {
      this.handleLoginClick()
    }
  }

  handleLoginClick() {
    const form = this.props.authForm

    if (!this.validateForm()) {
      this.props.auth.login(loginUserHash(form))
    }
  }

  handleSignUpClick() {
    this.props.history.push('/sign-up')
  }

  handleForgotPasswordClick() {
    this.props.history.push('/password-recovery')
  }

  render() {
    const { authForm, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.loginPageContainer}>
        <div style={styles.pageContainer}>
          <div style={styles.loginContainer}>
            <img src={bsb} alt="" style={styles.buoyLogo} />
            <div style={styles.loginSignupTitle}>Login</div>

            <CssTextField
              error={this.state.email}
              margin="normal"
              variant="outlined"
              type="email"
              label="Email"
              style={styles.textFieldMediumWidth}
              value={authForm.userEmail}
              onChange={this.handleChange('addUserEmail')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <CssTextField
              error={this.state.password}
              margin="normal"
              variant="outlined"
              label="Password"
              type="password"
              style={styles.textFieldMediumWidth}
              value={authForm.userPassword}
              onChange={this.handleChange('addUserPassword')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <div style={styles.forgotPassword} onClick={() => this.handleForgotPasswordClick()}>
              Forgot password?
            </div>

            <DarkBlueButton
              size="large"
              variant="contained"
              style={styles.loginButton}
              onClick={() => this.handleLoginClick()}>
              Login
            </DarkBlueButton>

            <div style={styles.signUpOrLogIn} onClick={() => this.handleSignUpClick()}>
              or Sign Up
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUserEmail: email => dispatch(AuthActions.addUserEmail(email)),
    addUserPassword: password => dispatch(AuthActions.addUserPassword(password)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(LoginForm)),
)
