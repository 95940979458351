export const initialState = {
  authForm: {
    userId: '',
    userName: '',
    userEmail: '',
    userPhone: '',
    userPassword: '',
    confirmPassword: '',
    resetPasswordToken: '',
    notices: [],
  },
  onboarding: {
    merchantName: '',
    merchantCategory: '',
    merchantEmail: '',
    merchantPhone: '',
    bankDetails: {
      accountNumber: '',
      routingNumber: '',
      depositoryName: '',
      taxIdNumber: '',
    },
    billingAddress: {
      streetAddress1: '',
      streetAddress2: '',
      state: 'ME',
      country: 'United States',
      city: '',
      zipCode: '',
    },
    shippingAddress: {
      streetAddress1: '',
      streetAddress2: '',
      state: 'ME',
      country: 'United States',
      city: '',
      zipCode: '',
    },
    locationAddress: {
      address_id: '',
      name: '',
      streetAddress1: '',
      streetAddress2: '',
      state: 'ME',
      country: 'United States',
      city: '',
      zipCode: '',
    },
    locationDetails: {
      id: '',
      description: '',
      facebook_link: '',
      instagram_link: '',
      online_ordering_link: '',
      website_link: '',
      logoImage: '',
      bannerImage: '',
      locationCreated: false,
    },
    googlePlaceDetails: {
      googlePlaceId: '',
      phone: '',
      latitude: '',
      longitude: '',
    },
    billingSameAsShipping: false,
    offers: {
      regular: {
        type: 'variableReward',
        amount: '',
        existingOfferId: '',
      },
      acquisition: {
        type: 'fixedReward',
        amount: '',
        minSpend: '',
        existingOfferId: '',
      },
    },
    locations: [],
    transactions: [],
    placeResults: [],
    merchantOffers: [],
    tagTypes: [],
    merchantsCreated: [],
    appBarTab: 0,
    locationsFilter: [{ id: 'all', value: 'All locations' }],
    selectedDateRange: 'Last 12 months',
    selectedLocation: 'All locations',
    filteredTransactions: [],
    totalCount: 0,
  },
  newMerchant: {
    merchantId: '',
    logoImage: '',
    bannerImage: '',
    activeMerchant: '',
    avgTicketValueReport: {},
    allTimeTransactionsReport: {},
    totalPointsIssuedReport: {},
    totalSpentReport: {},
    firstTimeCustomers: {},
    monthToDateTransactionsReport: {},
    monthToDateSpentReport: {},
    monthToDateFirstTimeCustomers: {},
    virtualCardsList: [],
    virtualCard: {},
    virtualCardUrl: '',
    virtualCardModal: false,
  },
  notifications: {
    openSnackbar: false,
    message: '',
    variant: '',
  },
}
