import AuthModule from '../AuthModule'
import store from '../Store'
import { NotificationsActions } from '../Actions/notificationsActions'

import handleErrors from './handleErrors'

const authModule = new AuthModule(store)

const noTokenHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Expose-Headers': 'Authorization',
}

const headerWithToken = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: window.localStorage.bearerToken,
}

export function logInUser(payload) {
  let data = { user: payload }

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/sign_in`, {
    method: 'POST',
    mode: 'cors',
    headers: noTokenHeader,
    body: JSON.stringify(data),
  })
    .then(response => {
      authModule.handleAuthentication(response)

      if (!response.ok) {
        handleErrors(response)
      }

      return response.json()
    })
    .then(json => {
      if (json.error) {
        store.dispatch(NotificationsActions.displayErrorSnackbar(json.error))
      } else if (json.user.role !== 'agent') {
        store.dispatch(NotificationsActions.displayErrorSnackbar('Unauthorized user'))
      } else {
        window.localStorage.setItem('userEmail', json.user.email)
        authModule.saveTokenLifespan(json)

        return json
      }
    })
}

export function signUpUser(payload) {
  let data = { user: payload }

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users`, {
    method: 'POST',
    mode: 'cors',
    headers: noTokenHeader,
    body: JSON.stringify(data),
  })
    .then(response => {
      authModule.handleAuthentication(response)
      return response.json()
    })
    .then(json => {
      if (json.errors) {
        store.dispatch(NotificationsActions.displayErrorSnackbar(json.errors[0].title))
      } else {
        window.localStorage.setItem('userEmail', json.user.email)
        authModule.saveTokenLifespan(json)

        return json
      }
    })
}

export function logOutUser() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/sign_out`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headerWithToken,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    authModule.userLoggedOut()
  })
}

export function updateUserProfile(payload) {
  let userId = payload.id
  delete payload.id

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/${userId}/change_profile`, {
    method: 'PUT',
    mode: 'cors',
    headers: headerWithToken,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function deactivateUserProfile(payload) {
  let userId = payload

  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/${userId}/deactivate`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headerWithToken,
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    authModule.userLoggedOut()
  })
}

export function getUserProfile() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/by_signature`, {
    method: 'GET',
    mode: 'cors',
    headers: headerWithToken,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function recoverPassword(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/password`, {
    method: 'POST',
    mode: 'cors',
    headers: noTokenHeader,
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.status === 201) {
      store.dispatch(NotificationsActions.displaySuccessSnackbar('Email sent'))
    }

    return response.json()
  })
  .then(json => {
    if (Object.keys(json).length > 0) {
      if (json.status != 200) {
        store.dispatch(NotificationsActions.displayErrorSnackbar(json.message))
      }

      return json 
    }
  })
}

export function resetPassword(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/users/password`, {
    method: 'PUT',
    mode: 'cors',
    headers: noTokenHeader,
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.status == 204) {
      logInUser(payload.user)
      return
    }

    response.json()
    .then(json => {
      if (json.errors) {
        for (let field in json.errors) {
          json.errors[field].forEach(message => {
            store.dispatch(NotificationsActions.displayErrorSnackbar(message))
          })
        }
      }
    })
  })
}

export function confirmEmail(payload) {
  let params = payload
  let url = new URL(`${process.env.REACT_APP_BACKEND_URI}/users/confirmation/`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: noTokenHeader,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getPendingLegalNotices() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terms_and_conditions/pending`, {
    method: 'GET',
    mode: 'cors',
    headers: headerWithToken,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function getAllLegalNotices() {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terms_and_conditions`, {
    method: 'GET',
    mode: 'cors',
    headers: headerWithToken,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}

export function acceptLegalNotices(payload) {
  return fetch(`${process.env.REACT_APP_BACKEND_URI}/terms_and_conditions/${payload}/accept`, {
    method: 'POST',
    mode: 'cors',
    headers: headerWithToken,
  }).then(response => {
    if (!response.ok) {
      handleErrors(response)
    }

    return response.json()
  })
}
