import React, { Component } from 'react'
import { withWidth } from '@material-ui/core'

import { moveToNextPage, getStyles } from '../../Constants/utilityFunctions'
import bsb from '../../images/buoy-local-logo-empowering.png'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'

class AwaitingReview extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleNextButtonPress = () => {
    moveToNextPage()
  }

  render() {
    const { width } = this.props
    let styles = getStyles(width)

    return (
      <div style={{ ...styles.pageContainer, alignItems: 'flex-start' }}>
        <img src={bsb} alt="" style={styles.buoyLogoAwaitingPage} />

        <h1 style={styles.title}>All Done. Awaiting Review.</h1>

        <div style={styles.subtitle}>
          <p>Congratulations on setting up your Buoy Business Profile!</p>

          <p>
            We are currently reviewing your profile and will contact you on next steps within 3
            business days. Your business will not be added to the Buoy Local mobile app until
            approved, but you can add additional profile information and see a preview of how your
            business will be displayed by visiting the “Locations” tab on the next screen.
          </p>
        </div>

        <div style={styles.detailsContainer}>
          <div style={{ margin: '1rem 0', display: 'flex' }}>
            <DarkBlueButton variant="contained" size="large" onClick={this.handleNextButtonPress}>
              Go to Dashboard
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

export default withWidth()(AwaitingReview)
