import React, { Component } from 'react'

import ReactGoogleMapImage from 'react-google-map-image'

class VerifyTerminalsHeader extends Component {
  render () {
    const { isMobile, locationName, styles, locationAddress } = this.props

    const googleImageWidth = isMobile
      ? Math.round(window.innerWidth / 1.2)
      : Math.round(window.innerWidth / 5)
    const googleImageHeight = isMobile
      ? Math.round(window.innerHeight / 8)
      : Math.round(window.innerHeight / 5)

    const googleMapApiConfig = {
      center: locationAddress,
      size: `${googleImageWidth}x${googleImageHeight}`,
      zoom: '11',
      key: process.env.REACT_APP_PLACES_KEY,
      maptype: 'roadmap',
    }
    
    return (
      <div style={styles.verifyTerminalHeaderContainer}>
        <div>
          <div style={styles.locationName}>{locationName}</div>
          <div style={styles.locationCardAddress}>{locationAddress}</div>
        </div>

        <div style={{...styles.locationImage, marginLeft: '1rem' }}>
          <ReactGoogleMapImage
            config={googleMapApiConfig}
            wrapperStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </div>
      </div>
    )
  }
}

export default VerifyTerminalsHeader
