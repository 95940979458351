import MuiPhoneInput from 'material-ui-phone-number'
import { styled } from '@material-ui/styles'

import { GREY, LIGHT_GREY, OUTLINE_GREY } from '../../Constants/cssColors'

const CustomColorPhoneInput = styled(MuiPhoneInput)({
  '& label, label.Mui-focused': {
    color: `${GREY}`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${OUTLINE_GREY}`,
    },
    '&:hover fieldset': {
      borderColor: `${LIGHT_GREY}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${GREY}`,
    },
  },
})

export default CustomColorPhoneInput
