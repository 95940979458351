import React, { Component } from 'react'
import { Card, CardContent } from '@material-ui/core'

import acquisitionOfferImage from '../images/acquisition-offer.png'
import regularOfferImage from '../images/loyalty-offer.png'
import { BLUE } from '../Constants/cssColors'

export default class OfferCard extends Component {
  render() {
    const { offersProps, offerType, styles } = this.props

    return (
      <Card style={styles.offerCard}>
        <CardContent style={styles.offerCardContent}>
          {offerType === 'regular' ? (
            <>
              <img src={regularOfferImage} alt="" style={styles.offerCardLogo} />
              <div style={styles.offerCardTitle}>Loyalty Offer</div>
              <div style={{ color: `${BLUE}` }}>{parseInt(offersProps.amount)}X Points</div>
            </>
          ) : (
            <>
              <img src={acquisitionOfferImage} alt="" style={styles.offerCardLogo} />
              <div style={styles.offerCardTitle}>Acquisition Offer</div>
              <div style={{ color: `${BLUE}` }}>${parseFloat(offersProps.amount / 100)}</div>
            </>
          )}
        </CardContent>
      </Card>
    )
  }
}
