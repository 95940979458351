function imgToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

export default imgToBase64;
