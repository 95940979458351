import React from 'react'
import { ResponsiveLine } from '@nivo/line'

const theme = {
  axis: {
    legend: {
      text: {
        fontSize: '1rem',
      },
    },
  },
}

export const NumOfTrxLineGraph = ({ data }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 25, right: 15, bottom: 75, left: 35 }}
    theme={theme}
    colors={data.map(c => c.color)}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    axisTop={null}
    axisRight={null}
    enableArea={false}
    enablePointLabel={true}
    isInteractive={false}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: 'Number of transactions',
      legendOffset: 65,
      legendPosition: 'middle',
    }}
    enableCrosshair={false}
    pointSize={6}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={1}
    pointBorderColor={{ from: 'serieColor' }}
  />
)
