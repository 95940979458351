import { MerchantOnboarding, OnboardingApiCalls, Auth } from '../Constants/action-types'
import { CountryRegionData } from 'react-country-region-selector'
import { initialState } from '../Store/initialState'
import { formatSocialMediaLinks } from '../Constants/utilityFunctions'

export default function onboardingReducers(state = initialState.onboarding, action) {
  if (action.type === MerchantOnboarding.MERCHANT_NAME) {
    return {
      ...state,
      merchantName: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.MERCHANT_CATEGORY) {
    return {
      ...state,
      merchantCategory: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.MERCHANT_EMAIL) {
    return {
      ...state,
      merchantEmail: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.MERCHANT_PHONE) {
    return {
      ...state,
      merchantPhone: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.ACCOUNT_NUMBER) {
    return {
      ...state,
      bankDetails: {
        ...state.bankDetails,
        accountNumber: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.ROUTING_NUMBER) {
    return {
      ...state,
      bankDetails: {
        ...state.bankDetails,
        routingNumber: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.DEPOSITORY_NAME) {
    return {
      ...state,
      bankDetails: {
        ...state.bankDetails,
        depositoryName: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.TAX_IDENTIFICATION_NUMBER) {
    return {
      ...state,
      bankDetails: {
        ...state.bankDetails,
        taxIdNumber: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_ADDRESS_1) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        streetAddress1: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_ADDRESS_2) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        streetAddress2: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_STATE) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        state: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_COUNTRY) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        country: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_CITY) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        city: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_ZIP_CODE) {
    return {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        zipCode: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_ADDRESS_1) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        streetAddress1: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_ADDRESS_2) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        streetAddress2: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_STATE) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        state: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_COUNTRY) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        country: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_CITY) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        city: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.SHIPPING_ZIP_CODE) {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        zipCode: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.BILLING_SAME_AS_SHIPPING) {
    if (action.payload) {
      return {
        ...state,
        billingSameAsShipping: action.payload,
        billingAddress: state.shippingAddress,
      }
    } else {
      return {
        ...state,
        billingSameAsShipping: action.payload,
        billingAddress: {
          streetAddress1: '',
          streetAddress2: '',
          state: 'ME',
          country: CountryRegionData.find(array => array[0] == 'United States'),
          city: '',
          zipCode: '',
        },
      }
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_NAME) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        name: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_ADDRESS_1) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        streetAddress1: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_ADDRESS_2) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        streetAddress2: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_STATE) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        state: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_COUNTRY) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        country: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_CITY) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        city: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_ZIP_CODE) {
    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        zipCode: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_GOOGLE_PLACE_ID) {
    return {
      ...state,
      googlePlaceDetails: {
        ...state.googlePlaceDetails,
        googlePlaceId: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_PHONE_NUMBER) {
    return {
      ...state,
      googlePlaceDetails: {
        ...state.googlePlaceDetails,
        phone: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_LATITUDE) {
    return {
      ...state,
      googlePlaceDetails: {
        ...state.googlePlaceDetails,
        latitude: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_LONGITUDE) {
    return {
      ...state,
      googlePlaceDetails: {
        ...state.googlePlaceDetails,
        longitude: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.CLEAR_LOCATION_ADDRESS) {
    return {
      ...state,
      locationAddress: {
        name: '',
        streetAddress1: '',
        streetAddress2: '',
        country: CountryRegionData.find(array => array[0] == 'United States'),
        state: 'ME',
        city: '',
        zipCode: '',
      },
      googlePlaceDetails: {
        googlePlaceId: '',
        phone: '',
        latitude: '',
        longitude: '',
      },
    }
  }

  if (action.type === MerchantOnboarding.CLEAR_LOCATION_CREATED) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        locationCreated: false
      }
    }
  }

  if (action.type === MerchantOnboarding.CLEAR_LOCATION_DETAILS) {
    return {
      ...state,
      locationDetails: {}
    }
  }

  if (action.type === MerchantOnboarding.CLEAR_SHIPPING_ADDRESS) {
    return {
      ...state,
      shippingAddress: {
        streetAddress1: '',
        streetAddress2: '',
        state: 'ME',
        country: CountryRegionData.find(array => array[0] == 'United States'),
        city: '',
        zipCode: '',
      },
    }
  }

  if (action.type === MerchantOnboarding.CLEAR_BILLING_ADDRESS) {
    return {
      ...state,
      billingAddress: {
        streetAddress1: '',
        streetAddress2: '',
        state: 'ME',
        country: CountryRegionData.find(array => array[0] == 'United States'),
        city: '',
        zipCode: '',
      },
    }
  }

  if (action.type === MerchantOnboarding.GOOGLE_PLACES) {
    return {
      ...state,
      placeResults: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.REGULAR_REWARD_AMOUNT) {
    return {
      ...state,
      offers: {
        ...state.offers,
        regular: {
          ...state.offers.regular,
          amount: action.payload,
        },
      },
    }
  }

  if (action.type === MerchantOnboarding.ACQUISITION_REWARD_AMOUNT) {
    return {
      ...state,
      offers: {
        ...state.offers,
        acquisition: {
          ...state.offers.acquisition,
          amount: action.payload,
        },
      },
    }
  }

  if (action.type === MerchantOnboarding.ACQUISITION_MIN_SPEND_AMOUNT) {
    return {
      ...state,
      offers: {
        ...state.offers,
        acquisition: {
          ...state.offers.acquisition,
          minSpend: action.payload,
        },
      },
    }
  }

  if (action.type === MerchantOnboarding.REGULAR_OFFER_ID) {
    return {
      ...state,
      offers: {
        ...state.offers,
        regular: {
          ...state.offers.regular,
          existingOfferId: action.payload,
        },
      },
    }
  }

  if (action.type === MerchantOnboarding.ACQUISITION_OFFER_ID) {
    return {
      ...state,
      offers: {
        ...state.offers,
        acquisition: {
          ...state.offers.acquisition,
          existingOfferId: action.payload,
        },
      },
    }
  }

  if (action.type === OnboardingApiCalls.OFFER_CREATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_UPDATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_LOCATION_CREATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        locationCreated: true,
      },
    }
  }

  if (action.type === OnboardingApiCalls.LOCATIONS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      locations: action.payload.locations,
    }
  }

  if (action.type === MerchantOnboarding.BUILD_LOCATIONS_FILTER) {
    let locationsFilter = [{ id: 'all', value: 'All locations' }]

    // Save the location id and name into a list for the reporting page locations filter
    for (let location of action.payload.locations) {
      locationsFilter.push({
        id: location.id,
        value: location.name,
      })
    }

    return {
      ...state,
      locationsFilter: locationsFilter,
    }
  }

  if (action.type === MerchantOnboarding.SET_LOCATION_FILTER) {
    return {
      ...state,
      selectedLocation: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.SET_DATE_RANGE_FILTER) {
    return {
      ...state,
      selectedDateRange: action.payload,
    }
  }

  if (action.type === MerchantOnboarding.POPULATE_LOCATION_DETAILS) {
    let locationCountry

    CountryRegionData.forEach(country => {
      if (country[0] === action.payload.address_attributes.country) {
        locationCountry = country
      }
    })

    return {
      ...state,
      locationAddress: {
        ...state.locationAddress,
        address_id: action.payload.address_attributes.id,
        name: action.payload.name ? action.payload.name : '',
        streetAddress1: action.payload.address_attributes.address_1
          ? action.payload.address_attributes.address_1
          : '',
        streetAddress2: action.payload.address_attributes.address_2
          ? action.payload.address_attributes.address_2
          : '',
        state: action.payload.address_attributes.state
          ? action.payload.address_attributes.state
          : '',
        city: action.payload.address_attributes.city ? action.payload.address_attributes.city : '',
        zipCode: action.payload.address_attributes.zip ? action.payload.address_attributes.zip : '',
        country: locationCountry,
      },
      googlePlaceDetails: {
        ...state.googlePlaceDetails,
        googlePlaceId: action.payload.google_place_id,
        phone: action.payload.phone ? action.payload.phone : '',
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      },
      locationDetails: {
        ...state.locationDetails,
        id: action.payload.id,
        description: action.payload.description ? action.payload.description : '',
        facebook_link: action.payload.facebook_link
          ? formatSocialMediaLinks(action.payload.facebook_link)
          : '',
        instagram_link: action.payload.instagram_link
          ? formatSocialMediaLinks(action.payload.instagram_link)
          : '',
        online_ordering_link: action.payload.online_ordering_link || '',
        website_link: action.payload.website_link ? action.payload.website_link : '',
      },
    }
  }

  if (action.type === MerchantOnboarding.POPULATE_SHIPPING_ADDRESS) {
    let locationCountry
    let shippingAddress = action.payload

    CountryRegionData.forEach(country => {
      if (country[0] === shippingAddress.country) {
        locationCountry = country
      }
    })

    return {
      ...state,
      shippingAddress: {
        streetAddress1: shippingAddress.address_1,
        streetAddress2: shippingAddress.address_2,
        state: shippingAddress.state,
        country: locationCountry,
        city: shippingAddress.city,
        zipCode: shippingAddress.zip,
      },
    }
  }

  if (action.type === MerchantOnboarding.POPULATE_BILLING_ADDRESS) {
    let locationCountry
    let billingAddress = action.payload

    CountryRegionData.forEach(country => {
      if (country[0] === billingAddress.country) {
        locationCountry = country
      }
    })

    return {
      ...state,
      billingAddress: {
        streetAddress1: billingAddress.address_1,
        streetAddress2: billingAddress.address_2,
        state: billingAddress.state,
        country: locationCountry,
        city: billingAddress.city,
        zipCode: billingAddress.zip,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_LOGO_IMAGE) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        logoImage: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_BANNER_IMAGE) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        bannerImage: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.ABOUT_LOCATION) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        description: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_WEBSITE) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        website_link: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_INSTAGRAM) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        instagram_link: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_FACEBOOK) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        facebook_link: action.payload,
      },
    }
  }

  if (action.type === MerchantOnboarding.LOCATION_ONLINE_ORDERING) {
    return {
      ...state,
      locationDetails: {
        ...state.locationDetails,
        online_ordering_link: action.payload,
      },
    }
  }

  if (action.type === OnboardingApiCalls.TAG_TYPES_RETRIEVED) { if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      tagTypes: action.payload.tag_types,
    }
  }

  if (action.type === OnboardingApiCalls.OFFERS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      merchantOffers: action.payload.offers,
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANTS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      merchantsCreated: action.payload.merchants,
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_LOCATION_UPDATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      locationDetails: {}
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_OFFER_UPDATED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }
  }

  if (action.type === MerchantOnboarding.SWITCH_APP_BAR_TAB) {
    return {
      ...state,
      appBarTab: action.payload,
    }
  }

  if (action.type === OnboardingApiCalls.MERCHANT_TRANSACTIONS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      filteredTransactions: action.payload.transactions,
      totalCount: action.payload.total_count,
    }
  }

  if (action.type === OnboardingApiCalls.LOCATION_TRANSACTIONS_RETRIEVED) {
    if (action.payload.error) {
      return {
        ...state,
        apiError: action.payload.error,
      }
    }

    return {
      ...state,
      filteredTransactions: action.payload.transactions,
    }
  }

  if (action.type === OnboardingApiCalls.SHOW_ALL_TRANSACTIONS) {
    return {
      ...state,
      filteredTransactions: state.transactions,
    }
  }

  if (action.type === Auth.USER_LOGGED_OUT) {
    return {
      ...initialState.onboarding,
    }
  }

  return state
}
