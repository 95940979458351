import React from 'react'
import { Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE } from '../../Constants/cssColors'

export default withStyles({
  root: {
    color: `${BLUE}`,
    '&$checked': {
      color: `${BLUE}`,
    },
  },
  checked: {},
})(props => <Radio {...props} />)
