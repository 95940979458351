import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ButtonBase, InputAdornment, withWidth } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import Dropzone from 'react-dropzone'
import uploadImage from '../../images/upload-icon.png'

import { getStyles } from '../../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { NotificationsActions } from '../../Actions/notificationsActions'
import { getLocationLatLong } from '../../Constants/utilityFunctions'
import { updateLocationHash } from '../../Constants/apiHashes'
import LocationPreviewPanel from '../../Components/LocationPreviewPanel'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LightBlueButton from '../../Components/UI/LightBlueButton'
import LocationAddressForm from '../../Components/LocationAddressForm'
import VerifyTerminalsSection from '../../Components/VerifyTerminalsSection'
import CssTextField from '../../Components/UI/CssTextField'
import imgToBase64 from '../../Components/base64'
import Map from "../../Components/Map"

class LocationEditPage extends Component {
  state = {
    bannerImage: uploadImage,
    logoImage: uploadImage,
    logoImageError: false,
    bannerImageError: false,
    previewBanner: null,
    previewLogo: null,
    website: false,
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.clearLocationAddressForm()

    const onboarding = this.props.onboarding
    const locations = onboarding.locations
    const locationId = this.props.match.params.id
    const selectedMerchantId = localStorage.selectedMerchant

    let merchantLogo = ''
    let merchantBanner = ''

    onboarding.merchantsCreated.forEach(merchant => {
      if (merchant.merchant_id === parseInt(selectedMerchantId)) {
        merchantLogo = merchant.logo
        merchantBanner = merchant.banner
      }
    })

    locations.forEach(locationEntry => {
      if (locationEntry.id === parseInt(locationId)) {
        this.props.populateLocationDetails(locationEntry)

        if (locationEntry.logo) {
          this.setState({
            logoImage: locationEntry.logo,
            previewLogo: locationEntry.logo,
          })
        } else if (merchantLogo) {
          this.setState({
            logoImage: merchantLogo,
            previewLogo: merchantLogo,
          })
        }

        if (locationEntry.banner) {
          this.setState({
            bannerImage: locationEntry.banner,
            previewBanner: locationEntry.banner,
          })
        } else if (merchantBanner) {
          this.setState({
            bannerImage: merchantBanner,
            previewBanner: merchantBanner,
          })
        }
      }
    })
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handleLogoUploadClick = acceptedFiles => {
    let logoImageSize = acceptedFiles[0].size / 1000

    if (logoImageSize > 400) {
      this.setState({ logoImageError: true })
    } else {
      let logoImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewLogo: logoImage, logoImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addLocationLogoImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  handleBannerUploadClick = acceptedFiles => {
    let bannerImageSize = acceptedFiles[0].size / 1000

    if (bannerImageSize > 800) {
      this.setState({ bannerImageError: true })
    } else {
      let bannerImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewBanner: bannerImage, bannerImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addLocationBannerImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  validateWebsiteAddress(website) {
    let regex = RegExp(
      //eslint-disable-next-line
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i,
    )

    this.setState({
      website: website === '' || !regex.test(website),
    })

    return website === '' || !regex.test(website)
  }

  handleSaveButtonPress = () => {
    let form = this.props.onboarding
    const merchantId = this.props.newMerchant.merchantId

    if (form.locationDetails.website_link) {
      var websiteError = this.validateWebsiteAddress(form.locationDetails.website_link)
    }

    if (!this.validateLocationAddressForm() && !websiteError) {
      getLocationLatLong(form.locationAddress)
        .then(() => {
          form = this.props.onboarding
          this.props.updateMerchantLocation(updateLocationHash(form, merchantId))
          this.props.history.goBack()
        })
        .catch(err => {
          this.props.displayErrorSnackbar(err)
        })
    }
  }

  handleBackButtonPress = () => {
    this.props.clearLocationAddressForm()
    this.props.clearLocationDetails()
    this.props.history.goBack()
  }

  render() {
    const { onboarding, width } = this.props

    let styles = getStyles(width)
    const mobileWidths = ['xs', 'sm']
    const isMobile = mobileWidths.includes(width)

    const location = {
      lat: parseFloat(onboarding.googlePlaceDetails.latitude),
      lng: parseFloat(onboarding.googlePlaceDetails.longitude),
    }

    return (
      <div>
        <div style={styles.pageContainer}>
          <h1 style={styles.title}>{onboarding.locationAddress.name}</h1>
          <div style={styles.subtitle}>Edit your merchant location details</div>
        </div>

        <div style={styles.previewContainer}>
          <div style={styles.pageContainer}>
            {location.lat ? <Map center={location} styles={styles} /> : null}

            <div style={styles.merchantInputFieldRow}>
              <CssTextField
                label="Location description"
                multiline={true}
                rows={4}
                rowsMax={4}
                variant="outlined"
                style={styles.textFieldFullWidth}
                value={onboarding.locationDetails.description || ''}
                onChange={this.handleChange('addAboutLocation')}
                inputProps={{
                  maxLength: 200
                }}
              />
            </div>

            <div style={styles.detailsContainer}>
              <h4>Upload Images</h4>
              <div style={styles.buttonImageRow}>
                <div style={styles.columnContainer}>
                  <div style={styles.imageButtonTitle}>List Image</div>

                  <Dropzone
                    onDropAccepted={acceptedFiles => this.handleLogoUploadClick(acceptedFiles)}
                    accept={'image/jpeg, image/png'}>
                    {({ getRootProps, getInputProps }) => (
                      <ButtonBase>
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div style={styles.buttonContainer}>
                              {!this.state.previewLogo ? (
                                <img
                                  src={this.state.logoImage}
                                  alt=""
                                  style={styles.uploadImageIcon}
                                />
                              ) : (
                                <img
                                  src={this.state.previewLogo}
                                  alt=""
                                  style={styles.buttonIcon}
                                />
                              )}
                            </div>
                          </div>
                        </section>
                      </ButtonBase>
                    )}
                  </Dropzone>

                  {this.state.logoImageError ? (
                    <div style={styles.imageUploadError}>Error: the image cannot exceed 400Kb</div>
                  ) : null}

                  <div style={styles.imageButtonInfoText}>
                    This image will appear in offer lists and marketing to consumers. Preferred
                    dimensions 400 x 400. Max size 400 kb. Accepted formats PNG, JPG.
                  </div>
                </div>

                <div style={{ margin: '0 2rem' }} />

                <div style={styles.columnContainer}>
                  <div style={styles.imageButtonTitle}>Banner image</div>

                  <Dropzone
                    onDropAccepted={acceptedFiles => this.handleBannerUploadClick(acceptedFiles)}
                    accept={'image/jpeg, image/png'}>
                    {({ getRootProps, getInputProps }) => (
                      <ButtonBase>
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div style={styles.buttonContainer}>
                              {!this.state.previewBanner ? (
                                <img
                                  src={this.state.bannerImage}
                                  alt=""
                                  style={styles.uploadImageIcon}
                                />
                              ) : (
                                <img
                                  src={this.state.previewBanner}
                                  alt=""
                                  style={styles.buttonIcon}
                                />
                              )}
                            </div>
                          </div>
                        </section>
                      </ButtonBase>
                    )}
                  </Dropzone>

                  {this.state.bannerImageError ? (
                    <div style={styles.imageUploadError}>Error: the image cannot exceed 800Kb</div>
                  ) : null}

                  <div style={styles.imageButtonInfoText}>
                    This image will show up in your merchant profile. Preferred dimensions 1500 x
                    400. Max size 800 kb. Accepted formats PNG, JPG.
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.detailsContainer}>
              <h4>Location Address</h4>
              <LocationAddressForm
                styles={styles}
                callValidateForm={addressValidation =>
                  (this.validateLocationAddressForm = addressValidation)
                }
              />
            </div>

            <div style={styles.detailsContainer}>
              <h4>Social Media</h4>

              <div style={styles.merchantInputFieldRow}>
                <CssTextField
                  label="Website"
                  margin="normal"
                  variant="outlined"
                  error={this.state.website}
                  style={styles.textFieldHalfWidth}
                  value={onboarding.locationDetails.website_link}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={styles.fontAwesomeIcon} icon={faGlobeAmericas} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChange('addLocationWebsite')}
                />

                <CssTextField
                  label="Facebook"
                  margin="normal"
                  variant="outlined"
                  style={styles.textFieldHalfWidth}
                  value={onboarding.locationDetails.facebook_link}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={styles.fontAwesomeIcon} icon={faFacebookSquare} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChange('addLocationFacebook')}
                />
              </div>

              <div style={styles.merchantInputFieldRow}>
                <CssTextField
                  label="Instagram"
                  margin="normal"
                  variant="outlined"
                  style={styles.textFieldHalfWidth}
                  value={onboarding.locationDetails.instagram_link}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={styles.fontAwesomeIcon} icon={faInstagram} />
                        {onboarding.locationDetails.instagram_link ? (
                          <div style={styles.handlePrefix}>@</div>
                        ) : (
                          ''
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChange('addLocationInstagram')}
                />

                <CssTextField
                  label="Online Ordering"
                  margin="normal"
                  variant="outlined"
                  style={styles.textFieldHalfWidth}
                  value={onboarding.locationDetails.online_ordering_link}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon style={styles.fontAwesomeIcon} icon={faShoppingBag} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleChange('addLocationOnlineOrdering')}
                />
              </div>
            </div>

            <VerifyTerminalsSection styles={styles} isMobile={isMobile} />

            <div
              style={{
                margin: '1rem 0',
                alignSelf: 'flex-start',
                display: 'flex',
                flexDirection: 'row',
              }}>
              <LightBlueButton
                variant="contained"
                size="large"
                onClick={this.handleBackButtonPress}>
                Back
              </LightBlueButton>
              <div style={{ margin: '0 1rem' }} />
              <DarkBlueButton
                variant="contained"
                size="large"
                onClick={this.handleSaveButtonPress}>
                Save
              </DarkBlueButton>
            </div>
          </div>

          {!isMobile ? (
            <LocationPreviewPanel
              bannerImage={this.state.previewBanner}
              logoImage={this.state.previewLogo}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearLocationAddressForm: () => dispatch(MerchantOnboardingActions.clearLocationAddressForm()),
    clearLocationDetails: () => dispatch(MerchantOnboardingActions.clearLocationDetails()),
    populateLocationDetails: data =>
      dispatch(MerchantOnboardingActions.populateLocationDetails(data)),
    addAboutLocation: about => dispatch(MerchantOnboardingActions.addAboutLocation(about)),
    addLocationLogoImage: image => dispatch(MerchantOnboardingActions.addLocationLogoImage(image)),
    addLocationBannerImage: image =>
      dispatch(MerchantOnboardingActions.addLocationBannerImage(image)),
    addLocationWebsite: website => dispatch(MerchantOnboardingActions.addLocationWebsite(website)),
    addLocationFacebook: facebook =>
      dispatch(MerchantOnboardingActions.addLocationFacebook(facebook)),
    addLocationInstagram: instagram =>
      dispatch(MerchantOnboardingActions.addLocationInstagram(instagram)),
    addLocationOnlineOrdering: url => dispatch(MerchantOnboardingActions.addLocationOnlineOrdering(url)),
    displayErrorSnackbar: error => dispatch(NotificationsActions.displayErrorSnackbar(error)),
    updateMerchantLocation: data => dispatch(MerchantApiActions.updateMerchantLocation(data)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(LocationEditPage)),
)
