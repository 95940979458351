import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Backdrop, Fade, Card, CardContent, CardMedia } from '@material-ui/core'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'

class VirtualCardModal extends Component {
  handleClose = () => {
    this.props.closeVirtualCardModal()
  }

  render () {
    const { virtualCardModal, virtualCardUrl } = this.props.newMerchant
    const { styles } = this.props

    return (
      <Modal 
        open={virtualCardModal}
        onClose={this.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={virtualCardModal}>
          <Card
            style={styles.virtualCardModal}
          >
            <CardContent style={{ padding: 0 }}>
              <CardMedia
                style={styles.virtualCardIFrame}
                component="iframe"
                src={virtualCardUrl}
              />
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeVirtualCardModal: () => dispatch(MerchantOnboardingActions.closeVirtualCardModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VirtualCardModal)
