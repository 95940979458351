import history from './history'
import { AuthActions } from './Actions/authActions'

export default class AuthModule {
  myStore
  expiresAt
  bearerToken

  constructor(store) {
    this.myStore = store
  }

  login(userData) {
    this.myStore.dispatch(AuthActions.logInUser(userData))
  }

  logout() {
    this.myStore.dispatch(AuthActions.userLogOut())
  }

  userLoggedOut() {
    localStorage.clear()

    history.replace('/')
  }

  signup(userData) {
    this.myStore.dispatch(AuthActions.signUpUser(userData))
  }

  handleAuthentication = response => {
    let headers = response.headers
    let authHeader = headers.get('Authorization')

    if (authHeader !== null) {
      window.localStorage.setItem('bearerToken', authHeader)
    }
  }

  saveTokenLifespan = body => {
    let tokenLifespan = body.expiration_token_time

    if (tokenLifespan !== null) {
      this.expiresAt = tokenLifespan * 7 + new Date().getTime()
      window.localStorage.setItem('tokenExpiry', this.expiresAt)
    }

    window.location.reload()
  }

  isAuthenticated(pathname) {
    // skip authentication if user is verifying an account
    if (!pathname.includes('confirm-email')) {
      let tokenPresent = window.localStorage.bearerToken !== null
      let lifespan = window.localStorage.tokenExpiry
      let sesionValid = new Date().getTime() < lifespan

      return tokenPresent && sesionValid
    }
  }
}
