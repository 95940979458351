import { OnboardingApiCalls } from '../Constants/action-types'

export const MerchantApiActions = {
  getMerchantLocations: function getMerchantLocations(payload) {
    return {
      type: OnboardingApiCalls.GET_MERCHANT_LOCATIONS,
      payload,
    }
  },
  createMerchantLocation: function createMerchantLocation(payload) {
    return {
      type: OnboardingApiCalls.CREATE_MERCHANT_LOCATION,
      payload,
    }
  },
  updateMerchantLocation: function updateMerchantLocation(payload) {
    return {
      type: OnboardingApiCalls.UPDATE_MERCHANT_LOCATION,
      payload,
    }
  },
  createMerchant: function createMerchant(payload) {
    return {
      type: OnboardingApiCalls.CREATE_MERCHANT,
      payload,
    }
  },
  getMerchants: function getMerchants(payload) {
    return {
      type: OnboardingApiCalls.GET_MERCHANTS,
      payload,
    }
  },
  merchantCreated: function merchantCreated(payload) {
    return {
      type: OnboardingApiCalls.MERCHANT_CREATED,
      payload,
    }
  },
  updateMerchant: function updateMerchant(payload) {
    return {
      type: OnboardingApiCalls.UPDATE_MERCHANT,
      payload,
    }
  },
  merchantUpdated: function merchantUpdated(payload) {
    return {
      type: OnboardingApiCalls.MERCHANT_UPDATED,
      payload,
    }
  },
  createOffer: function createOffer(payload) {
    return {
      type: OnboardingApiCalls.CREATE_OFFER,
      payload,
    }
  },
  deleteOffer: function deleteOffer(payload) {
    return {
      type: OnboardingApiCalls.DELETE_OFFER,
      payload,
    }
  },
  offerCreated: function offerCreated(payload) {
    return {
      type: OnboardingApiCalls.OFFER_CREATED,
      payload,
    }
  },
  getMerchantOffers: function getMerchantOffers(payload) {
    return {
      type: OnboardingApiCalls.GET_MERCHANT_OFFERS,
      payload,
    }
  },
  updateMerchantOffer: function updateMerchantOffer(payload) {
    return {
      type: OnboardingApiCalls.UPDATE_MERCHANT_OFFER,
      payload,
    }
  },
  getTagTypes: function getTagTypes(payload) {
    return {
      type: OnboardingApiCalls.GET_TAG_TYPES,
      payload,
    }
  },
  getMerchantDetails: function getMerchantDetails(payload) {
    return {
      type: OnboardingApiCalls.GET_MERCHANT_DETAILS,
      payload,
    }
  },
  getMerchantTransactions: function getMerchantTransactions(payload) {
    return {
      type: OnboardingApiCalls.GET_MERCHANT_TRANSACTIONS,
      payload,
    }
  },
  getLocationTransactions: function getLocationTransactions(payload) {
    return {
      type: OnboardingApiCalls.GET_LOCATION_TRANSACTIONS,
      payload,
    }
  },
  getAverageTicketValueReport: function getAverageTicketValueReport(payload) {
    return {
      type: OnboardingApiCalls.GET_AVG_TICKET_VALUE_REPORT,
      payload,
    }
  },
  getAllTimeTransactionsReport: function getAllTimeTransactionsReport(payload) {
    return {
      type: OnboardingApiCalls.GET_ALL_TIME_TRANSACTIONS_REPORT,
      payload,
    }
  },
  getMonthToDateTransactionsReport: function getMonthToDateTransactionsReport(payload) {
    return {
      type: OnboardingApiCalls.GET_MONTH_TO_DATE_TRANSACTIONS_REPORT,
      payload,
    }
  },
  getTotalPointsIssuedReport: function getTotalPointsIssuedReport(payload) {
    return {
      type: OnboardingApiCalls.GET_TOTAL_POINTS_ISSUED_REPORT,
      payload,
    }
  },
  getTotalSpentReport: function getTotalSpentReport(payload) {
    return {
      type: OnboardingApiCalls.GET_TOTAL_SPENT_REPORT,
      payload,
    }
  },
  getMonthToDateSpentReport: function getMonthToDateSpentReport(payload) {
    return {
      type: OnboardingApiCalls.GET_MONTH_TO_DATE_SPENT_REPORT,
      payload,
    }
  },
  getFirstTimeCustomersReport: function getFirstTimeCustomersReport(payload) {
    return {
      type: OnboardingApiCalls.GET_FIRST_TIME_CUSTOMERS_REPORT,
      payload,
    }
  },
  getMonthToDateFirstTimeCustomersReport: function getMonthToDateFirstTimeCustomersReport(payload) {
    return {
      type: OnboardingApiCalls.GET_MONTH_TO_DATE_FIRST_TIME_CUSTOMERS_REPORT,
      payload,
    }
  },
  createVirtualCard: function createVirtualCard(payload) {
    return {
      type: OnboardingApiCalls.CREATE_VIRTUAL_CARD,
      payload,
    }
  },
  getVirtualCardsList: function getVirtualCardsList(payload) {
    return {
      type: OnboardingApiCalls.GET_VIRTUAL_CARDS_LIST,
      payload,
    }
  },
  getVirtualCardInfo: function getVirtualCardInfo(payload) {
    return {
      type: OnboardingApiCalls.GET_VIRTUAL_CARD_INFO,
      payload,
    }
  },
  getVirtualCardUrl: function getVirtualCardUrl(payload) {
    return {
      type: OnboardingApiCalls.GET_VIRTUAL_CARD_URL,
      payload,
    }
  },
  getLocationAndGenerateVirtualCard: function getLocationAndGenerateVirtualCard(payload) {
    return {
      type: OnboardingApiCalls.GET_LOCATION_AND_GENERATE_VIRTUAL_CARD,
      payload,
    }
  },
  createCardsRequest: function createCardsRequest(payload) {
    return {
      type: OnboardingApiCalls.CREATE_CARDS_REQUEST,
      payload,
    }
  },
  cardsRequestCreated: function cardsRequestCreated(payload) {
    return {
      type: OnboardingApiCalls.CARDS_REQUEST_CREATED,
      payload,
    }
  },
}
