import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper } from '@material-ui/core'

import { loadHomePageReports } from '../Constants/utilityFunctions'
import { RED, GREEN } from '../Constants/cssColors'

class HomePageKPIs extends Component {
  componentDidMount = () => {
    let merchantId = this.props.match.params.id

    if (merchantId) {
      loadHomePageReports(merchantId)
    }
  }

  render() {
    const { styles } = this.props
    const { monthToDateTransactionsReport, monthToDateSpentReport, monthToDateFirstTimeCustomers } = this.props.newMerchant

    const multipleMonthData = monthToDateTransactionsReport.detail === 'per_month'

    return (
      <div style={{ ...styles.reportingSection, justifyContent: 'space-between' }}>
        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>Total Transactions</div>
            <div style={styles.reportingCardValue}>
              {monthToDateTransactionsReport.all_time_transactions
                ? monthToDateTransactionsReport.all_time_transactions.toLocaleString()
                : 0}
            </div>
            <div style={styles.reportingCardFooter}>
              {monthToDateTransactionsReport.transactions_per_date_range && multipleMonthData ? (
                <CalculatePercent
                  monthlyData={monthToDateTransactionsReport.transactions_per_date_range}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </Paper>

        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>Total Sales</div>
            <div style={styles.reportingCardValue}>
              {monthToDateSpentReport.total_spent_value
                ? `$${(monthToDateSpentReport.total_spent_value / 100).toLocaleString('en-US', {minimumFractionDigits: 2})}`
                : 0}
            </div>
            <div style={styles.reportingCardFooter}>
              {monthToDateSpentReport.total_spent_per_date_range && multipleMonthData ? (
                <CalculatePercent monthlyData={monthToDateSpentReport.total_spent_per_date_range} />
              ) : (
                ''
              )}
            </div>
          </div>
        </Paper>

        <Paper style={styles.reportingCard}>
          <div style={styles.reportingCardContent}>
            <div style={styles.reportingCardTitle}>New Customers</div>
            <div style={styles.reportingCardValue}>
              {monthToDateFirstTimeCustomers.first_time_customers 
                ? monthToDateFirstTimeCustomers.first_time_customers.toLocaleString()
                : 0}
            </div>
            <div style={styles.reportingCardFooter}>
              {monthToDateFirstTimeCustomers.first_time_customers_per_date_range & multipleMonthData ? (
                <CalculatePercent
                  monthlyData={monthToDateFirstTimeCustomers.first_time_customers_per_date_range}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

function CalculatePercent({ monthlyData }) {
  let prevMonthValue
  let currentMonthValue
  let percentageDiff

  let currentDate = new Date()
  let currentMonth = currentDate.getMonth() + 1
  let currentYear = currentDate.getFullYear()

  currentMonthValue = monthlyData[`[${currentYear}, ${currentMonth}]`]

  if (currentMonth === 1) {
    prevMonthValue = monthlyData[`[${currentYear - 1}, 12]`]
  } else {
    prevMonthValue = monthlyData[`[${currentYear}, ${currentMonth - 1}]`]
  }

  // Calculate the difference in percentage between last month and this month
  percentageDiff = (Math.abs(prevMonthValue - currentMonthValue) / prevMonthValue) * 100

  const showDiffFromLastMonth = () => {
    return (
      <>
        {currentMonthValue >= prevMonthValue ? (
          <>
            <div style={{ color: `${GREEN}` }}>Up {percentageDiff.toFixed(2)}% </div>
            <div>since last month</div>
          </>
        ) : (
          <>
            <div style={{ color: `${RED}` }}>Down {percentageDiff.toFixed(2)}% </div>
            <div>since last month</div>
          </>
        )}
      </>
    )
  }

  return <>{currentMonthValue && prevMonthValue ? showDiffFromLastMonth() : ''}</>
}

const mapStateToProps = state => {
  return {
    newMerchant: state.newMerchant,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(HomePageKPIs)
)
