import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Card, CardContent, Divider } from '@material-ui/core'
import ReactGoogleMapImage from 'react-google-map-image'
import { IntlProvider, FormattedDate } from 'react-intl'

import DarkBlueButton from './UI/DarkBlueButton'

class LocationCard extends Component {
  handleEditLocation = id => {
    this.props.history.push(`/edit-location/${id}`)
  }

  render() {
    const { locationAddress, locationData, styles, isMobile } = this.props
    const googleImageWidth = isMobile
      ? Math.round(window.innerWidth / 1.2)
      : Math.round(window.innerWidth / 2)
    const googleImageHeight = isMobile
      ? Math.round(window.innerHeight / 8)
      : Math.round(window.innerHeight / 7)

    const googleMapApiConfig = {
      center: locationAddress,
      size: `${googleImageWidth}x${googleImageHeight}`,
      zoom: '11',
      key: process.env.REACT_APP_PLACES_KEY,
      maptype: 'roadmap',
      markers:"color:red|" + locationAddress,
    }

    return (
      <Card style={styles.locationCard}>
        <div style={styles.googleImageContainer}>
          <ReactGoogleMapImage
            config={googleMapApiConfig}
            wrapperStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        </div>

        <CardContent style={styles.locationCardContent}>
          <div style={styles.locationCardTitle}>
            <div>{locationData.name}</div>
            <div style={styles.locationCardAddress}>{locationAddress}</div>
          </div>

          <Divider style={{ width: '100%' }} />

          <div style={styles.locationCardFooter}>
            <div style={styles.locationCardRow}>
              <div>Added</div>
              <div style={{ color: '#ABABAB' }}>
                <IntlProvider locale={'en'}>
                  <FormattedDate
                    value={new Date(locationData.created_at)}
                    month="long"
                    day="numeric"
                    year="numeric"
                  />
                </IntlProvider>
              </div>
            </div>

            <div style={styles.locationCardRow}>
              <div>Terminals</div>
              <div
                style={
                  locationData.active_terminals_count <= 0 ? { color: 'red' } : { color: 'black' }
                }>
                {locationData.active_terminals_count}
              </div>
            </div>

            <div style={styles.locationCardButtonRow}>
              <DarkBlueButton
                variant="outlined"
                size={isMobile ? 'small' : 'medium'}
                color="primary"
                onClick={() => this.handleEditLocation(locationData.id)}>
                Edit Location
              </DarkBlueButton>
            </div>
          </div>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(withRouter(LocationCard)),
)
