import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { getStyles, validateEmail, validatePhone } from '../../Constants/utilityFunctions'
import CustomColorPhoneInput from '../../Components/UI/CustomColorPhoneInput'
import { AuthActions } from '../../Actions/authActions'
import { updateUserProfileHash } from '../../Constants/apiHashes'
import CssTextField from '../../Components/UI/CssTextField'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LightBlueButton from '../../Components/UI/LightBlueButton'
import DeleteUserDialog from '../../Components/DeleteUserDialog'

class ProfileEditPage extends Component {
  state = {
    name: false,
    email: false,
    phone: false,
    password: false,
    confirmPassword: false,
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.getUserProfile()
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handlePhoneInput = value => {
    this.props.addUserPhone(value)
  }

  validateChangePassword = () => {
    const form = this.props.userForm

    if (form.userPassword !== '' && form.confirmPassword !== '') {
      if (form.userPassword === form.confirmPassword) {
        this.setState({
          password: false,
          confirmPassword: false,
        })

        return false
      } else {
        this.setState({
          password: true,
          confirmPassword: true,
        })

        return true
      }
    }
  }

  validateForm = () => {
    const form = this.props.userForm

    this.setState({
      name: form.userName === '',
      email: validateEmail(form.userEmail),
      phone: validatePhone(form.userPhone),
    })

    let userFormErrors =
      form.userName === '' || 
      validateEmail(form.userEmail) || 
      validatePhone(form.userPhone) ||
      this.validateChangePassword()

    return userFormErrors
  }

  handleSaveButtonPress = () => {
    const form = this.props.userForm

    if (!this.validateForm()) {
      this.props.updateUserProfile(updateUserProfileHash(form))
      this.props.history.goBack()
    }
  }

  handleBackButtonPress = () => {
    this.props.history.goBack()
  }

  render() {
    const { userForm, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>User Profile</h1>

        <div style={styles.detailsContainer}>
          <CssTextField
            label="Business Owner Name"
            margin="normal"
            error={this.state.name}
            variant="outlined"
            style={styles.textFieldMediumWidth}
            value={userForm.userName}
            onChange={this.handleChange('addUserName')}
          />

          <CssTextField
            label="Email"
            margin="normal"
            error={this.state.email}
            type="email"
            variant="outlined"
            style={styles.textFieldMediumWidth}
            value={userForm.userEmail}
            onChange={this.handleChange('addUserEmail')}
          />

          <CustomColorPhoneInput
            margin="normal"
            variant="outlined"
            label="Phone"
            error={this.state.phone}
            defaultCountry="us"
            style={styles.textFieldMediumWidth}
            value={userForm.userPhone}
            onChange={value => this.handlePhoneInput(value)}
          />

          <CssTextField
            label="Change Password"
            margin="normal"
            error={this.state.password}
            variant="outlined"
            type="password"
            value={userForm.userPassword}
            style={styles.textFieldMediumWidth}
            onChange={this.handleChange('addUserPassword')}
          />

          <CssTextField
            label="Confirm Password"
            margin="normal"
            error={this.state.confirmPassword}
            variant="outlined"
            type="password"
            value={userForm.confirmPassword}
            style={styles.textFieldMediumWidth}
            onChange={this.handleChange('confirmUserPassword')}
          />

          <div style={styles.editUserButtonRow}>
            <div style={styles.backSaveButtonGroup}>
              <LightBlueButton
                variant="contained"
                size="large"
                onClick={this.handleBackButtonPress}>
                Back
              </LightBlueButton>

              <div style={{ margin: '0 0.5rem' }} />

              <DarkBlueButton variant="contained" size="large" onClick={this.handleSaveButtonPress}>
                Save
              </DarkBlueButton>
            </div>

            <div style={{ margin: '0 0.5rem' }} />

            <DeleteUserDialog />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserProfile: () => dispatch(AuthActions.getUserProfile()),
    addUserEmail: email => dispatch(AuthActions.addUserEmail(email)),
    addUserName: name => dispatch(AuthActions.addUserName(name)),
    addUserPhone: phone => dispatch(AuthActions.addUserPhone(phone)),
    addUserPassword: password => dispatch(AuthActions.addUserPassword(password)),
    confirmUserPassword: confirmation => dispatch(AuthActions.confirmUserPassword(confirmation)),
    updateUserProfile: data => dispatch(AuthActions.updateUserProfile(data)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(ProfileEditPage)),
)
