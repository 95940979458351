import React, { Component } from 'react'
import { withWidth } from '@material-ui/core'
import { connect } from 'react-redux'

import { getStyles } from '../Constants/utilityFunctions'
import { AuthActions } from '../Actions/authActions'
import { recoverPasswordHash } from '../Constants/apiHashes'
import CssTextField from '../Components/UI/CssTextField'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import bsb from '../images/buoy-local-logo-empowering.png'

class RecoverPassword extends Component {
  state = {
    emailError: false,
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  validateEmail = email => {
    email = email.trim()
    let regex = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

    if (email === '' || !regex.test(email)) {
      this.setState({
        emailError: true,
      })

      return false
    } else {
      this.setState({
        emailError: false,
      })

      return true
    }
  }

  catchReturn(event) {
    if (event.key === 'Enter') {
      this.handleSubmitClick()
    }
  }

  handleSubmitClick() {
    const form = this.props.authForm

    if (this.validateEmail(form.userEmail)) {
      this.props.recoverPassword(recoverPasswordHash(form.userEmail))
    }
  }

  render() {
    const { authForm, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.loginPageContainer}>
        <div style={styles.pageContainer}>
          <div style={styles.loginContainer}>
            <img src={bsb} alt="" style={styles.buoyLogo} />
            <div style={styles.loginSignupTitle}>Recover Password</div>

            <CssTextField
              label="Email"
              error={this.state.emailError}
              margin="normal"
              variant="outlined"
              type="email"
              style={styles.textFieldMediumWidth}
              value={authForm.userEmail}
              onChange={this.handleChange('addUserEmail')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <br />

            <DarkBlueButton
              size="large"
              variant="contained"
              style={styles.loginButton}
              onClick={() => this.handleSubmitClick()}>
              Submit
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUserEmail: email => dispatch(AuthActions.addUserEmail(email)),
    recoverPassword: email => dispatch(AuthActions.recoverPassword(email)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(RecoverPassword))
