import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import onboardingReducers from './onboardingReducers'
import authReducers from './authReducers'
import newMerchantReducers from './newMerchantReducers'
import notificationsReducers from './notificationsReducers'

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['notifications']
}

const rootReducer = combineReducers({
  authForm: authReducers,
  onboarding: onboardingReducers,
  newMerchant: newMerchantReducers,
  notifications: notificationsReducers,
})

export default persistReducer(rootPersistConfig, rootReducer)
