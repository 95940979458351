import React, { Component } from 'react'

import { withWidth } from '@material-ui/core'
import { connect } from 'react-redux'

import { getStyles, parseQueryString } from '../Constants/utilityFunctions'
import { confirmEmailHash } from '../Constants/apiHashes'

import DarkBlueButton from '../Components/UI/DarkBlueButton'
import bsb from '../images/buoy-local-logo-empowering.png'

import { AuthActions } from '../Actions/authActions'

class ConfirmEmail extends Component {
  state = {
    token: '',
    email: ''
  }

  componentDidMount = () => {
    let query = this.props.match.params.query
    let params = parseQueryString(query)

    for (let [key, value] of Object.entries(params)) {
      if (key === 'confirmation_token') {
        this.setState({token: value})
      }

      if (key === 'email') {
        this.setState({email: value})
      }
    }
  }

  handleSubmitClick() {
    this.props.confirmEmail(confirmEmailHash(this.state.token, this.state.email))
  }

  render() {
    const { width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.loginPageContainer}>
        <div style={styles.pageContainer}>
          <div style={styles.loginContainer}>
            <img src={bsb} alt="" style={styles.buoyLogo} />
            <div style={styles.loginSignupTitle}>Confirm Email</div>

            <div>Please click below to verify your account!</div>

            <br />

            <DarkBlueButton
              size="large"
              variant="contained"
              style={styles.loginButton}
              onClick={() => this.handleSubmitClick()}>
              Verify my account
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    confirmEmail: (payload) => dispatch(AuthActions.confirmEmail(payload)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withWidth()(ConfirmEmail))
