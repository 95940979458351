import { MerchantOnboarding } from '../Constants/action-types'

export const MerchantOnboardingActions = {
  addMerchantId: function addMerchantId(payload) {
    return {
      type: MerchantOnboarding.MERCHANT_ID,
      payload,
    }
  },
  addMerchantName: function addMerchantName(payload) {
    return {
      type: MerchantOnboarding.MERCHANT_NAME,
      payload,
    }
  },
  addMerchantCategory: function addMerchantCategory(payload) {
    return {
      type: MerchantOnboarding.MERCHANT_CATEGORY,
      payload,
    }
  },
  addMerchantEmail: function addMerchantEmail(payload) {
    return {
      type: MerchantOnboarding.MERCHANT_EMAIL,
      payload,
    }
  },
  addMerchantPhone: function addMerchantPhone(payload) {
    return {
      type: MerchantOnboarding.MERCHANT_PHONE,
      payload,
    }
  },
  addAccountNumber: function addAccountNumber(payload) {
    return {
      type: MerchantOnboarding.ACCOUNT_NUMBER,
      payload,
    }
  },
  addRoutingNumber: function addRoutingNumber(payload) {
    return {
      type: MerchantOnboarding.ROUTING_NUMBER,
      payload,
    }
  },
  addDepositoryName: function addDepositoryName(payload) {
    return {
      type: MerchantOnboarding.DEPOSITORY_NAME,
      payload,
    }
  },
  addTaxIdNumber: function addTaxIdNumber(payload) {
    return {
      type: MerchantOnboarding.TAX_IDENTIFICATION_NUMBER,
      payload,
    }
  },
  addBillingAddress1: function addBillingAddress1(payload) {
    return {
      type: MerchantOnboarding.BILLING_ADDRESS_1,
      payload,
    }
  },
  addBillingAddress2: function addBillingAddress2(payload) {
    return {
      type: MerchantOnboarding.BILLING_ADDRESS_2,
      payload,
    }
  },
  addBillingState: function addBillingState(payload) {
    return {
      type: MerchantOnboarding.BILLING_STATE,
      payload,
    }
  },
  addBillingCountry: function addBillingCountry(payload) {
    return {
      type: MerchantOnboarding.BILLING_COUNTRY,
      payload,
    }
  },
  addBillingCity: function addBillingCity(payload) {
    return {
      type: MerchantOnboarding.BILLING_CITY,
      payload,
    }
  },
  addBillingZipCode: function addBillingZipCode(payload) {
    return {
      type: MerchantOnboarding.BILLING_ZIP_CODE,
      payload,
    }
  },
  addShippingAddress1: function addShippingAddress1(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_ADDRESS_1,
      payload,
    }
  },
  addShippingAddress2: function addShippingAddress2(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_ADDRESS_2,
      payload,
    }
  },
  addShippingState: function addShippingState(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_STATE,
      payload,
    }
  },
  addShippingCountry: function addShippingCountry(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_COUNTRY,
      payload,
    }
  },
  addShippingCity: function addShippingCity(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_CITY,
      payload,
    }
  },
  addShippingZipCode: function addShippingZipCode(payload) {
    return {
      type: MerchantOnboarding.SHIPPING_ZIP_CODE,
      payload,
    }
  },
  addLocationName: function addLocationName(payload) {
    return {
      type: MerchantOnboarding.LOCATION_NAME,
      payload,
    }
  },
  addLocationAddress1: function addLocationAddress1(payload) {
    return {
      type: MerchantOnboarding.LOCATION_ADDRESS_1,
      payload,
    }
  },
  addLocationAddress2: function addLocationAddress2(payload) {
    return {
      type: MerchantOnboarding.LOCATION_ADDRESS_2,
      payload,
    }
  },
  addLocationState: function addLocationState(payload) {
    return {
      type: MerchantOnboarding.LOCATION_STATE,
      payload,
    }
  },
  addLocationCountry: function addLocationCountry(payload) {
    return {
      type: MerchantOnboarding.LOCATION_COUNTRY,
      payload,
    }
  },
  addLocationCity: function addLocationCity(payload) {
    return {
      type: MerchantOnboarding.LOCATION_CITY,
      payload,
    }
  },
  addLocationZipCode: function addLocationZipCode(payload) {
    return {
      type: MerchantOnboarding.LOCATION_ZIP_CODE,
      payload,
    }
  },
  addLocationGooglePlaceId: function addLocationGooglePlaceId(payload) {
    return {
      type: MerchantOnboarding.LOCATION_GOOGLE_PLACE_ID,
      payload,
    }
  },
  addLocationPhoneNumber: function addLocationPhoneNumber(payload) {
    return {
      type: MerchantOnboarding.LOCATION_PHONE_NUMBER,
      payload,
    }
  },
  addLocationLatitude: function addLocationLatitude(payload) {
    return {
      type: MerchantOnboarding.LOCATION_LATITUDE,
      payload,
    }
  },
  addLocationLongitude: function addLocationLongitude(payload) {
    return {
      type: MerchantOnboarding.LOCATION_LONGITUDE,
      payload,
    }
  },
  billingSameAsShipping: function billingSameAsShipping(payload) {
    return {
      type: MerchantOnboarding.BILLING_SAME_AS_SHIPPING,
      payload,
    }
  },
  clearLocationCreated: function clearLocationCreated() {
    return {
      type: MerchantOnboarding.CLEAR_LOCATION_CREATED,
    }
  }, 
  clearLocationDetails: function clearLocationDetails() {
    return {
      type: MerchantOnboarding.CLEAR_LOCATION_DETAILS,
    }
  }, 
  clearLocationAddressForm: function clearLocationAddressForm() {
    return {
      type: MerchantOnboarding.CLEAR_LOCATION_ADDRESS,
    }
  },
  clearShippingAddressForm: function clearShippingAddressForm() {
    return {
      type: MerchantOnboarding.CLEAR_SHIPPING_ADDRESS,
    }
  },
  clearBillingAddressForm: function clearBillingAddressForm() {
    return {
      type: MerchantOnboarding.CLEAR_BILLING_ADDRESS,
    }
  },
  addLogoImage: function addLogoImage(payload) {
    return {
      type: MerchantOnboarding.LOGO_IMAGE,
      payload,
    }
  },
  addBannerImage: function addBannerImage(payload) {
    return {
      type: MerchantOnboarding.BANNER_IMAGE,
      payload,
    }
  },
  addGooglePlaceResults: function addGooglePlaceResults(payload) {
    return {
      type: MerchantOnboarding.GOOGLE_PLACES,
      payload,
    }
  },
  setRegOfferAmount: function setRegOfferAmount(payload) {
    return {
      type: MerchantOnboarding.REGULAR_REWARD_AMOUNT,
      payload,
    }
  },
  setAcqOfferAmount: function setAcqOfferAmount(payload) {
    return {
      type: MerchantOnboarding.ACQUISITION_REWARD_AMOUNT,
      payload,
    }
  },
  setAcqOfferMinSpend: function setAcqOfferMinSpend(payload) {
    return {
      type: MerchantOnboarding.ACQUISITION_MIN_SPEND_AMOUNT,
      payload,
    }
  },
  setRegOfferId: function setRegOfferId(payload) {
    return {
      type: MerchantOnboarding.REGULAR_OFFER_ID,
      payload,
    }
  },
  setAcqOfferId: function setAcqOfferId(payload) {
    return {
      type: MerchantOnboarding.ACQUISITION_OFFER_ID,
      payload,
    }
  },
  getShippingAddress: function getShippingAddress(payload) {
    return {
      type: MerchantOnboarding.GET_SHIPPING_ADDRESS,
      payload,
    }
  },
  populateLocationDetails: function populateLocationDetails(payload) {
    return {
      type: MerchantOnboarding.POPULATE_LOCATION_DETAILS,
      payload,
    }
  },
  populateShippingAddress: function populateShippingAddress(payload) {
    return {
      type: MerchantOnboarding.POPULATE_SHIPPING_ADDRESS,
      payload,
    }
  },
  populateBillingAddress: function populateBillingAddress(payload) {
    return {
      type: MerchantOnboarding.POPULATE_BILLING_ADDRESS,
      payload,
    }
  },
  addLocationLogoImage: function addLocationLogoImage(payload) {
    return {
      type: MerchantOnboarding.LOCATION_LOGO_IMAGE,
      payload,
    }
  },
  addLocationBannerImage: function addLocationBannerImage(payload) {
    return {
      type: MerchantOnboarding.LOCATION_BANNER_IMAGE,
      payload,
    }
  },
  addAboutLocation: function addAboutLocation(payload) {
    return {
      type: MerchantOnboarding.ABOUT_LOCATION,
      payload,
    }
  },
  addLocationWebsite: function addLocationWebsite(payload) {
    return {
      type: MerchantOnboarding.LOCATION_WEBSITE,
      payload,
    }
  },
  addLocationInstagram: function addLocationInstagram(payload) {
    return {
      type: MerchantOnboarding.LOCATION_INSTAGRAM,
      payload,
    }
  },
  addLocationFacebook: function addLocationFacebook(payload) {
    return {
      type: MerchantOnboarding.LOCATION_FACEBOOK,
      payload,
    }
  },
  addLocationOnlineOrdering: function addLocationOnlineOrdering(payload) {
    return {
      type: MerchantOnboarding.LOCATION_ONLINE_ORDERING,
      payload,
    }
  },
  switchAppBarTab: function switchAppBarTab(payload) {
    return {
      type: MerchantOnboarding.SWITCH_APP_BAR_TAB,
      payload,
    }
  },
  setLocationFilter: function setLocationFilter(payload) {
    return {
      type: MerchantOnboarding.SET_LOCATION_FILTER,
      payload,
    }
  },
  setDateRangeFilter: function setDateRangeFilter(payload) {
    return {
      type: MerchantOnboarding.SET_DATE_RANGE_FILTER,
      payload,
    }
  },
  filterLocations: function filterLocations(payload) {
    return {
      type: MerchantOnboarding.FILTER_LOCATIONS,
      payload,
    }
  },
  closeVirtualCardModal: function closeVirtualCardModal() {
    return {
      type: MerchantOnboarding.CLOSE_VIRTUAL_CARD_MODAL
    }
  },
}
