import { Notifications } from '../Constants/action-types'

export const NotificationsActions = {
  displayErrorSnackbar: function displayErrorSnackbar(payload) {
    return {
      type: Notifications.ERROR_SNACKBAR,
      payload
    }
  },
  displaySuccessSnackbar: function displaySuccessSnackbar(payload) {
    return {
      type: Notifications.SUCCESS_SNACKBAR,
      payload
    }
  },
  displayInfoSnackbar: function displayInfoSnackbar(payload) {
    return {
      type: Notifications.INFO_SNACKBAR,
      payload
    }
  },
  displayWarningSnackbar: function displayWarningSnackbar(payload) {
    return {
      type: Notifications.WARNING_SNACKBAR,
      payload
    }
  },
  closeSnackbar: function closeSnackbar() {
    return {
      type: Notifications.REMOVE_SNACKBAR
    }
  },
}
