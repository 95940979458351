import history from '../history'
import { NotificationsActions } from '../Actions/notificationsActions'
import store from '../Store'

export default function handleErrors(response) {
  const code = response.status
  const httpStatuses = [400, 401, 403, 404, 422, 424, 500, 503]

  if (code === 451) {
    history.replace('/pending-agreements')
  }

  if (httpStatuses.includes(code)) {
    store.dispatch(NotificationsActions.displayErrorSnackbar(response.statusText))
  }
}
