import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import sanitizeHtml from "sanitize-html"

export default function LegalNoticesAccordion({ notices, styles }) {
  if (notices.length > 0) {
    return notices.map(notice => {
      return <LegalNotice notice={notice} key={notice['id']} styles={styles} />
    })
  } else {
    return <div style={{ alignSelf: 'flex-start' }}>No agreements present</div>
  }
}

function LegalNotice({ notice, styles }) {
  const [expanded, setExpanded] = React.useState(false)

  const trackPanelExpansion = () => {
    if (!expanded) {
      setExpanded(!expanded)
    } else {
      setExpanded(!expanded)
    }
  }

  return (
    <Accordion
      style={styles.agreementsContainer}
      expanded={expanded}
      onClick={trackPanelExpansion}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon style={styles.fontAwesomeIcon} icon={faChevronDown} />}>
        <div style={styles.legalNoticeTitle} >{notice.title}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.agreementBody}>
          <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(notice.terms_and_conditions) }}/>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
