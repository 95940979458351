import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card, CardContent, ButtonBase, Tooltip } from '@material-ui/core'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'

const dollarToPointsRatio = 100

class AcqOfferButtons extends Component {
  state = {
    offer: this.props.offer,
    // pointsButtonSelected: 2,
    pointsButtonSelected: 4,
    values: [20, 10, 5, 2, 0],
    purchaseThreshold: [100, 50, 25, 15, 0],
  }

  componentDidMount = () => {
    // Initialize the component with "$5 offer" pre-selected during the signup flow
    if(this.onboardingPage()) {
      this.handlePointsButtonClick(2)
    } else {
      // this.handlePointsButtonClick(4)
    }
  }

  static getDerivedStateFromProps(props, state) {
    // The offer props is used on the Offers Edit page
    if (props.offer !== state.offer) {
      return {
        pointsButtonSelected: props.offer.amount / dollarToPointsRatio,
        offer: props.offer,
      }
    }

    return null
  }

  handlePointsButtonClick = index => {
    this.props.setAcqOfferAmount(this.state.values[index] * dollarToPointsRatio)
    this.props.setAcqOfferMinSpend(this.state.purchaseThreshold[index] * dollarToPointsRatio)

    this.setState({
      pointsButtonSelected: this.state.values[index],
    })
  }

  onboardingPage = () => {
    let signupFlow = localStorage.getItem('onboardingPage')
    return signupFlow === 'setUpOffersPage'
  }

  render() {
    const { values, purchaseThreshold } = this.state
    const { styles } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Card
          raised={this.state.pointsButtonSelected === values[0]}
          // onClick={() => this.handlePointsButtonClick(0)}
          style={{
            ...styles.offerButtonContainer,
            border: `1px solid lightgrey`,
            // this.state.pointsButtonSelected === values[0] ? `${BLUE}` : 'lightgrey'
          }}>
          <Tooltip title="Merchant is responsible for funding this offer" placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.acqOfferButton,
                  color: 'lightgray',
                }}>
                <div>${values[0]}</div>
                <div style={styles.buttonTextSubtext}>purchase over ${purchaseThreshold[0]}</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[1]}
          // onClick={() => this.handlePointsButtonClick(1)}
          style={{
            ...styles.offerButtonContainer,
            border: '1px solid lightgray',
          }}>
          <Tooltip title="Merchant is responsible for funding this offer" placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.acqOfferButton,
                  color: 'lightgray',
                }}>
                <div>${values[1]}</div>
                <div style={styles.buttonTextSubtext}>purchase over ${purchaseThreshold[1]}</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[2]}
          // onClick={() => this.handlePointsButtonClick(2)}
          style={{
            ...styles.offerButtonContainer,
            border: '1px solid lightgray',
          }}>
          <Tooltip title="Merchant is responsible for funding this offer" placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.acqOfferButton,
                  color: 'lightgray',
                }}>
                <div>${values[2]}</div>
                <div style={styles.buttonTextSubtext}>purchase over ${purchaseThreshold[2]}</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[3]}
          // onClick={() => this.handlePointsButtonClick(3)}
          style={{
            ...styles.offerButtonContainer,
            border: '1px solid lightgray',
          }}>
          <Tooltip title="Merchant is responsible for funding this offer" placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.acqOfferButton,
                  color: 'lightgray',
                }}>
                <div>${values[3]}</div>
                <div style={styles.buttonTextSubtext}>purchase over ${purchaseThreshold[3]}</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>

        <Card
          raised={this.state.pointsButtonSelected === values[4]}
          // onClick={() => this.handlePointsButtonClick(4)}
          style={{
            ...styles.offerButtonContainer,
            border: '1px solid lightgray',
          }}>
          <Tooltip
            title="Acquisition Offers are a great way to attract new customers. Consider setting an offer"
            placement="top">
            <ButtonBase style={{ height: '100%', width: '100%' }}>
              <CardContent
                style={{
                  ...styles.acqOfferButton,
                  color: 'lightgray',
                }}>
                <div style={styles.noSubtextButton}>No offer</div>
              </CardContent>
            </ButtonBase>
          </Tooltip>
        </Card>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAcqOfferAmount: amount => dispatch(MerchantOnboardingActions.setAcqOfferAmount(amount)),
    setAcqOfferMinSpend: amount => dispatch(MerchantOnboardingActions.setAcqOfferMinSpend(amount)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(AcqOfferButtons)
