import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Chip, Tooltip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { YELLOW, LIGHT_YELLOW, GREEN, LIGHT_GREEN } from '../../Constants/cssColors'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

class MerchantStatusChip extends Component {
  render() {
    const { activeMerchant } = this.props.newMerchant

    let merchantStatus = false

    if (activeMerchant) {
      merchantStatus = activeMerchant.is_active
    }

    if (merchantStatus) {
      return (
        <>
          <Chip
            style={{ backgroundColor: `${LIGHT_GREEN}` }}
            icon={
              <FontAwesomeIcon
                style={{ color: `${GREEN}`, margin: '10 0 10 10' }}
                icon={faCircle}
              />
            }
            label={'LIVE'}
          />
        </>
      )
    } else {
      return (
        <Tooltip title="Thank you for your submission. It is under review by the Buoy Local team. We will respond within 3 business days.">
          <Chip
            style={{ backgroundColor: `${LIGHT_YELLOW}` }}
            icon={
              <FontAwesomeIcon
                style={{ color: `${YELLOW}`, margin: '10 0 10 10' }}
                icon={faCircle}
              />
            }
            label={'Pending Review'}
          />
        </Tooltip>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    newMerchant: state.newMerchant,
  }
}

export default connect(
  mapStateToProps,
  null,
)(MerchantStatusChip)
