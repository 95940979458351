import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { getStyles } from '../Constants/utilityFunctions'
import { AuthActions } from '../Actions/authActions'
import LegalNoticesAccordion from '../Components/UI/LegalNoticesAccordion'
import LightBlueButton from '../Components/UI/LightBlueButton'

class LegalAgreements extends Component {
  componentDidMount = () => {
    this.props.getAllLegalNotices()
  }

  handleBackButtonPress = () => {
    this.props.history.goBack()
  }

  render() {
    const { notices } = this.props.authForm
    const { width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>Agreements</h1>

        <LegalNoticesAccordion notices={notices} styles={styles} />

        <div
          style={{
            margin: '1rem 0',
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-start',
          }}>
          <LightBlueButton variant="contained" size="large" onClick={this.handleBackButtonPress}>
            Back
          </LightBlueButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllLegalNotices: () => dispatch(AuthActions.getAllLegalNotices()),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(LegalAgreements)),
)
