import React from 'react'
import { connect } from 'react-redux'
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faTimes,
  faCheckCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import { amber, green } from '@material-ui/core/colors'

import { NotificationsActions } from '../../Actions/notificationsActions'

const variantIcon = {
  success: faCheckCircle,
  warning: faExclamationTriangle,
  error: faExclamationCircle,
  info: faInfoCircle,
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function MySnackbarContentWrapper(props) {
  const classes = useStyles()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <FontAwesomeIcon className={clsx(classes.icon, classes.iconVariant)} icon={Icon}/>
          {message}
        </span>
      }
      action={[
        <IconButton key='close' aria-label='close' color='inherit' onClick={() => onClose()}>
          <FontAwesomeIcon className={classes.icon} icon={faTimes}/>
        </IconButton>,
      ]}
      {...other}
    />
  )
}

function CustomizedSnackbars(props) {
  const { notifications } = props

  function handleClose() {
    props.closeSnackbar()
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notifications.openSnackbar}
        autoHideDuration={3000}
        onClose={() => handleClose()}
      >
        <MySnackbarContentWrapper
          onClose={() => handleClose()}
          variant={notifications.variant}
          message={notifications.message}
        />
      </Snackbar>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeSnackbar: () => dispatch(NotificationsActions.closeSnackbar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbars)
