import { Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { BLUE } from '../../Constants/cssColors'

export default withStyles({
  root: {
    // width: '100%',
    color: `${BLUE}`,
  },
  indicator: {
    backgroundColor: `${BLUE}`,
  },
})(Tabs)
