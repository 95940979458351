import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'

import { moveToNextPage, getStyles } from '../../Constants/utilityFunctions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { newOfferHash } from '../../Constants/apiHashes'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import AcquisitionOfferPanel from '../../Components/AcquisitionOfferPanel'
import RegularOfferPanel from '../../Components/RegularOfferPanel'

class CreateOffers extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleNextButtonPress = () => {
    const offers = this.props.onboarding.offers
    const merchantId = this.props.newMerchant.merchantId
    const merchantName = this.props.onboarding.merchantName

    this.props.createOffer(newOfferHash(offers.regular, merchantId, merchantName))

    // if (offers.acquisition.amount !== 0) {
    //   this.props.createOffer(newOfferHash(offers.acquisition, merchantId, merchantName))
    // }

    moveToNextPage()
  }

  render() {
    const { width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.pageContainer}>
        <h1 style={styles.title}>Offers</h1>
        <div style={styles.offersSubtitle}>
          Select offers to reward and attract customers. These offers will be automatically applied
          when a customer uses their Buoy Local card and will not require any additional steps from
          the merchant or front line staff. You will be billed monthly for these offers (where
          applicable) and will be able to change these offers through your merchant profile.
        </div>

        <div style={styles.offerPanelContainer}>
          <AcquisitionOfferPanel styles={styles} />
          <RegularOfferPanel styles={styles} />
        </div>

        <div style={{ margin: '2rem 0', alignSelf: 'flex-start' }}>
          <DarkBlueButton variant="contained" size="large" onClick={this.handleNextButtonPress}>
            Next
          </DarkBlueButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createOffer: data => dispatch(MerchantApiActions.createOffer(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withWidth()(CreateOffers))
