import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { MenuItem } from '@material-ui/core'
import { selectMerchant } from '../../Constants/utilityFunctions'

class MerchantsList extends Component {
  handleSwitchMerchantClick = (id) => {
    selectMerchant(id)
    this.props.history.push('/')
  }
  
  render() {
    const { merchants } = this.props

    return (
      <>
        {merchants.map(merchant => (
          <MenuItem key={merchant.merchant_id} onClick={() => this.handleSwitchMerchantClick(merchant.merchant_id)}>
            Switch to {merchant.business_name}
          </MenuItem>
        ))}
      </>
    )
  }
}

export default withRouter(MerchantsList)
