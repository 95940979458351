import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  MenuItem,
  Paper,
  ButtonBase,
  Modal,
  makeStyles,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { CountryRegionData as countries } from 'react-country-region-selector'

import { MerchantApiActions } from '../Actions/merchantApiActions'

import { BLUE } from '../Constants/cssColors'
import Radio from './UI/CustomColorRadio';
import Input from './UI/CssTextField'

const CardsIcon = () => {
  return (
    <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.2257 3.90048L29.1549 3.0351V16.9895L32.9101 7.93382C33.5437 6.35757 32.8174 4.54953 31.2257 3.90048ZM1.09156 9.61824L8.75644 28.085C8.98214 28.6469 9.36714 29.1305 9.86408 29.4765C10.361 29.8224 10.9482 30.0156 11.5535 30.0322C11.9553 30.0322 12.3725 29.9549 12.7743 29.7849L24.1635 25.0716C25.3225 24.5926 26.0333 23.449 26.0642 22.3055C26.0797 21.9037 26.0024 21.4555 25.8633 21.0537L18.1366 2.58695C17.9182 2.02138 17.5346 1.53467 17.0357 1.19014C16.5369 0.845616 15.9458 0.659241 15.3396 0.655273C14.9378 0.655273 14.536 0.747994 14.1497 0.887074L2.77598 5.60036C2.01994 5.90996 1.4178 6.50713 1.10193 7.26057C0.786064 8.01401 0.782333 8.86205 1.09156 9.61824ZM26.0488 3.74595C26.0488 2.92625 25.7232 2.14013 25.1435 1.56051C24.5639 0.980897 23.7778 0.655273 22.9581 0.655273H20.7174L26.0488 13.5434" fill="#1175EB"/>
    </svg>
  );
}

const getRegions = country => {
  if (!country) {
    return []
  }

  return country[2].split('|').map(regionPair => {
    return regionPair.split('~')
  });
}

const useOrderCardsStyles = makeStyles(theme => ({
  baseButton: {
    width: '100%',
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    maxWidth: 796,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cursor: 'pointer',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > h1': {
      marginBottom: 0,
    },
    '& > p': {
      marginLeft: 0,
      marginTop: theme.spacing(1),
      fontWeight: 400,
      color: '#939393',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
    },
  },
  leftContainer: {
    display:  'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 3),
      flexDirection: 'row',
    },
  },
  icon: {
    color: BLUE,
    fontSize: 32,
  },
  iconBackground: {
    backgroundColor: 'rgba(17, 117, 235, 0.05)',
    borderRadius: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
  },
  rightContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display:  'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  chevronRight: {
    color: BLUE,
    fontSize: 18,
    marginRight: theme.spacing(2),
  },
}));

const useOrderCardModalStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    overflow: 'auto',
    display: 'block',
    maxHeight: '75vh',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 12),
      maxHeight: 560,
      maxWidth: 560,
    },
  },
  button: {
    backgroundColor: BLUE,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,122,255, 0.8)',
    },
  },
  link: {
    color: BLUE,
  },
  closeButton: {
    color: BLUE,
    marginLeft: theme.spacing(2),
  },
  formsContainer: {
    marginTop: theme.spacing(3),
  },
  label: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 900,
    fontSize: 32,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
  },
  addLocationLink: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(0,122,255, 0.8)',
    },
    '& > svg': {
      color: BLUE,
      marginRight: theme.spacing(2),
    },
  },
}));

const useLocationLabelStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 18,
    marginLeft: 12,
  },
  locationName: {
    fontSize: 16,
  },
  locationAddress: {
    fontSize: 16,
    color: '#888888',
  },
});

const LocationLabel = ({ location }) => {
  const { address_attributes: address } = location;

  const classes = useLocationLabelStyles();
  const fullAddress = `${address.address_1}${address.address_2 && ' ' + address.address_2}, ${address.city}, ${address.state} ${address.zip}`

  return (
    <div className={classes.container}>
      <span className={classes.locationName}>{location.name}</span>
      <span className={classes.locationAddress}>{fullAddress}</span>
    </div>
  );
};

const OrderCardFirstStep = ({
  locations,
  classes,
  address,
  setAddress,
  quantity,
  setQuantity,
  selectedAddressId,
  selectOrToggleLocation,
  newShippingAddressFormIsOpen,
  toggleNewShippingAddressForm,
}) => {
  const quantityOptions = ['50', '100'];

  return (
    <>
      <Typography className={classes.title}>Order Cards</Typography>
      <Typography className={classes.subtitle}>Order and distribute cards to customers and promote your loyalty program.</Typography>

      <Grid container className={classes.formsContainer}>
        <Grid item xs={12}>
          <Typography className={classes.label}>How many cards do you want to order?</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="quantity" name="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)}>
              {quantityOptions.map((option) => <FormControlLabel key={option} value={option} control={<Radio />} label={`${option} cards`} />)}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {locations.length > 0 && (
        <Grid container className={classes.formsContainer}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Where do you want your cards delivered?</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" disabled={newShippingAddressFormIsOpen}>
              <RadioGroup aria-label="quantity" name="quantity" value={selectedAddressId} onClick={selectOrToggleLocation}>
                {locations.map((option) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id.toString()}
                    control={<Radio />}
                    label={<LocationLabel location={option} />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.formsContainer}>
        <div className={classes.addLocationLink} onClick={toggleNewShippingAddressForm}>
          <FontAwesomeIcon className={classes.chevronRifaPlusght} icon={newShippingAddressFormIsOpen ? faMinus : faPlus} />
          <Typography className={classes.link}>Add a new shipping address</Typography>
        </div>
      </Grid>

      {newShippingAddressFormIsOpen && (
        <>
          <Grid container spacing={2} className={classes.formsContainer}>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                label="Business Address"
                margin="normal"
                variant="outlined"
                value={address.address_1}
                onChange={(e) => setAddress({ ...address, address_1: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                fullWidth
                label="Unit number"
                margin="normal"
                variant="outlined"
                value={address.address_2}
                onChange={(e) => setAddress({ ...address, address_2: e.target.value })}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Input
                fullWidth
                label="City"
                margin="normal"
                variant="outlined"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                fullWidth
                label="Postal / Zip code"
                margin="normal"
                variant="outlined"
                value={address.zip}
                onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                select
                label="Country"
                margin="normal"
                variant="outlined"
                value={address.country}
                onChange={(e) => setAddress({ ...address, country: e.target.value })}
                fullWidth
              >
                {countries.map(option => (
                  <MenuItem key={option[0]} value={option}>
                    {option[0]}
                  </MenuItem>
                ))}
              </Input>
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                select
                label="Province or State"
                margin="normal"
                variant="outlined"
                value={address.state}
                onChange={(e) => setAddress({ ...address, state: e.target.value })}
                fullWidth
              >
                {getRegions(address.country).map(option => (
                  <MenuItem key={option[1]} value={option[1]}>
                    {option[0]}
                  </MenuItem>
                ))}
              </Input>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const OrderCardSecondStep = ({ classes, quantity, fullAddress }) => {
  return (
    <>
      <Typography className={classes.title}>Confirm Card Order</Typography>
      <Typography className={classes.subtitle}>
        Are you sure you want to order <b>{quantity} cards</b> to <b>{fullAddress}</b>?
      </Typography>
    </>
  );
};

const OrderCardThirdStep = ({ classes }) => {
  return (
    <>
      <Typography className={classes.title}>Success, your cards are on their way!</Typography>
      <Typography className={classes.subtitle}>
        Your cards are being prepared and will be shipped within 3-5 business days.
      </Typography>
    </>
  );
};

const OrderCardModal = ({ onClose, locations, createCardsRequest, open = false }) => {
  const classes = useOrderCardModalStyles();

  const [quantity, setQuantity] = useState('50');
  const [newShippingAddressFormIsOpen, setNewShippingAddressFormIsOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [address, setAddress] = useState({});
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const selectOrToggleLocation = e => {
    if (!e.target.value || e.target.value?.toString() === selectedAddressId) {
      setSelectedAddressId(null);
      setAddress({});
    } else {
      const { address_attributes } = locations.find((location) => location.id === parseInt(e.target.value));
      setAddress(address_attributes);
      setSelectedAddressId(e.target.value);
    }
  };

  const closeModal = () => {
    onClose();
    setSelectedAddressId(null);
    setAddress({});
    setStep(0);
    setNewShippingAddressFormIsOpen(false);
  };

  const toggleNewShippingAddressForm = () => {
    if (!newShippingAddressFormIsOpen) {
      setAddress({});
    }
    setSelectedAddressId(null);
    setNewShippingAddressFormIsOpen(!newShippingAddressFormIsOpen);
  };

  const confirmButtonText = () => {
    switch (step) {
      case 1:
        return 'Yes, ship it!';
      case 2:
        return 'Thanks';
      default:
        return 'Next';
    }
  };

  const auxiliaryButtonText = () => {
    switch (step) {
      case 1:
        return 'Go back'
      default:
        return 'Close';
    }
  };

  const onNextStep = async () => {
    switch (step) {
      case 1:
        setIsLoading(true);
        createCardsRequest({
          merchant_id: parseInt(localStorage.getItem('selectedMerchant')),
          number_of_cards: parseInt(quantity),
          address: fullAddress,
        });
        setStep(step + 1);
        setIsLoading(false);
        break;
      case 2:
        closeModal();
        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  const onPrevStep = () => {
    if (step === 1) {
      setStep(step - 1);
    } else {
      closeModal();
    }
  };

  const isNextButtonDisabled = () => {
    const { address_1, city, zip, state, country } = address;

    return !isLoading && address_1 && city && zip && state && country;
  };

  const fullAddress = `${address.address_1}${address.address_2 && ' ' + address.address_2}, ${address.city}, ${address.state} ${address.zip}`;

  return (
    <Modal className={classes.modal} open={open} onClose={closeModal}>
      <Paper className={classes.paper}>
        {step === 0 && (
          <OrderCardFirstStep
            locations={locations}
            classes={classes}
            address={address}
            setAddress={setAddress}
            quantity={quantity}
            setQuantity={setQuantity}
            selectedAddressId={selectedAddressId}
            selectOrToggleLocation={selectOrToggleLocation}
            setNewShippingAddressFormIsOpen={setNewShippingAddressFormIsOpen}
            newShippingAddressFormIsOpen={newShippingAddressFormIsOpen}
            toggleNewShippingAddressForm={toggleNewShippingAddressForm}
          />
        )}
        {step === 1 && <OrderCardSecondStep classes={classes} fullAddress={fullAddress} quantity={quantity} />}
        {step === 2 && <OrderCardThirdStep classes={classes} />}
        <Grid container className={classes.formsContainer}>
          <Button className={classes.button} variant="contained" color="primary" disabled={!isNextButtonDisabled()} onClick={onNextStep}>{confirmButtonText()}</Button>
          {step < 2 && <Button className={classes.closeButton} color="primary" disabled={isLoading} onClick={onPrevStep}>{auxiliaryButtonText()}</Button>}
        </Grid>
      </Paper>
    </Modal>
  );
};

const OrderCards = ({ onboarding, ...props }) => {
  const classes = useOrderCardsStyles();
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OrderCardModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        locations={onboarding.locations}
        {...props}
      />

      <ButtonBase className={classes.baseButton} onClick={() => setModalOpen(true)}>
        <Paper className={classes.paper}>
          <div className={classes.leftContainer}>
            <div className={classes.iconBackground}>
              <CardsIcon className={classes.icon} />
            </div>

            <div className={classes.text}>
              <h1>Order Cards</h1>
              <p>Distribute cards at your locations to help promote your loyalty program.</p>
            </div>
          </div>
          <div className={classes.rightContainer}>
            <FontAwesomeIcon className={classes.chevronRight} icon={faChevronRight} />
          </div>
        </Paper>
      </ButtonBase>
    </>
  );
};

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createCardsRequest: payload => dispatch(MerchantApiActions.createCardsRequest(payload)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderCards);
