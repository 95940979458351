import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faGlobeAmericas, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'

import styles from '../Styles/stylesDesktop'
import { BLUE } from '../Constants/cssColors'

class LocationPreviewPanel extends Component {
  state = {
    bannerImage: this.props.bannerImage,
    logoImage: this.props.logoImage,
  }

  static getDerivedStateFromProps(props, state) {
    if(props.bannerImage !== state.bannerImage && props.logoImage !== state.logoImage) {
      return {
        bannerImage: props.bannerImage,
        logoImage: props.logoImage,
      }
    }

    if(props.bannerImage !== state.bannerImage) {
      return {
        bannerImage: props.bannerImage
      }
    }

    if(props.logoImage !== state.logoImage) {
      return {
        logoImage: props.logoImage
      }
    }

    return null
  }

  render() {
    const { onboarding } = this.props
    const { locationDetails } = onboarding

    return (
      <div>
        <div style={styles.sidepanelTitle}>PROFILE PREVIEW</div>

        <Paper square elevation={2} style={styles.sidepanel}>
          <img alt='' src={this.state.bannerImage} style={styles.bannerImage} />

          <img alt='' src={this.state.logoImage} style={styles.logoImage}/>

          <div style={styles.merchantName}>
            {onboarding.locationAddress.name}
          </div>

          <div style={styles.aboutMerchantSection}>
            {locationDetails.description ? locationDetails.description : 'Your description goes here...'}
          </div>

          <div style={styles.contactInfoContainer}>
            <div style={styles.contactSection}>
              <FontAwesomeIcon style={{ ...styles.fontAwesomeIcon, color: `${BLUE}` }} icon={faPhoneAlt}/>
              <Typography noWrap style={styles.contactInfo}>
                { onboarding.googlePlaceDetails.phone ? onboarding.googlePlaceDetails.phone : 'Phone' }
              </Typography>
            </div>

            <div style={styles.contactSection}>
              <FontAwesomeIcon style={{ ...styles.fontAwesomeIcon, color: `${BLUE}` }} icon={faGlobeAmericas}/>
              <Typography noWrap style={styles.contactInfo}>
                { locationDetails.website_link ? locationDetails.website_link : 'Website' }
              </Typography>
            </div>

            <div style={styles.contactSection}>
              <FontAwesomeIcon style={{ ...styles.fontAwesomeIcon, color: `${BLUE}` }} icon={faFacebookSquare}/>
              <Typography noWrap style={styles.contactInfo}>
                { locationDetails.facebook_link ? locationDetails.facebook_link : 'Facebook' }
              </Typography>
            </div>

            <div style={styles.contactSection}>
              <FontAwesomeIcon style={{ ...styles.fontAwesomeIcon, color: `${BLUE}` }} icon={faInstagram}/>
              <div style={styles.handlePrefix}>@</div>
              <Typography noWrap style={{ ...styles.contactInfo, marginLeft: '0.5vw' }}>
                { locationDetails.instagram_link ? locationDetails.instagram_link : 'Instagram' }
              </Typography>
            </div>

            <div style={styles.contactSection}>
              <FontAwesomeIcon style={{ ...styles.fontAwesomeIcon, color: `${BLUE}` }} icon={faShoppingBag}/>
              <Typography noWrap style={styles.contactInfo}>
                { locationDetails.online_ordering_link ? locationDetails.online_ordering_link : 'Online Orderling' }
              </Typography>
            </div>
          </div>

        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

export default connect(mapStateToProps, null)(LocationPreviewPanel)
