import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { getStyles, validateEmail, validatePhone } from '../Constants/utilityFunctions'
import CustomColorPhoneInput from '../Components/UI/CustomColorPhoneInput'
import DarkBlueButton from '../Components/UI/DarkBlueButton'
import CssTextField from '../Components/UI/CssTextField'
import { AuthActions } from '../Actions/authActions'
import { newUserHash } from '../Constants/apiHashes'
import bsb from '../images/buoy-local-logo-empowering.png'

class SignupForm extends Component {
  state = {
    email: false,
    phone: false,
    name: false,
    password: false,
    confirmPassword: false,
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handlePhoneInput = value => {
    this.props.addUserPhone(value)
  }

  validateForm = () => {
    let form = this.props.authForm
    let passwordConfirmError = true

    this.setState({
      email: validateEmail(form.userEmail),
      phone: validatePhone(form.userPhone),
      password: form.userPassword === '',
      name: form.userName === '',
      confirmPassword: form.confirmPassword === '',
    })

    if (form.userPassword === form.confirmPassword) {
      passwordConfirmError = false
    } else {
      this.setState({
        password: true,
        confirmPassword: true,
      })
    }

    let formErrors =
      validateEmail(form.userEmail) ||
      validatePhone(form.userPhone) ||
      form.userName === '' ||
      form.userPassword === '' ||
      form.confirmPassword === '' ||
      passwordConfirmError

    return formErrors
  }

  catchReturn(event) {
    if (event.key === 'Enter') {
      this.handleSignupClick()
    }
  }

  handleSignupClick() {
    let form = this.props.authForm

    if (!this.validateForm()) {
      this.props.auth.signup(newUserHash(form))
    }
  }

  handleLogInClick() {
    this.props.history.push('/')
  }

  render() {
    const { authForm, width } = this.props
    let styles = getStyles(width)

    return (
      <div style={styles.loginPageContainer}>
        <div style={styles.pageContainer}>
          <div style={styles.loginContainer}>
            <img src={bsb} alt="" style={styles.buoyLogo} />
            <div style={styles.loginSignupTitle}>Welcome to Buoy Local</div>
            <div style={{ fontSize: '1rem', textAlign: 'center', margin: '2vw 0' }}>
              Suppporting local businesses one transaction at a time
            </div>

            <CssTextField
              error={this.state.name}
              margin="normal"
              variant="outlined"
              label="Business Owner Name"
              style={styles.textFieldMediumWidth}
              value={authForm.userName}
              onChange={this.handleChange('addUserName')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <CssTextField
              error={this.state.email}
              margin="normal"
              variant="outlined"
              label="Email"
              type="email"
              style={styles.textFieldMediumWidth}
              value={authForm.userEmail}
              onChange={this.handleChange('addUserEmail')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <CustomColorPhoneInput
              error={this.state.phone}
              margin="normal"
              variant="outlined"
              label="Phone"
              defaultCountry="us"
              style={styles.textFieldMediumWidth}
              value={authForm.userPhone}
              onChange={value => this.handlePhoneInput(value)}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <CssTextField
              error={this.state.password}
              margin="normal"
              variant="outlined"
              label="Password"
              type="password"
              style={styles.textFieldMediumWidth}
              value={authForm.userPassword}
              onChange={this.handleChange('addUserPassword')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <CssTextField
              error={this.state.confirmPassword}
              margin="normal"
              variant="outlined"
              label="Confirm Password"
              type="password"
              style={styles.textFieldMediumWidth}
              value={authForm.confirmPassword}
              onChange={this.handleChange('confirmUserPassword')}
              onKeyPress={ev => this.catchReturn(ev)}
            />

            <br />

            <DarkBlueButton
              size="large"
              variant="contained"
              style={styles.loginButton}
              onClick={() => this.handleSignupClick()}>
              Signup
            </DarkBlueButton>

            <div style={styles.signUpOrLogIn} onClick={() => this.handleLogInClick()}>
              or Log In
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authForm: state.authForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addUserName: name => dispatch(AuthActions.addUserName(name)),
    addUserEmail: email => dispatch(AuthActions.addUserEmail(email)),
    addUserPhone: phone => dispatch(AuthActions.addUserPhone(phone)),
    addUserPassword: password => dispatch(AuthActions.addUserPassword(password)),
    confirmUserPassword: password => dispatch(AuthActions.confirmUserPassword(password)),
    signUpUser: userData => dispatch(AuthActions.signUpUser(userData)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(SignupForm)),
)
