import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper, CircularProgress } from '@material-ui/core'

import { MerchantApiActions } from '../../Actions/merchantApiActions'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { moveToNextPage, getStyles, getLocationAddress } from '../../Constants/utilityFunctions'
import bsb from '../../images/buoy-local-logo-empowering.png'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import VerifyTerminalsHeader from '../../Components/VerifyTerminalsHeader'

class VerifyTerminal extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.runVirtualCardGenerator()
  }

  runVirtualCardGenerator = () => {
    let merchantId = this.props.newMerchant.merchantId
    this.props.getLocationAndGenerateVirtualCard(merchantId)
  }

  handleNextButtonPress = () => {
    // Prevents the activation of the virtual card modal (used on edit location page)
    if (this.props.newMerchant.virtualCardModal) {
      this.props.closeVirtualCardModal()
    }

    moveToNextPage()
  }

  render() {
    const { onboarding, width, newMerchant } = this.props
    let styles = getStyles(width)
    const mobileWidths = ['xs', 'sm']
    const isMobile = mobileWidths.includes(width)
            
    return (
      <div style={{ ...styles.pageContainer, alignItems: 'flex-start' }}>
        <img src={bsb} alt='' style={styles.buoyLogoAwaitingPage} />

        <h1 style={styles.title}>Verify your terminals</h1>

        <div style={styles.subtitle}>
          <p>Follow the steps below to verify your terminals and enable them to accept Buoy Local transactions</p>

          <Paper style={styles.verifyTerminalPageContainer}>
            {onboarding.locations.length > 0 ? (
              <>
                <VerifyTerminalsHeader
                  isMobile={isMobile}
                  styles={styles} 
                  locationAddress={getLocationAddress(onboarding.locations[0])}
                  locationName={onboarding.locations[0].name}
                />

                <div style={styles.verificationCardSection}>
                  {newMerchant.virtualCardUrl ? (
                    <iframe scrolling='no' alt='' src={newMerchant.virtualCardUrl} style={styles.verificationCardIframe} />
                  ) : (
                    <CircularProgress />
                  )}

                  <div>
                    <div style={styles.yourVerificationCard}>YOUR VERIFICATION CARD</div>
                    <div style={styles.yourVerificationCardSubtitle}>
                      This card is to be used to verify the terminals at your location
                    </div>
                    <a style={styles.generateNewCardLink} onClick={() => this.runVirtualCardGenerator()}>
                      Generate a new card
                    </a>
                  </div>
                </div>

                <div>
                  <ol style={styles.verifyTerminalInstructionsList}>
                    <li>Run a “Card Not Present” transaction for $1.11 and select “Visa” or “Credit” when prompted</li>
                    <li>Enter the card number, expiry and CVV of your verification card (see above)</li>
                    <li>If prompted, enter your business address</li>
                    <li>The transaction will decline (as expected)</li>
                    <li>Repeat steps 1-4 on all the terminals at your location</li>
                  </ol>
                </div>
              </>
            ) : ( 
              <CircularProgress />
            )}
          </Paper>
        </div>

        <div style={styles.detailsContainer}>
          <div style={{ margin: '1rem 0', display: 'flex' }}>
            <DarkBlueButton variant="contained" size="large" onClick={this.handleNextButtonPress}>
              Next
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createVirtualCard: payload => dispatch(MerchantApiActions.createVirtualCard(payload)),
    closeVirtualCardModal: () => dispatch(MerchantOnboardingActions.closeVirtualCardModal()),
    getLocationAndGenerateVirtualCard: merchantId => dispatch(MerchantApiActions.getLocationAndGenerateVirtualCard(merchantId)),
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(VerifyTerminal)),
)
