import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'

import AcqOfferButtons from './AcqOfferButtons'
import { getOfferType } from '../Constants/utilityFunctions'
import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import acquisitionOfferImage from '../images/acquisition-offer.png'

class AcquisitionOfferPanel extends Component {
  componentDidMount = () => {
    let merchantOffers = this.props.onboarding.merchantOffers

    for (let offer of merchantOffers) {
      if (getOfferType(offer) === 'acquisition') {
        this.props.setAcqOfferAmount(parseInt(offer.amount))
        this.props.setAcqOfferId(offer.id)

        for (let offerTag of offer.offer_tags_attributes) {
          if (offerTag.tag === 'minimum_spent') {
            this.props.setAcqOfferMinSpend(offerTag.value)
          }
        }
      }
    }
  }

  render() {
    const { offers } = this.props.onboarding
    const { styles } = this.props

    return (
      <Paper style={{...styles.offerContainer, opacity: 0.3, pointerEvents: 'none' }}>
        <img src={acquisitionOfferImage} alt="" style={styles.offerLogo} />
        <div style={{ ...styles.remainingActionsEntry, margin: '0' }}>
          <div style={styles.offerPanelTitle}>Acquisition Offer</div>
        </div>
        <div style={styles.offerPanelSubtitle}>Set a one-time use offer for new customers</div>

        <AcqOfferButtons offer={offers.acquisition} styles={styles} />
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setAcqOfferId: id => dispatch(MerchantOnboardingActions.setAcqOfferId(id)),
    setAcqOfferAmount: amount => dispatch(MerchantOnboardingActions.setAcqOfferAmount(amount)),
    setAcqOfferMinSpend: amount => dispatch(MerchantOnboardingActions.setAcqOfferMinSpend(amount)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcquisitionOfferPanel)
