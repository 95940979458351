{
  "name": "mrewards_onboarding_portal",
  "version": "3.78",
  "private": true,
  "dependencies": {
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-brands-svg-icons": "^6.6.0",
    "@fortawesome/free-regular-svg-icons": "^6.6.0",
    "@fortawesome/free-solid-svg-icons": "^6.6.0",
    "@fortawesome/react-fontawesome": "^0.2.2",
    "@material-ui/core": "^4.12.3",
    "@material-ui/lab": "^4.0.0-alpha.61",
    "@nivo/bar": "^0.87",
    "@nivo/core": "^0.87",
    "@nivo/line": "^0.87",
    "@react-google-maps/api": "^2.19.3",
    "@rollbar/react": "^0.11.1",
    "dotenv": "^16.4.5",
    "eslint-plugin-react": "^7.34.4",
    "express": "^4.19.2",
    "google-map-react": "^2.2.1",
    "husky": "^9.0.11",
    "lodash": "^4.17.21",
    "material-ui-phone-number": "^2.2.6",
    "moment": "^2.30.1",
    "prettier": "^3.3.3",
    "react": "^18.3.1",
    "react-country-region-selector": "^3.2.0",
    "react-dom": "^18.3.1",
    "react-dropzone": "^14.2.3",
    "react-google-map-image": "^1.0.3",
    "react-intl": "^6.6.8",
    "react-redux": "^9.1.2",
    "react-router-dom": "5.3.3",
    "redux": "^4.2.1",
    "redux-persist": "^6.0.0",
    "redux-saga": "^1.3.0",
    "rollbar": "^2.26.1",
    "sanitize-html": "^2.13.0",
    "typeface-source-sans-pro": "^1.1.13"
  },
  "resolutions": {
    "d3-color": "^3.1.0"
  },
  "engines": {
    "node": "22.4.1"
  },
  "scripts": {
    "start": "parcel src/index.html",
    "prebuild": "rm -rf dist/",
    "build": "parcel build src/index.html",
    "test": "chmod u+x ./test.sh & ./test.sh",
    "pretty": "prettier --config .prettierrc --write",
    "precommit": "lint-staged",
    "lint": "eslint --max-warnings 0"
  },
  "husky": {
    "hooks": {
      "pre-commit": "yarn precommit"
    }
  },
  "lint-staged": {
    "{src}/**/*.{js,jsx}": [
      "yarn run pretty",
      "yarn run lint",
      "git add"
    ]
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@babel/preset-env": "^7.24.8",
    "@babel/preset-react": "^7.24.7",
    "@parcel/packager-raw-url": "2.12.x",
    "@parcel/transformer-webmanifest": "2.12.x",
    "babel-jest": "^29.7.0",
    "buffer": "^5.5.0||^6.0.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "lint-staged": "^15.2.7",
    "parcel": "2.12.0",
    "path-browserify": "^1.0.1",
    "process": "^0.11.10",
    "react-test-renderer": "^18.3.1",
    "redux-devtools-extension": "^2.13.9"
  }
}
