import store from '../Store'
import history from '../history'
import * as moment from 'moment/moment.js'
import _ from 'lodash'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../Actions/merchantApiActions'
import { merchantTransactionsHash, locationTransactionsHash } from '../Constants/apiHashes'
import desktopStyles from '../Styles/stylesDesktop'
import mobileStyles from '../Styles/stylesMobile'

export function getLocationAddress(location) {
  let attr = location.address_attributes
  let address = attr.address_1 + ', '

  address += attr.city + ', ' + attr.state + ', ' + attr.country + ', ' + attr.zip

  return address
}

export function getOfferType(offer) {
  if (offer.offer_type === 'N number of points') {
    return 'acquisition'
  } else {
    return 'regular'
  }
}

export function selectMerchant(id) {
  localStorage.setItem('selectedMerchant', id)
}

export function parseQueryString(query) {
  let query_params = {}

  query = query.replace('&amp;', '&')
  query = decodeURIComponent(query)
  query = query.split('&')

  for (let item of query) {
    let pair = item.split('=')
    query_params[pair[0]] = pair[1]
  }

  return query_params
}

export function moveToNextPage() {
  let currentPage = localStorage.getItem('onboardingPage')

  if (currentPage === 'merchantAccountPage') {
    localStorage.setItem('onboardingPage', 'setUpOffersPage')
    history.push('/new-merchant')
  } else if (currentPage === 'setUpOffersPage') {
    localStorage.setItem('onboardingPage', 'addLocationsPage')
    history.push('/new-merchant')
  } else if (currentPage === 'addLocationsPage') {
    localStorage.setItem('onboardingPage', 'verifyTerminalPage')
    history.push('/new-merchant')
  } else if (currentPage === 'verifyTerminalPage') {
    localStorage.setItem('onboardingPage', 'billingDetailsPage')
    history.push('/new-merchant')
  } else if (currentPage === 'billingDetailsPage') {
    localStorage.setItem('onboardingPage', 'awaitingReviewPage')
    history.push('/new-merchant')
  } else if (currentPage === 'awaitingReviewPage') {
    localStorage.removeItem('onboardingPage')
    history.push('/')
  } else {
    history.push('/')
  }
}

export function getLocationLatLong(locationAddress) {
  return new Promise(function(resolve, reject) {
    const geocoder = new window.google.maps.Geocoder()
    let address = locationAddress.streetAddress1 + ', '
    address += locationAddress.city + ', '
    address += locationAddress.state + ', '
    address += locationAddress.country[0]

    geocoder.geocode({ address: address }, function(results, status) {
      if (status === 'OK') {
        store.dispatch(
          MerchantOnboardingActions.addLocationLatitude(results[0].geometry.location.lat()),
        )
        store.dispatch(
          MerchantOnboardingActions.addLocationLongitude(results[0].geometry.location.lng()),
        )

        resolve()
      } else {
        reject('Failed to retrieve latitude and longitude based on your address')
      }
    })
  })
}

export function formatSocialMediaLinks(link) {
  let response = ''
  let instagramRegExp = RegExp(/(http(s)?:\/\/?)?:?(www\.)?instagram\.com\//)
  let twitterRegExp = RegExp(/(http(s)?:\/\/?)?:?(www\.)?twitter\.com\//)
  let facebookRegExp = RegExp(/(http(s)?:\/\/?)?:?(www\.)?facebook\.com\//)

  if (instagramRegExp.test(link)) {
    response = link.replace(instagramRegExp, '')
  }

  if (twitterRegExp.test(link)) {
    response = link.replace(twitterRegExp, '')
  }

  if (facebookRegExp.test(link)) {
    response = link.replace(facebookRegExp, '')
    response = 'facebook.com/' + response
  }

  return response
}

export function getStyles(width) {
  const mobileWidths = ['xs', 'sm']

  if (mobileWidths.includes(width)) {
    return mobileStyles
  } else {
    return desktopStyles
  }
}

export function missingLocationFields(locations) {
  let missingFields = false

  locations.forEach(location => {
    if (
      !location.description ||
      !location.logo ||
      !location.banner
    ) {
      missingFields = true
    }
  })

  return missingFields
}

// Used in the location filter on the reporting page
export function getLocationId(locationName, locationsFilter) {
  // Extract the location id
  let [locationId] = locationsFilter
    .map(filter => {
      if (filter.value === locationName) {
        return filter.id
      } else {
        return null
      }
    })
    .filter(Boolean)

  return locationId
}

// Used in formatting data on reporting graphs
export function getMonthAndYear(yearMonth) {
  yearMonth = yearMonth.split(/\D/).filter(Boolean)
  let monthAndYear = ''
  const monthNames = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]

  monthAndYear += monthNames[yearMonth[1]]
  monthAndYear += ', ' + yearMonth[0]
  return monthAndYear
}

export function getDateRange(range) {
  let now = moment()
    .utc()
    .format()
  let from

  switch (range) {
    case 'Last 12 months':
      from = moment()
        .utc()
        .subtract(1, 'years')
        .format()
      break
    case 'Last 3 months':
      from = moment()
        .utc()
        .subtract(3, 'months')
        .format()
      break
    case 'Last month':
      from = moment()
        .utc()
        .subtract(1, 'months')
        .format()
      break
    case 'Last 2 weeks':
      from = moment()
        .utc()
        .subtract(2, 'weeks')
        .format()
      break
    case 'Last week':
      from = moment()
        .utc()
        .subtract(1, 'weeks')
        .format()
      break
    case 'Month to date':
      from = moment()
        .startOf('month')
        .utc()
        .format()
      break
    case 'Last month to date':
      from = moment()
        .startOf('month')
        .utc()
        .subtract(1, 'months')
        .format()
      now = moment()
        .utc()
        .subtract(1, 'months')
        .format()
      break
    default:
      break
  }

  return { from: from, to: now }
}

export function loadReports(locationId, merchantId, dateRange) {
  if (locationId === 'all') {
    store.dispatch(MerchantApiActions.getAverageTicketValueReport({ merchant_id: merchantId, ...dateRange }))
    store.dispatch(MerchantApiActions.getAllTimeTransactionsReport({ merchant_id: merchantId, ...dateRange }))
    store.dispatch(MerchantApiActions.getTotalPointsIssuedReport({ merchant_id: merchantId, ...dateRange }))
    store.dispatch(MerchantApiActions.getTotalSpentReport({ merchant_id: merchantId, ...dateRange }))
    store.dispatch(MerchantApiActions.getFirstTimeCustomersReport({ merchant_id: merchantId, ...dateRange }))
  } else {
    store.dispatch(MerchantApiActions.getAverageTicketValueReport({ location_id: locationId, ...dateRange }))
    store.dispatch(MerchantApiActions.getAllTimeTransactionsReport({ location_id: locationId, ...dateRange }))
    store.dispatch(MerchantApiActions.getTotalPointsIssuedReport({ location_id: locationId, ...dateRange }))
    store.dispatch(MerchantApiActions.getTotalSpentReport({ location_id: locationId, ...dateRange }))
    store.dispatch(MerchantApiActions.getLocationTransactions(locationTransactionsHash(locationId, dateRange)))
    store.dispatch(MerchantApiActions.getFirstTimeCustomersReport({ location_id: locationId, ...dateRange }))
  }
}

export function loadMerchantTransactions(merchantId, dateRange) {
    return function(page, rowsPerPage) {
        let params = merchantTransactionsHash(merchantId, dateRange, page, rowsPerPage)
        store.dispatch(MerchantApiActions.getMerchantTransactions(params))
    }
}

export function loadHomePageReports(merchantId) {
  let monthToDateRange = getDateRange('Month to date')

  store.dispatch(MerchantApiActions.getMonthToDateTransactionsReport({ merchant_id: merchantId, ...monthToDateRange }))
  store.dispatch(MerchantApiActions.getMonthToDateSpentReport({ merchant_id: merchantId, ...monthToDateRange }))
  store.dispatch(MerchantApiActions.getMonthToDateFirstTimeCustomersReport({ merchant_id: merchantId, ...monthToDateRange }))
}

// The following two functions are not currently used but keeping for now in case we'll need to return to this logic
// export function reportsNotLoaded(newMerchant, onboarding) {
//   // Returns true if the reporting data is missing, allowing the triggering of reporting API calls for the Reporting tab
//   if (newMerchant && onboarding) {
//     return _.isEmpty(newMerchant.avgTicketValueReport) && _.isEmpty(newMerchant.allTimeTransactionsReport)
//       && _.isEmpty(newMerchant.totalPointsIssuedReport) && _.isEmpty(newMerchant.totalSpentReport)
//       && _.isEmpty(onboarding.transactions) && _.isEmpty(newMerchant.firstTimeCustomers)
//   }
// }

// export function homePageReportsNotLoaded(newMerchant) {
//   // Returns true if the reporting data is missing, allowing the triggering of reporting API calls for the home page
//   if (newMerchant) {
//     return _.isEmpty(newMerchant.monthToDateTransactionsReport)
//       && _.isEmpty(newMerchant.monthToDateSpentReport)
//       && _.isEmpty(newMerchant.monthToDateFirstTimeCustomers)
//   }
// }

export function refreshKPIReporting(newMerchant, onboarding) {
  let dateRange = getDateRange(onboarding.selectedDateRange)
  let merchantId = newMerchant.merchantId
  let locationId = getLocationId(
    onboarding.selectedLocation,
    onboarding.locationsFilter,
  )

  loadReports(locationId, merchantId, dateRange)
  loadMerchantTransactions(merchantId, dateRange)()
}

export function validateEmail(email) {
  email = email.trim()
  let regex = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

  return email === '' || !regex.test(email)
}

export function validatePhone(phone) {
  phone = phone.toString().trim()

  return phone === '' || phone.match(/\d/g).length < 11
}

export function maskLast4(input) {
  if (input === "" || input === undefined || input === null) return ""

  return input.slice(0, -4).replace(/./g, '*') + input.slice(-4);
}
