import { HOVER_BLUE } from '../../Constants/cssColors'
import { getMonthAndYear } from '../../Constants/utilityFunctions'

export default function getTotalSpent(data) {
  let formattedData = [
    {
      id: 'totalSpent',
      color: `${HOVER_BLUE}`,
      data: [],
    },
  ]

  if (data.total_spent_per_date_range != null) {
    Object.entries(data.total_spent_per_date_range).forEach(entry => {
      formattedData[0].data.unshift({
        x: getMonthAndYear(entry[0]),
        y: (entry[1] / 100).toFixed(2),
      })
    })
  }

  return formattedData
}
