import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ButtonBase, MenuItem, withWidth } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import uploadImage from '../../images/upload-icon.png'
import AlertDialog from '../../Components/AlertDialog'

import { getStyles, validateEmail, validatePhone } from '../../Constants/utilityFunctions'
import { updateMerchantHash } from '../../Constants/apiHashes'
import { MerchantOnboardingActions } from '../../Actions/merchantOnboardingActions'
import { MerchantApiActions } from '../../Actions/merchantApiActions'
import CustomColorPhoneInput from '../../Components/UI/CustomColorPhoneInput'
import DarkBlueButton from '../../Components/UI/DarkBlueButton'
import LightBlueButton from '../../Components/UI/LightBlueButton'
import CssTextField from '../../Components/UI/CssTextField'
import BankingInfoForm from '../../Components/BankingInfoForm'
import ShippingAddressForm from '../../Components/ShippingAddressForm'
import BillingAddressForm from '../../Components/BillingAddressForm'
import imgToBase64 from '../../Components/base64'

class MerchantEditPage extends Component {
  state = {
    bannerImage: uploadImage,
    logoImage: uploadImage,
    logoImageError: false,
    bannerImageError: false,
    previewBanner: null,
    previewLogo: null,
    name: false,
    category: false,
    email: false,
    phone: false,
  }
  
  accountVerificationDate = this.props.newMerchant.activeMerchant.account_verification_date
  
  componentDidMount = () => {
    window.scrollTo(0, 0)
    const { activeMerchant } = this.props.newMerchant

    if (activeMerchant) {
      let merchantName = activeMerchant.business_name
      let shippingAddress = activeMerchant.shipping_address_attributes
      let billingAddress = activeMerchant.billing_address_attributes
      let achBankDetails = activeMerchant.ach_bank_detail_attributes
      let phone = activeMerchant.phone
      let email = activeMerchant.email
      let category = activeMerchant.tag_type
      let logo = activeMerchant.logo
      let banner = activeMerchant.banner

      this.props.clearBillingAddressForm('')

      if (merchantName) this.props.addMerchantName(merchantName)
      if (shippingAddress) this.props.populateShippingAddress(shippingAddress)
      if (billingAddress) this.props.populateBillingAddress(billingAddress)
      if (phone) this.props.addMerchantPhone(phone)
      if (email) this.props.addMerchantEmail(email)

      if (typeof achBankDetails === 'object' && achBankDetails !== null) {
        if ('account_number' in achBankDetails) this.props.addAccountNumber(achBankDetails.account_number)
        if ('routing_number' in achBankDetails) this.props.addRoutingNumber(achBankDetails.routing_number)
        if ('depository_name' in achBankDetails) this.props.addDepositoryName(achBankDetails.depository_name)
        if ('tax_identification_number' in achBankDetails) {
            this.props.addTaxIdNumber(achBankDetails.tax_identification_number)
        }
      } else {
        this.clearBankingInfo()
      }

      this.props.fetchMerchantCategories()

      if (category) this.props.addMerchantCategory(category)

      if (logo) {
        this.setState({ logoImage: logo, previewLogo: logo })
      }

      if (banner) {
        this.setState({ bannerImage: banner, previewBanner: banner })
      }
    }
  }

  handlePhoneInput = value => {
    this.props.addMerchantPhone(value)
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  handleLogoUploadClick = acceptedFiles => {
    let logoImageSize = acceptedFiles[0].size / 1000

    if (logoImageSize > 400) {
      this.setState({ logoImageError: true })
    } else {
      let logoImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewLogo: logoImage, logoImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addLogoImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  handleBannerUploadClick = acceptedFiles => {
    let bannerImageSize = acceptedFiles[0].size / 1000

    if (bannerImageSize > 800) {
      this.setState({ bannerImageError: true })
    } else {
      let bannerImage = URL.createObjectURL(acceptedFiles[0])
      this.setState({ previewBanner: bannerImage, bannerImageError: false })

      imgToBase64(acceptedFiles[0])
        .then(response => {
          this.props.addBannerImage(response)
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }
  
  clearBankingInfo = () => {
    this.props.addAccountNumber('')
    this.props.addRoutingNumber('')
    this.props.addDepositoryName('')
    this.props.addTaxIdNumber('')
  }

  handleBackButtonPress = () => {
    this.clearBankingInfo()

    this.props.history.goBack()
  }

  validateForm = () => {
    const form = this.props.onboarding

    this.setState({
      name: form.merchantName === '',
      category: form.merchantCategory === '',
      email: validateEmail(form.merchantEmail),
      phone: validatePhone(form.merchantPhone),
    })

    let shippingAddressFormErrors = this.validateShippingAddressForm()
    let bankingInfoFormErrors = this.validateBankingInfoForm()
    let validateBillingAddressForm = this.validateBillingAddressForm()
    let merchantFormErrors =
      form.merchantName === '' ||
      form.merchantCategory === '' ||
      validateEmail(form.merchantEmail) ||
      validatePhone(form.merchantPhone)

    return validateBillingAddressForm || bankingInfoFormErrors || shippingAddressFormErrors || merchantFormErrors
  }

  handleSaveButtonPress = () => {
    const form = this.props.onboarding
    const newMerchant = this.props.newMerchant

    if (!this.validateForm()) {
      if (newMerchant.merchantId) {
        this.props.updateMerchant(updateMerchantHash(form, newMerchant))
      }
    }
  }

  render() {
    const { onboarding, width } = this.props
    let styles = getStyles(width)

    return (
      <div>
        <AlertDialog open={!this.accountVerificationDate} />
        <div style={styles.pageContainer}>
          <h1 style={styles.title}>Edit Merchant</h1>
          <div style={styles.subtitle}>
            Edit your merchant profile that can be seen by interested customers
          </div>
        </div>

        <div style={styles.pageContainer}>
          <div style={styles.detailsContainer}>
            <CssTextField
              label="Merchant Name"
              margin="normal"
              error={this.state.name}
              variant="outlined"
              style={styles.textFieldFullWidth}
              value={onboarding.merchantName}
              onChange={this.handleChange('addMerchantName')}
            />

            <h4>Shipping Address</h4>
            <ShippingAddressForm
              styles={styles}
              callValidateForm={shippingAddressValidation =>
                (this.validateShippingAddressForm = shippingAddressValidation)
              }
            />
          </div>

          <br />

          <div style={styles.merchantInputFieldRow}>
            <CustomColorPhoneInput
              label="Phone number"
              margin="normal"
              error={this.state.phone}
              variant="outlined"
              defaultCountry="us"
              style={styles.textFieldHalfWidth}
              value={onboarding.merchantPhone}
              onChange={value => this.handlePhoneInput(value)}
            />

            <div style={{ padding: '0 8px' }} />

            <CssTextField
              label="Merchant Email"
              margin="normal"
              error={this.state.email}
              type="email"
              variant="outlined"
              style={styles.textFieldHalfWidth}
              value={onboarding.merchantEmail}
              onChange={this.handleChange('addMerchantEmail')}
            />
          </div>

          <div style={styles.merchantInputFieldRow}>
            <CssTextField
              select
              label="Category"
              margin="normal"
              error={this.state.category}
              variant="outlined"
              style={styles.textFieldHalfWidth}
              value={onboarding.merchantCategory}
              onChange={this.handleChange('addMerchantCategory')}>
              {onboarding.tagTypes.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CssTextField>
          </div>

          <div style={styles.buttonImageRow}>
            <div style={styles.columnContainer}>
              <div style={styles.imageButtonTitle}>List Image</div>

              <Dropzone
                onDropAccepted={acceptedFiles => this.handleLogoUploadClick(acceptedFiles)}
                accept={'image/jpeg, image/png'}>
                {({ getRootProps, getInputProps }) => (
                  <ButtonBase>
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div style={styles.buttonContainer}>
                          {!this.state.previewLogo ? (
                            <img src={this.state.logoImage} alt="" style={styles.uploadImageIcon} />
                          ) : (
                            <img src={this.state.previewLogo} alt="" style={styles.buttonIcon} />
                          )}
                        </div>
                      </div>
                    </section>
                  </ButtonBase>
                )}
              </Dropzone>

              {this.state.logoImageError ? (
                <div style={styles.imageUploadError}>Error: the image cannot exceed 400Kb</div>
              ) : null}

              <div style={styles.imageButtonInfoText}>
                This image will appear in offer lists and marketing to consumers. Preferred
                dimensions 400 x 400. Max size 400 kb. Accepted formats PNG, JPG.
              </div>
            </div>

            <div style={{ margin: '0 8px' }} />

            <div style={styles.columnContainer}>
              <div style={styles.imageButtonTitle}>Banner Image</div>

              <Dropzone
                onDropAccepted={acceptedFiles => this.handleBannerUploadClick(acceptedFiles)}
                accept={'image/jpeg, image/png'}>
                {({ getRootProps, getInputProps }) => (
                  <ButtonBase>
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div style={styles.buttonContainer}>
                          {!this.state.previewBanner ? (
                            <img
                              src={this.state.bannerImage}
                              alt=""
                              style={styles.uploadImageIcon}
                            />
                          ) : (
                            <img src={this.state.previewBanner} alt="" style={styles.buttonIcon} />
                          )}
                        </div>
                      </div>
                    </section>
                  </ButtonBase>
                )}
              </Dropzone>

              {this.state.bannerImageError ? (
                <div style={styles.imageUploadError}>Error: the image cannot exceed 800Kb</div>
              ) : null}

              <div style={styles.imageButtonInfoText}>
                This image will show up in your merchant profile. Preferred dimensions 1500 x 400.
                Max size 800 kb. Accepted formats PNG, JPG.
              </div>
            </div>
          </div>

          <br />

          <div style={styles.detailsContainer}>
            <h4>Enter banking information</h4>

            <BankingInfoForm
              callValidateForm={bankingInfoValidation =>
                (this.validateBankingInfoForm = bankingInfoValidation)
              }
              pageLocation='MerchantEditPage'
            />

            <div style={{ ...styles.previewContainer, alignItems: 'center' }}>
              <FontAwesomeIcon
                style={{ ...styles.fontAwesomeIcon, padding: '0.6rem' }}
                icon={faLock}
              />
              <div style={styles.infoText}>Your information is encrypted and secure.</div>
            </div>
          </div>

          <div style={styles.detailsContainer}>
            <h4>Billing Address</h4>

            <BillingAddressForm
              styles={styles}
              callValidateForm={billingAddressValidation =>
                (this.validateBillingAddressForm = billingAddressValidation)
              }
            />
          </div>

          <div
            style={{
              margin: '2rem 0',
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-start',
            }}>
            <LightBlueButton variant="contained" size="large" onClick={this.handleBackButtonPress}>
              Back
            </LightBlueButton>
            <div style={{ margin: '0 1rem' }} />
            <DarkBlueButton variant="contained" size="large" onClick={this.handleSaveButtonPress}>
              Save
            </DarkBlueButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
    newMerchant: state.newMerchant,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearBillingAddressForm: () => dispatch(MerchantOnboardingActions.clearBillingAddressForm()),
    populateShippingAddress: address =>
      dispatch(MerchantOnboardingActions.populateShippingAddress(address)),
    populateBillingAddress: address =>
      dispatch(MerchantOnboardingActions.populateBillingAddress(address)),
    fetchMerchantCategories: () => dispatch(MerchantApiActions.getTagTypes()),
    addMerchantName: name => dispatch(MerchantOnboardingActions.addMerchantName(name)),
    addMerchantCategory: cat => dispatch(MerchantOnboardingActions.addMerchantCategory(cat)),
    addMerchantEmail: email => dispatch(MerchantOnboardingActions.addMerchantEmail(email)),
    addMerchantPhone: phone => dispatch(MerchantOnboardingActions.addMerchantPhone(phone)),
    addAccountNumber: number => dispatch(MerchantOnboardingActions.addAccountNumber(number)),
    addRoutingNumber: number => dispatch(MerchantOnboardingActions.addRoutingNumber(number)),
    addDepositoryName: name => dispatch(MerchantOnboardingActions.addDepositoryName(name)),
    addTaxIdNumber: name => dispatch(MerchantOnboardingActions.addTaxIdNumber(name)),
    addLogoImage: image => dispatch(MerchantOnboardingActions.addLogoImage(image)),
    addBannerImage: image => dispatch(MerchantOnboardingActions.addBannerImage(image)),
    updateMerchant: data => dispatch(MerchantApiActions.updateMerchant(data)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withWidth()(MerchantEditPage)),
)
