import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CountryRegionData } from 'react-country-region-selector'
import { MenuItem } from '@material-ui/core'

import { MerchantOnboardingActions } from '../Actions/merchantOnboardingActions'
import CssTextField from '../Components/UI/CssTextField'

class ShippingAddressForm extends Component {
  state = {
    address: false,
    province: false,
    city: false,
    zip: false,
  }

  componentDidMount = () => {
    // Return validateForm() as a callback so it can be
    // accessed by its parent element
    this.props.callValidateForm(this.validateForm.bind(this))
  }

  validateForm = () => {
    const form = this.props.onboarding.shippingAddress

    this.setState({
      address: form.streetAddress1 === '',
      province: form.state === '',
      city: form.city === '',
      zip: form.zipCode === '',
    })

    return (
      form.streetAddress1 === '' || form.state === '' || form.city === '' || form.zipCode === ''
    )
  }

  handleChange = name => event => {
    this.props[name](event.target.value)
  }

  getRegions = country => {
    if (!country) {
      return []
    }
    return country[2].split('|').map(regionPair => {
      let regionName = regionPair.split('~')
      return regionName
    })
  }

  render() {
    const { onboarding, styles } = this.props

    return (
      <>
        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Address 1"
            margin="normal"
            error={this.state.address}
            variant="outlined"
            style={styles.textFieldFullWidth}
            value={onboarding.shippingAddress.streetAddress1}
            onChange={this.handleChange('addShippingAddress1')}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="Address 2"
            margin="normal"
            variant="outlined"
            style={styles.textFieldFullWidth}
            value={onboarding.shippingAddress.streetAddress2}
            onChange={this.handleChange('addShippingAddress2')}
          />
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            select
            label="Province or State"
            margin="normal"
            error={this.state.province}
            variant="outlined"
            value={onboarding.shippingAddress.state}
            style={styles.textFieldHalfWidth}
            onChange={this.handleChange('addShippingState')}>
            {this.getRegions(onboarding.shippingAddress.country).map(option => (
              <MenuItem key={option[1]} value={option[1]}>
                {option[0]}
              </MenuItem>
            ))}
          </CssTextField>

          <div style={{ padding: '0 8px' }} />

          <CssTextField
            select
            label="Country"
            margin="normal"
            variant="outlined"
            value={onboarding.shippingAddress.country}
            style={styles.textFieldHalfWidth}
            onChange={this.handleChange('addShippingCountry')}>
            {CountryRegionData.map(option => (
              <MenuItem key={option[0]} value={option}>
                {option[0]}
              </MenuItem>
            ))}
          </CssTextField>
        </div>

        <div style={styles.merchantInputFieldRow}>
          <CssTextField
            label="City"
            margin="normal"
            error={this.state.city}
            variant="outlined"
            style={styles.textFieldHalfWidth}
            value={onboarding.shippingAddress.city}
            onChange={this.handleChange('addShippingCity')}
          />

          <div style={{ padding: '0 8px' }} />

          <CssTextField
            label="Postal/Zip Code"
            margin="normal"
            error={this.state.zip}
            variant="outlined"
            style={styles.textFieldHalfWidth}
            value={onboarding.shippingAddress.zipCode}
            onChange={this.handleChange('addShippingZipCode')}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    onboarding: state.onboarding,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addShippingAddress1: street => dispatch(MerchantOnboardingActions.addShippingAddress1(street)),
    addShippingAddress2: unit => dispatch(MerchantOnboardingActions.addShippingAddress2(unit)),
    addShippingState: state => dispatch(MerchantOnboardingActions.addShippingState(state)),
    addShippingCountry: country => dispatch(MerchantOnboardingActions.addShippingCountry(country)),
    addShippingCity: city => dispatch(MerchantOnboardingActions.addShippingCity(city)),
    addShippingZipCode: zip => dispatch(MerchantOnboardingActions.addShippingZipCode(zip)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingAddressForm)
